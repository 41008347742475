import React from 'react'
import { OverlayTrigger } from '../OverlayTrigger/OverlayTrigger'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { isReactElement } from '../_utils/isReactElement'
import type { DivAttributes } from '../_utils/types'
import { StyledPopover, StyledPopoverContent } from './Popover.styles'
import type {
  PopoverContentProps,
  PopoverProps,
  PopoverRole,
} from './Popover.types'

const Content = React.forwardRef<
  HTMLDivElement,
  DivAttributes & PopoverContentProps
>(function Content(
  { children, block = false, fluid = false, placement = 'top', ...props },
  ref
) {
  return (
    <StyledPopover ref={ref}>
      <StyledPopoverContent
        {...props}
        $block={block}
        $fluid={fluid}
        $placement={placement}
      >
        {children}
      </StyledPopoverContent>
    </StyledPopover>
  )
})

function PopoverInner<Role extends PopoverRole>(
  {
    children,
    initialIsVisible = false,
    restoreFocusOnHide = false,
    placement = 'top',
    overlay,
    overlayRef,
    role,
    ...props
  }: PopoverProps<Role>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const overlayNode = isReactElement(overlay) ? (
    React.cloneElement(overlay, { placement })
  ) : (
    <React.Fragment />
  )

  return (
    <OverlayTrigger
      {...{
        ...props,
        initialIsVisible,
        overlay: overlayNode,
        placement,
        role,
        ref,
        restoreFocusOnHide,
        shrinkOverlay: true,
        arrow: true,
        overlayRef,
      }}
    >
      {children}
    </OverlayTrigger>
  )
}

const Popover_ = React.forwardRef(PopoverInner) as <Role extends PopoverRole>(
  props: PopoverProps<Role>
) => ReturnType<typeof PopoverInner>

// @ts-expect-error
Popover_.displayName = 'Popover'

Content.displayName = 'Popover.Content'

/**

 We use popovers to display more of an item’s information or details on hover.
 This may be used to show additional fields in an item’s form that aren’t shown
 by default in the UI. Oftentimes, there will be a visual queue to indicate that
 more information is available.

 Do not use popovers to display large amounts of information, perform data
 entry, or use as a replacement for an overflow, menu, or modal.

 If you want to show descriptive / educational information about an item,
 use a tooltip.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-popover--demo)

 @see [Design Guidelines](https://design.procore.com/popover)

*/
export const Popover = addSubcomponents({ Content }, Popover_)
