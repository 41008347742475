import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'
import { colors } from '../_styles/colors'

export const StyledToggleButton = styled(Button)<{
  $selected: boolean
  loading?: boolean
}>`
  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${colors.blue96};
      color: ${colors.blue45};

      [data-icon-prop='true'] {
        color: ${colors.blue50};
      }

      &:hover {
        background-color: ${colors.blue90};
        color: ${colors.blue45};
      }

      &:focus {
        background-color: ${colors.blue96};
        color: ${colors.blue45};
      }

      &:disabled {
        background-color: ${colors.blue96};
        color: ${colors.blue70};

        [data-icon-prop='true'] {
          color: ${colors.blue70};
        }

        circle {
          stroke: ${colors.blue50};
        }
      }
    `}
`
