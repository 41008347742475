import styled, { css, keyframes } from 'styled-components'
import { colors } from '../_styles/colors'

const backgroundSize = 8

const color1 = colors.blue70

const color2 = colors.blue60

export const loaderTransitionDuration = 250

const movingStripes = keyframes`
  from {
    background-position: ${backgroundSize}px 0;
  }
  100% {
    background-position: 0 ${backgroundSize}px;
  }
`

export const StyledLoader = styled.div`
  display: block;
  height: 100%;
  left: 0;
  min-width: 12px; // TODO one-off value
  position: absolute;
  transition: width ${loaderTransitionDuration}ms ease-out; // TODO animation palette
  top: 0;
`

export const StyledGradient = styled.div<{ $animated: boolean }>`
  height: 100%;

  ${({ $animated }) =>
    $animated
      ? css`
          animation: ${movingStripes} 0.5s linear infinite;
          background-image: linear-gradient(
            45deg,
            ${color1} 26%,
            ${color2} 25%,
            ${color2} 51%,
            ${color1} 50%,
            ${color1} 76%,
            ${color2} 75%,
            ${color2}
          );
          background-size: ${backgroundSize}px ${backgroundSize}px;
        `
      : css`
          background-color: ${colors.blue50};
        `}
`
