export type Spacing = keyof typeof spacing

/** Number values for padding, margin, etc.
 * Intended to be used as pixels.

  none: 0,

  xxs: 2,

  xs: 4,

  sm: 8,

  md: 12,

  lg: 16,

  xl: 24,

  xxl: 32,
 */
export const spacing = {
  /** value 0 */
  none: 0,
  /** value 2 */
  xxs: 2,
  /** value 4 */
  xs: 4,
  /** value 8 */
  sm: 8,
  /** value 12 */
  md: 12,
  /** value 16 */
  lg: 16,
  /** value 24 */
  xl: 24,
  /** value 32 */
  xxl: 32,
}
