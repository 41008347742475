import type { DraggableProvided } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import { Draggable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import { Check, Clear as ClearIcon, Grip } from '@procore/core-icons'
import React from 'react'
import { FakeCheckbox } from '../Checkbox/Checkbox'
import { Spinner } from '../Spinner/Spinner'
import { Token } from '../Token/Token'
import { Tooltip } from '../Tooltip/Tooltip'
import { Typeahead } from '../Typeahead/Typeahead'
import { OverflowObserver } from '../_hooks/OverflowObserver'
import { colors } from '../_styles/colors'
import type { DivAttributes } from '../_utils/types'
import { InternalDragDropContext } from './SuperSelect'
import { draggableOptionIdSymbol } from './SuperSelect.constants'
import {
  StyledArrow,
  StyledArrowContainer,
  StyledArrowFill,
  StyledArrowOutline,
  StyledCheckboxContainer,
  StyledCheckmarkContainer,
  StyledClearIcon,
  StyledDraggableWrapper,
  StyledEmptyMessage,
  StyledFooter,
  StyledGrip,
  StyledGroup,
  StyledHeader,
  StyledIndicators,
  StyledItem,
  StyledItemLabel,
  StyledLabel,
  StyledMultiInput,
  StyledMultiInputContainer,
  StyledMultiValue,
  StyledMultiValueContainer,
  StyledOptionGroupLabel,
  StyledOptions,
  StyledOverlay,
  StyledSearchContainer,
  StyledTrigger,
} from './SuperSelect.styles'
import type {
  SuperSelectMenuItemProps,
  SuperSelectOption,
} from './SuperSelect.types'
import { useSuperSelectContext } from './useSuperSelect'

export const Overlay = React.forwardRef<HTMLElement, any>((props, ref) => {
  return <StyledOverlay ref={ref} {...props} />
})

export const SingleTrigger = (props: React.PropsWithChildren<any>) => {
  const ctx = useSuperSelectContext()

  return (
    <OverflowObserver>
      {({ isOverflowingX, ref }) => {
        const showTooltip =
          !ctx.config.disabled && isOverflowingX && !ctx.state.open

        const label = ctx.state.selectedLabel || ctx.config.placeholder

        const labelComponent = (
          <ctx.components.Label {...props} ref={ref} hoverable={showTooltip} />
        )

        return (
          <Tooltip trigger={showTooltip ? 'hover' : 'none'} overlay={label}>
            {labelComponent}
          </Tooltip>
        )
      }}
    </OverflowObserver>
  )
}

export const MultiTrigger = (props: React.PropsWithChildren<any>) => {
  const ctx = useSuperSelectContext()

  if (ctx.config.multiple && Array.isArray(ctx.state.value)) {
    return (
      <ctx.components.MultiValueContainer {...props}>
        {ctx.state.value?.map((val, j) => {
          const option: SuperSelectOption = ctx.state.sourceOptions.find(
            (o) => ctx.option.value(o) === val
          )

          return (
            option && (
              <ctx.components.MultiValue key={val} index={j} option={option} />
            )
          )
        })}
        <ctx.components.MultiInputContainer>
          <ctx.components.MultiInput {...ctx.props.multiInput()} />
        </ctx.components.MultiInputContainer>
      </ctx.components.MultiValueContainer>
    )
  }

  return null
}

export const TriggerContainer = StyledTrigger as
  | React.ForwardRefExoticComponent<any>
  | React.FC<any>

export const Trigger = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  const ctx = useSuperSelectContext()

  const searchRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    searchRef.current?.focus()
  }, [ctx.state.value])

  return (
    <ctx.components.TriggerContainer ref={ref} {...props}>
      {ctx.config.multiple ? (
        <ctx.components.MultiTrigger />
      ) : (
        <ctx.components.SingleTrigger />
      )}
      <ctx.components.Indicators />
    </ctx.components.TriggerContainer>
  )
})

export const Indicators = (props: React.PropsWithChildren<any>) => {
  const ctx = useSuperSelectContext()

  const hasValue = Array.isArray(ctx.state.value)
    ? ctx.state.value.length > 0
    : ctx.state.value

  return (
    <StyledIndicators>
      {hasValue && <ctx.components.Clear />}
      {ctx.config.loading ? (
        <ctx.components.Loading />
      ) : (
        <ctx.components.ArrowContainer>
          <ctx.components.Arrow
            up={ctx.state.open}
            down={!ctx.state.open}
            width={8}
            height={4}
          />
        </ctx.components.ArrowContainer>
      )}
    </StyledIndicators>
  )
}

export const Label = React.forwardRef<HTMLDivElement, { hoverable: boolean }>(
  ({ hoverable, ...props }, ref) => {
    const ctx = useSuperSelectContext()

    return (
      <StyledLabel
        {...props}
        {...ctx.props.label()}
        ref={ref}
        $hoverable={hoverable}
      >
        {ctx.state.selectedLabel || ctx.config.placeholder}
      </StyledLabel>
    )
  }
)

export const Loading = () => {
  return <Spinner color="blue50" size="xs" />
}

export const ArrowContainer = (props: React.PropsWithChildren<any>) => {
  return <StyledArrowContainer {...props} />
}

export const Arrow = ({
  up,
  down,
  left,
  right,
  width = 12,
  height = 12,
  border = 0,
}: any) => {
  let outline = ''
  let fill = ''

  if (up) {
    outline = `0,${height} ${width},${height} {width / 2},0`
    // prettier-ignore
    fill = `${border},${height} ${width - border},${height} ${width / 2},${border * 2}`
  } else if (down) {
    outline = `0,0 ${width},0 ${width / 2},${height}`
    // prettier-ignore
    fill = `${border},0 ${width - border},0 ${width / 2},${height - border * 2}`
  } else if (left) {
    outline = `${width},0 ${width},${height} 0,${height / 2}`
    // prettier-ignore
    fill = `${width},${border} ${width},${height - border} ${border * 2},${height / 2}`
  } else if (right) {
    outline = `0,0 0,${height} ${width},${height / 2}`
    // prettier-ignore
    fill = `0,${border} 0,${height - border} ${width - border * 2},${height / 2}`
  }

  return (
    <StyledArrow>
      <svg height={height} width={width} focusable="false">
        {border > 0 && <StyledArrowOutline points={outline} />}
        <StyledArrowFill points={fill} />
      </svg>
    </StyledArrow>
  )
}

export const Clear = () => {
  const ctx = useSuperSelectContext()

  return (
    <StyledClearIcon
      {...ctx.props.clear()}
      size="sm"
      variant="tertiary"
      disabled={ctx.config.disabled}
      icon={<ClearIcon />}
      tabIndex={-1} // TODO revisit this accessibility
    />
  )
}

export const MultiInputContainer = (props: React.PropsWithChildren<{}>) => {
  return <StyledMultiInputContainer>{props.children}</StyledMultiInputContainer>
}

export const MultiInput = React.forwardRef(
  (props: React.PropsWithChildren<any>, ref) => {
    return <StyledMultiInput ref={ref} {...props} />
  }
)

export const MultiValue = (props: React.PropsWithChildren<any>) => {
  const ctx = useSuperSelectContext()

  return (
    <StyledMultiValue>
      <Token focused={ctx.state.activeTokenIndex === props.index}>
        <Token.Label>{ctx.option.label(props.option)}</Token.Label>
        <Token.Remove {...ctx.props.tokenClear(props.index)} />
      </Token>
    </StyledMultiValue>
  )
}

export const MultiValueContainer = (props: React.PropsWithChildren<any>) => {
  return <StyledMultiValueContainer {...props} />
}

export const SearchContainer = React.forwardRef(
  (props: React.PropsWithChildren<any>, ref) => {
    return <StyledSearchContainer ref={ref} {...props} />
  }
)

export const Search = (props: React.PropsWithChildren<any>) => {
  return <Typeahead {...props} />
}

export const Header = (props: React.PropsWithChildren<any>) => {
  return <StyledHeader {...props} />
}

export const Footer = React.forwardRef(
  (props: React.PropsWithChildren<any>, ref) => {
    return <StyledFooter ref={ref} {...props} padding="md lg" />
  }
)

export const EmptyMessage = (props: any) => {
  return (
    <StyledEmptyMessage {...props}>{props.emptyMessage}</StyledEmptyMessage>
  )
}

export const Options = (props: React.PropsWithChildren<{}> & DivAttributes) => {
  const ctx = useSuperSelectContext()

  return <StyledOptions {...props} {...ctx.props.menu()} />
}

export const Group = (props: React.PropsWithChildren<{}>) => {
  const ctx = useSuperSelectContext()

  return (
    <StyledGroup
      {...props}
      {...ctx.props.item()}
      role="separator"
      aria-disabled
    />
  )
}

export const Item = React.forwardRef<HTMLDivElement, SuperSelectMenuItemProps>(
  ({ children, isDragging = false, ...props }, ref) => {
    const ctx = useSuperSelectContext()
    const { isDraggingOver } = React.useContext(InternalDragDropContext)
    const [size, setSize] = React.useState(0)

    const knownSize = props['data-known-size'] ?? 0

    React.useEffect(() => {
      setSize((prevSize) => (knownSize === 0 ? prevSize : knownSize))
    }, [knownSize])

    const index = props['data-index']

    const option = ctx.state.options[index]

    if (!option) {
      return null
    }

    const isDisabled = ctx.option.isDisabled(option)
    const isSelected = ctx.option.isSelected(option)
    const isOptgroup = ctx.option.isOptgroup(option)
    const value = ctx?.option.value(option)

    function setNode(node: HTMLElement | null) {
      if (ctx) {
        ctx.refs.navigationList.current[index] = node
      }
    }

    if (isOptgroup) {
      return <ctx.components.Group {...props}>{children}</ctx.components.Group>
    }

    return (
      <StyledItem
        {...props}
        {...ctx.props.item({
          onClick: () => ctx.state.onSelect(option),
        })}
        $disabled={ctx.option.isDisabled(option)}
        $emptyMinHeight={size}
        $highlighted={ctx.state.activeMenuIndex === index}
        $isDraggable={!isDisabled}
        $isDragging={isDragging}
        $isDraggingDisabled={Boolean(ctx.state.searchValue)}
        $isDraggingOver={isDraggingOver}
        $selected={isSelected}
        aria-posinset={index + 1}
        aria-selected={ctx.state.activeMenuIndex === index}
        aria-setsize={ctx.state.options.length || 0}
        id={`item-${value}`}
        ref={setNode}
        role="option"
      >
        {ctx.config.selectionStyle === 'checkbox' && (
          <ctx.components.CheckboxContainer>
            <ctx.components.Checkbox
              disabled={isDisabled}
              checked={isSelected}
            />
          </ctx.components.CheckboxContainer>
        )}
        {children}
        {ctx.config.selectionStyle === 'checkmark' && (
          <ctx.components.CheckmarkContainer>
            {isSelected && <ctx.components.Checkmark />}
          </ctx.components.CheckmarkContainer>
        )}
      </StyledItem>
    )
  }
)

export const OptionGroupHeader = ({ optgroup }: any) => {
  return <StyledOptionGroupLabel>{optgroup.label}</StyledOptionGroupLabel>
}

export const OptionLabel = ({ option }: any) => {
  const ctx = useSuperSelectContext()

  return (
    <StyledItemLabel $draggable={ctx.config.draggable}>
      {ctx.option.label(option)}
    </StyledItemLabel>
  )
}

export const OptionContent = ({ option }: any) => {
  const ctx = useSuperSelectContext()

  if (ctx.option.isOptgroup(option)) {
    return <ctx.components.OptionGroupHeader optgroup={option} />
  }

  return <ctx.components.OptionLabel option={option} />
}

export const DraggableOptionContent = ({ index, option }: any) => {
  const ctx = useSuperSelectContext()

  const disabled = ctx.option.isDisabled(option)
  const isOptgroup = ctx.option.isOptgroup(option)
  const optionId = isOptgroup ? option.id : option[draggableOptionIdSymbol]

  return (
    <Draggable
      draggableId={optionId}
      index={index}
      key={optionId}
      isDragDisabled={isOptgroup || disabled || Boolean(ctx.state.searchValue)}
    >
      {(provided: DraggableProvided) => {
        return (
          <StyledDraggableWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {!isOptgroup && <ctx.components.DragHandle />}
            <ctx.components.OptionContent index={index} option={option} />
          </StyledDraggableWrapper>
        )
      }}
    </Draggable>
  )
}

export const DragHandle = (props: React.PropsWithChildren<any>) => {
  return (
    <StyledGrip {...props}>
      <Grip color={colors.gray45} />
    </StyledGrip>
  )
}

export const CheckboxContainer = (props: React.PropsWithChildren<any>) => {
  return <StyledCheckboxContainer {...props} />
}

export const Checkbox = (props: React.PropsWithChildren<any>) => {
  return <FakeCheckbox {...props} />
}

export const CheckmarkContainer = (props: React.PropsWithChildren<any>) => {
  return <StyledCheckmarkContainer {...props} />
}

export const Checkmark = (props: React.PropsWithChildren<any>) => {
  return <Check {...props} size="sm" />
}
