import React from 'react'
import { useDeprecation } from '../_hooks/Deprecation'
import type { DivAttributes } from '../_utils/types'
import { StyledCardContainer } from './Card.styles'
import type { CardProps } from './Card.types'

/**

 We use cards to display information that is grouped together.
 Cards may contain rich media, text, buttons, and other functionality.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-card--demo)

 @see [Design Guidelines](https://design.procore.com/card)

 */
export const Card = React.forwardRef<HTMLDivElement, DivAttributes & CardProps>(
  function Card(
    {
      children,
      level,
      shadowStrength = 1,
      variant,
      'aria-orientation': ariaOrientation,
      role,
      ...props
    },
    ref
  ) {
    useDeprecation({
      when: level,
      oldThing: 'level',
      newThing: 'shadowStrength',
    })

    const roleAttributes =
      role && role !== 'none' && role !== 'presentation'
        ? { role, 'aria-orientation': ariaOrientation }
        : {}

    return (
      <StyledCardContainer
        {...props}
        {...roleAttributes}
        $level={level}
        $shadowStrength={shadowStrength}
        $variant={variant}
        ref={ref}
      >
        {children}
      </StyledCardContainer>
    )
  }
)

Card.displayName = 'Card'
