import React from 'react'
import {
  StyledContainer,
  StyledIncrementContainer,
  StyledIncrementOverlay,
  StyledLabel,
  StyledSlider,
} from './Slider.styles'
import type { SliderProps } from './Slider.types'

export const getIncrements = ({
  min,
  max,
  step,
}: {
  min: number
  max: number
  step?: number
}): number => {
  if (!step) {
    return 0
  }
  return (max - min) / step
}

export const getPercentage = ({
  max,
  min,
  value,
}: {
  max: number
  min: number
  value: number
}): number => {
  return ((value - min) / (max - min)) * 100
}

export const getLabel = ({
  max,
  min,
  step,
  index,
}: {
  max: number
  min: number
  step: number
  index: number
}): number => {
  if (min === 0) {
    return index * step
  } else if (min >= 0) {
    return index * step + min
  } else if (min <= 0) {
    return (index - getIncrements({ max, min, step })) * step + max
  } else {
    return 0
  }
}

export const getDefaultValue = ({
  min,
  max,
  step,
}: {
  min: number
  max: number
  step?: number
}): number => {
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range#value
  const defaultVal = max < min ? min : min + (max - min) / 2
  return step ? Math.round(defaultVal / step) * step : defaultVal
}

/**

 A slider is a bar with selectable/grabbable handle. It allows users to click
 and drag horizontally to a desired value. It is used when users need to select
 a value from a predefined range of values. Sliders should always have a
 corresponding value or increment displayed within proximity of the component.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-slider--demo)

 @see [Design Guidelines](https://design.procore.com/slider)

 */
export const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
  function Slider(
    {
      className,
      disabled,
      onChange,
      max = 100,
      min = 0,
      showLabel,
      step,
      style,
      value: value_,
      ...props
    },
    ref
  ) {
    const [isDragging, setIsDragging] = React.useState<boolean>(false)

    const value = value_ ?? getDefaultValue({ max, min, step })
    const increments = getIncrements({ max, min, step })

    // check if the "step" value does not cleanly
    //  divide into max - min
    if (increments % 1 !== 0) {
      console.warn(
        '@procore/core-react: SLIDER COMPONENT WARNING: Your step value does not cleanly divide into max - min. This will cause your Slider to display incorrectly.'
      )
    }

    return (
      <StyledContainer
        className={className}
        style={style}
        $showLabel={showLabel}
      >
        <StyledSlider
          {...props}
          disabled={disabled}
          onChange={onChange}
          max={max}
          min={min}
          $percent={getPercentage({ max, min, value })}
          ref={ref}
          step={step}
          type="range"
          value={value}
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          $isDragging={isDragging}
        />
        {step && (
          <StyledIncrementContainer>
            {Array(Math.floor(increments) + 1)
              .fill(null)
              .map((_, index) => {
                return (
                  <StyledIncrementOverlay
                    key={`core-slider-increment-overlay-${index}-${min}-${max}-${step}`}
                  >
                    {showLabel && (
                      <StyledLabel intent="small">
                        {getLabel({
                          min,
                          max,
                          step,
                          index,
                        })}
                      </StyledLabel>
                    )}
                  </StyledIncrementOverlay>
                )
              })}
          </StyledIncrementContainer>
        )}
      </StyledContainer>
    )
  }
)
