import { Building, People } from '@procore/core-icons'
import { useId } from '@react-aria/utils'
import React, { forwardRef, useMemo, useState } from 'react'
import { Avatar } from '../Avatar'
import { Button } from '../Button'
import { ContactItem } from '../ContactItem'
import { Link } from '../Link'
import { Modal } from '../Modal'
import type { OverlayTriggerRef } from '../OverlayTrigger/OverlayTrigger.types'
import { Popover } from '../Popover'
import { Typography } from '../Typography'
import { useI18nContext } from '../_hooks/I18n'
import type { TriggerVariant } from '../_hooks/Trigger'
import type { Color } from '../_styles/colors'
import {
  colorsOrder,
  foldedItemsCap,
  restCountThreshold,
  visibleItemsCap,
} from './AvatarStack.constants'
import {
  StyledAvatar,
  StyledContactItem,
  StyledContactItems,
  StyledModalBody,
  StyledViewAllWrapper,
  StyledWrapper,
} from './AvatarStack.styles'
import type {
  AvatarStackItem,
  AvatarStackItemId,
  AvatarStackItemType,
  AvatarStackProps,
  AvatarStackSize,
  FoldedAvatarStackProps,
  ViewAllModalProps,
} from './AvatarStack.types'

const defaultPopoverTrigger: TriggerVariant[] = ['hover', 'focus']

export function getOverflowValues<Item extends AvatarStackItem>(items: Item[]) {
  let foldedItems: Item[] = []
  let restCountLabel: string | null = null
  let isViewAllNeeded = false

  const visibleItems =
    items.length > visibleItemsCap ? items.slice(0, visibleItemsCap - 1) : items
  const restItemsCount = items.length - visibleItems.length

  if (restItemsCount > 0) {
    foldedItems = items.slice(
      visibleItems.length,
      visibleItems.length + foldedItemsCap
    )

    restCountLabel =
      restItemsCount > restCountThreshold
        ? `${restCountThreshold}+`
        : `+${restItemsCount}`

    isViewAllNeeded = restItemsCount > foldedItemsCap
  }

  return {
    visibleItems,
    foldedItems,
    restCountLabel,
    isViewAllNeeded,
  }
}

export function getIcon(type: AvatarStackItemType, size: 'md' | 'sm') {
  switch (type) {
    case 'company':
      return <Building size={size} />

    case 'group':
      return <People size={size} />

    case 'user':
    default:
      return null
  }
}

function getContactIcon(type: AvatarStackItemType) {
  return getIcon(type, 'md')
}

export function getAvatarIcon(
  type: AvatarStackItemType,
  size: AvatarStackSize
) {
  const avatarSize = size === 'lg' ? 'md' : 'sm'
  return getIcon(type, avatarSize)
}

export function getColorOrder(avatarItems: AvatarStackItem[]) {
  return avatarItems.reduce(
    ({ map, color }, item) => {
      if (item.imageUrl) {
        return {
          map,
          color,
        }
      }

      if (item.inactive) {
        map.set(item.id, 'gray70')

        return {
          map,
          color,
        }
      }

      map.set(item.id, color)

      const currentColorIndex = colorsOrder.indexOf(color)
      const nextColorIndex = (currentColorIndex + 1) % colorsOrder.length
      return {
        map,
        color: item.imageUrl ? color : colorsOrder[nextColorIndex],
      }
    },
    {
      map: new Map<AvatarStackItemId, Color>(),
      color: colorsOrder[0],
    }
  ).map
}

export function AvatarStackContactItem({
  id,
  type,
  imageUrl,
  initials,
  inactive,
  name,
  linkUrl,
  description,
}: AvatarStackItem) {
  return (
    <StyledContactItem
      key={id}
      imageUrl={imageUrl}
      initials={initials}
      icon={getContactIcon(type)}
      disabled={inactive}
    >
      <ContactItem.Title>
        {!inactive && linkUrl ? <Link href={linkUrl}>{name}</Link> : name}
      </ContactItem.Title>
      <ContactItem.Description>
        {type === 'group' ? (
          <Typography color="gray15">{description}</Typography>
        ) : (
          description
        )}
      </ContactItem.Description>
    </StyledContactItem>
  )
}

export function AvatarContent({
  imageUrl,
  initials,
  type,
  size,
}: {
  imageUrl?: AvatarStackItem['imageUrl']
  initials: AvatarStackItem['initials']
  type: AvatarStackItem['type']
  size: AvatarStackSize
}) {
  const avatarIcon = getAvatarIcon(type, size)

  if (imageUrl) {
    return <Avatar.Portrait imageUrl={imageUrl} />
  }

  if (avatarIcon) {
    return <Avatar.Icon icon={avatarIcon} />
  }

  if (initials) {
    return <Avatar.Label>{initials}</Avatar.Label>
  }

  return null
}

function AvatarWithPopover<Item extends AvatarStackItem>({
  item,
  size,
  colors,
}: {
  item: Item
  size: AvatarStackSize
  colors: Map<AvatarStackItemId, Color>
}) {
  const hasEnabledLink = item.linkUrl && !item.inactive
  const avatarProps = hasEnabledLink
    ? {
        role: 'link',
        href: item.linkUrl,
      }
    : {
        role: 'img',
        // Allow SR to open Popover as it cycles through. Keeps visual with audio.
        // The link or image is labelled, and focus does not enter the Popover.
        tabIndex: -1,
      }

  return (
    <Popover
      key={item.id}
      trigger={defaultPopoverTrigger}
      overlay={
        <Popover.Content>
          <AvatarStackContactItem
            id={item.id}
            type={item.type}
            imageUrl={item.imageUrl}
            initials={item.initials}
            inactive={item.inactive}
            name={item.name}
            linkUrl={item.linkUrl}
            description={item.description}
          />
        </Popover.Content>
      }
    >
      <StyledAvatar
        {...avatarProps}
        aria-label={`${item.name}, ${item.description}`}
        disabled={item.inactive}
        size={size}
        $color={colors.get(item.id) as Color}
      >
        <AvatarContent
          imageUrl={item.imageUrl}
          initials={item.initials}
          type={item.type}
          size={size}
        />
      </StyledAvatar>
    </Popover>
  )
}

export function FoldedAvatarStack<Item extends AvatarStackItem>({
  items,
  onClickViewAll,
  size,
  restCountLabel,
  isViewAllNeeded,
  title,
}: FoldedAvatarStackProps<Item>) {
  const I18n = useI18nContext()
  const overlayRef = React.useRef<OverlayTriggerRef>(null)
  const restAvatarId = useId()

  return (
    <Popover
      trigger={defaultPopoverTrigger}
      aria-labelledby={restAvatarId}
      overlayRef={overlayRef}
      beforeHide={(e) => {
        return e.type !== 'blur'
      }}
      overlay={
        <Popover.Content>
          <StyledContactItems>
            {items.map((item) => (
              <AvatarStackContactItem
                key={item.id}
                id={item.id}
                type={item.type}
                imageUrl={item.imageUrl}
                initials={item.initials}
                inactive={item.inactive}
                name={item.name}
                linkUrl={item.linkUrl}
                description={item.description}
              />
            ))}
          </StyledContactItems>
          {isViewAllNeeded && (
            <StyledViewAllWrapper>
              <Button
                data-qa="core-avatar-stack-view-all-modal-trigger"
                onClick={onClickViewAll}
                size="sm"
                variant="secondary"
              >
                {I18n.t('core.avatarStack.viewAll')}
              </Button>
            </StyledViewAllWrapper>
          )}
        </Popover.Content>
      }
    >
      <StyledAvatar
        role="button"
        onPress={onClickViewAll}
        id={restAvatarId}
        aria-label={`${restCountLabel}, ${title}`}
        data-qa="core-avatar-stack-folded-avatars-popover-trigger"
        size={size}
        $color="gray85"
      >
        <Avatar.Label aria-hidden>
          <Typography color="black">{restCountLabel}</Typography>
        </Avatar.Label>
      </StyledAvatar>
    </Popover>
  )
}

export function ViewAllModal<Item extends AvatarStackItem>({
  isOpen,
  onClose,
  title,
  items,
}: ViewAllModalProps<Item>) {
  const I18n = useI18nContext()
  const labelId = useId()

  return (
    <Modal
      role="dialog"
      aria-labelledby={labelId}
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <span id={labelId}>
          {title} ({items.length})
        </span>
      </Modal.Header>
      <StyledModalBody>
        {items.map((item) => (
          <AvatarStackContactItem
            key={item.id}
            id={item.id}
            type={item.type}
            imageUrl={item.imageUrl}
            initials={item.initials}
            inactive={item.inactive}
            name={item.name}
            linkUrl={item.linkUrl}
            description={item.description}
          />
        ))}
      </StyledModalBody>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Button onClick={onClose}>{I18n.t('core.avatarStack.close')}</Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  )
}

export function defaultInitials<Item extends AvatarStackItem>(item: Item) {
  return item.initials as string
}

export function defaultGetImageUrl<Item extends AvatarStackItem>(item: Item) {
  return item.imageUrl as string
}

export function getTransformedItems<Item extends AvatarStackItem>({
  items,
  getInitials,
  getImageUrl,
}: {
  items: Item[]
  getInitials: AvatarStackProps<Item>['getInitials']
  getImageUrl: AvatarStackProps<Item>['getImageUrl']
}) {
  return items.map((item) => ({
    ...item,
    initials: getInitials!(item),
    imageUrl: getImageUrl!(item),
  }))
}

const _AvatarStack = <Item extends AvatarStackItem>(
  {
    items: _items,
    title,
    size = 'lg',
    getInitials = defaultInitials,
    getImageUrl = defaultGetImageUrl,
    onClickViewAll,
    ...props
  }: AvatarStackProps<Item>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const items = useMemo(
    () =>
      getTransformedItems({
        items: _items,
        getInitials,
        getImageUrl,
      }),
    [_items]
  )

  const { visibleItems, foldedItems, restCountLabel, isViewAllNeeded } =
    useMemo(() => getOverflowValues(items), [items])

  const visibleItemsColors = useMemo(
    () => getColorOrder(visibleItems),
    [visibleItems]
  )

  return (
    <div ref={ref} {...props}>
      {isViewAllNeeded && !onClickViewAll && (
        <ViewAllModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={title}
          items={items}
        />
      )}
      <StyledWrapper>
        {visibleItems.map((item) => {
          return (
            <AvatarWithPopover
              colors={visibleItemsColors}
              item={item}
              key={`${item.name}_${item.id}`}
              size={size}
            />
          )
        })}
        {foldedItems.length > 0 && (
          <FoldedAvatarStack
            restCountLabel={restCountLabel as string}
            isViewAllNeeded={isViewAllNeeded}
            items={foldedItems}
            onClickViewAll={onClickViewAll || (() => setIsModalOpen(true))}
            size={size}
            title={title}
          />
        )}
      </StyledWrapper>
    </div>
  )
}

/**

 We use avatars to visually represent our users, places, and things in the app.
 These can be in the form of rich media or representative illustrations.

 @since 10.19.0

 @see [Storybook](https://procore.github.io/core/latest/?path=/story/demos-avatarstack--demo)

 @see [Design Guidelines](https://design.procore.com/avatar-stack)
 */
export const AvatarStack = forwardRef(_AvatarStack) as <
  Item extends AvatarStackItem
>(
  props: AvatarStackProps<Item> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof _AvatarStack>

// @ts-ignore
AvatarStack.displayName = 'AvatarStack'
