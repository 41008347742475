import React from 'react'
import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledLabel,
  StyledOL,
  StyledP,
  StyledUL,
} from './Semantic.styles'
import type { HeadingProps, ListProps } from './Semantic.types'

export const H1 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H1(props, ref) {
    return <StyledH1 {...props} ref={ref} />
  }
)

export const H2 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H2(props, ref) {
    return <StyledH2 {...props} ref={ref} />
  }
)

export const H3 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H3(props, ref) {
    return <StyledH3 {...props} ref={ref} />
  }
)

export const H4 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H4(props, ref) {
    return <H3 as="h4" {...props} ref={ref} />
  }
)

export const H5 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H5(props, ref) {
    return <H3 as="h5" style={{ fontSize: '90%' }} {...props} ref={ref} />
  }
)

export const H6 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function H6(props, ref) {
    return <H3 as="h6" style={{ fontSize: '85%' }} {...props} ref={ref} />
  }
)

const UnknownHeading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function UnknownHeading(props, ref) {
    return <strong {...props} ref={ref} />
  }
)

const levelToComponent = {
  '1': H1,
  '2': H2,
  '3': H3,
  '4': H4,
  '5': H5,
  '6': H6,
}

/**
 * @param level 1 - 6
 * @returns H1 - H6 component
 */
export function getHeadingComponent(
  level: string | number
): React.ForwardRefExoticComponent<HeadingProps> {
  // @ts-ignore
  return levelToComponent[String(level)] || UnknownHeading
}

export const P = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(function P(props, ref) {
  return <StyledP {...props} ref={ref} />
})

export const UL = React.forwardRef<HTMLUListElement, ListProps>(function UL(
  props,
  ref
) {
  const { listStyleType, ...restProps } = props
  return <StyledUL $listStyleType={listStyleType} {...restProps} ref={ref} />
})

export const OL = React.forwardRef<HTMLOListElement, ListProps>(function OL(
  props,
  ref
) {
  const { listStyleType, ...restProps } = props
  return <StyledOL $listStyleType={listStyleType} {...restProps} ref={ref} />
})

export const Label = React.forwardRef<
  HTMLLabelElement,
  React.HTMLAttributes<HTMLLabelElement> & { htmlFor?: string; block?: boolean }
>(function Label({ block, ...props }, ref) {
  return <StyledLabel $block={block} {...props} ref={ref} />
})

H1.displayName = 'H1'

H2.displayName = 'H2'

H3.displayName = 'H3'

P.displayName = 'P'

UL.displayName = 'UL'

OL.displayName = 'OL'

Label.displayName = 'Label'
