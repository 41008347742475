import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'
import type { EmptyStateSize } from './EmptyState.types'

const emptyStateWidth = 312

export const StyledSvg = styled.svg`
  width: 200px;
  height: 200px;
`

export const StyledImage = styled.div``

export const StyledTitle = styled.div`
  color: ${colors.gray15};
  max-width: ${emptyStateWidth}px;
  width: 100%;
  ${getTypographyIntent('h2')}
`

export const StyledDescription = styled.div`
  margin-top: ${spacing.lg}px;
  color: ${colors.gray45};
  max-width: ${emptyStateWidth}px;
  width: 100%;
  ${getTypographyIntent('body')}
`

export const StyledActions = styled.div`
  margin-top: ${spacing.xl}px;
`

export const StyledEmptyState = styled.div<{
  $compact: boolean
  $isHorizontal?: boolean
  $size?: EmptyStateSize
}>`
  display: flex;
  justify-content: center;
  align-items: ${({ $isHorizontal }) =>
    $isHorizontal ? 'flex-start' : 'center'};
  gap: ${spacing.xl}px;
  flex-direction: ${({ $isHorizontal }) => ($isHorizontal ? 'row' : 'column')};
  margin-top: ${({ $compact }) => ($compact ? 0 : 96)}px;
  margin-bottom: ${({ $compact }) => ($compact ? 0 : 64)}px;

  ${StyledImage} {
    > img {
      max-width: 200px;
    }
  }

  ${({ $compact = false, $size }) =>
    ($compact || $size === 'md') &&
    css`
      ${StyledImage} {
        > img {
          max-width: 120px;
        }
      }

      ${StyledSvg} {
        width: 120px;
        height: 120px;
      }
    `}

  ${({ $size }) =>
    $size === 'sm' &&
    css`
      gap: ${spacing.lg}px;

      ${StyledImage} {
        > img {
          max-width: 96px;
        }
      }

      ${StyledSvg} {
        width: 96px;
        height: 96px;
      }

      ${StyledTitle} {
        ${getTypographyIntent('h3')}
      }
    `}
}
`
