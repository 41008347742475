import type {
  SensorAPI,
  SnapDragActions,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import type React from 'react'
import { scrollIntoView } from '../_utils/scrollIntoView'
import type { Selection } from './MenuImperative.types'

function noop() {}

const MOUSE_ENTER = 'mouseenter'
const MOUSE_LEAVE = 'mouseleave'

const menuItemsWrapperAttributeName = 'data-core-menuimperative-items-wrapper'
export const menuItemsWrapperAttribute = {
  [menuItemsWrapperAttributeName]: 'true',
}

const isItem = (element: Element | null) => element?.hasAttribute('data-value')

function isGroup(el: HTMLElement) {
  return JSON.parse(el.dataset.group || 'false')
}

const isWrapper = (element: Element | null) =>
  element?.getAttribute(menuItemsWrapperAttributeName) === 'true'

function getFirstItem(menu: Element | null) {
  const firstItem = menu?.querySelector<HTMLElement>('[data-value]:first-child')

  if (firstItem && isGroup(firstItem)) {
    return getNextItem(firstItem)
  }

  return firstItem
}

function getPrevItem(item: Element): HTMLElement | null {
  if (item.previousElementSibling) {
    // LocationFilter in data-table has div-wrapper around some items
    if (isWrapper(item.previousElementSibling)) {
      return getLastItem(item.previousElementSibling) as HTMLElement
    }

    return item.previousElementSibling as HTMLElement
  }

  if (isWrapper(item?.parentElement)) {
    return item?.parentElement?.previousElementSibling as HTMLElement
  }

  if (item?.parentElement?.getAttribute('data-droppable') === 'true') {
    const prevDroppableItems =
      item?.parentElement?.previousElementSibling?.querySelectorAll<HTMLElement>(
        '[data-group="false"]'
      )
    return prevDroppableItems?.length
      ? prevDroppableItems[prevDroppableItems.length - 1]
      : null
  }

  return null
}

function getNextItem(item: Element): HTMLElement | null {
  if (item.nextElementSibling) {
    // LocationFilter in data-table has div-wrapper around some items
    if (isWrapper(item.nextElementSibling)) {
      return getFirstItem(item.previousElementSibling) as HTMLElement
    }
    return item.nextElementSibling as HTMLElement
  }

  if (isWrapper(item?.parentElement)) {
    return item?.parentElement?.nextElementSibling as HTMLElement
  }

  if (item?.parentElement?.getAttribute('data-droppable') === 'true') {
    return item?.parentElement?.nextElementSibling?.querySelector(
      '[data-group="false"]'
    ) as HTMLElement
  }

  return null
}

function getLastItem(menu: Element | null) {
  const items = menu?.querySelectorAll<HTMLElement>('[data-value]')
  const lastItem = items?.[items.length - 1]

  if (lastItem && isGroup(lastItem)) {
    return getPrevItem(lastItem)
  }

  return lastItem
}

function updateHighlightedAttribute(
  item: Element | null | undefined,
  highlight: boolean
) {
  item?.setAttribute('data-highlighted', highlight ? 'true' : 'false')
  item?.dispatchEvent(new MouseEvent(highlight ? MOUSE_ENTER : MOUSE_LEAVE))
}

export function createSensors(
  ref: React.RefObject<HTMLDivElement>,
  circular: boolean,
  onChangeActiveDescendant: (
    id: string | undefined,
    menuRef: Element | null
  ) => void
) {
  let highlightedItem: HTMLElement | null = null
  let dragAndDropApi: SensorAPI | null = null
  let currentDrag: SnapDragActions | null = null
  let onSelect: (selection: Selection) => any = noop

  function select(
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) {
    if (ref.current && highlightedItem) {
      const disabled = JSON.parse(highlightedItem?.dataset.disabled || 'false')
      if (disabled) return

      const item = JSON.parse(highlightedItem?.dataset.value || 'null')
      const selected = JSON.parse(highlightedItem?.dataset.selected || 'false')
      const restoreFocus = highlightedItem?.dataset.restorefocus
        ? JSON.parse(highlightedItem?.dataset.restorefocus)
        : undefined

      if (highlightedItem.tagName === 'A') {
        highlightedItem.click()
      }

      onSelect?.({
        event,
        item,
        group: false,
        action: selected ? 'unselected' : 'selected',
        ...(restoreFocus === undefined ? {} : { restoreFocus }),
      })
    }
  }

  function highlightItem(
    item: HTMLElement | null | undefined,
    shouldScroll: boolean = true
  ) {
    // performance improvement, highlightItem is called onMouseMove for Item
    if (item === highlightedItem) {
      return
    }

    if (item) {
      updateHighlightedAttribute(highlightedItem, false)

      // TODO add localized screenreader instructions for the item
      updateHighlightedAttribute(item, true)

      const id = item.getAttribute('id') ?? undefined
      onChangeActiveDescendant(id, ref.current)

      highlightedItem = item

      if (shouldScroll) {
        scrollIntoView(-1, item)
      }
    } else {
      onChangeActiveDescendant('', ref.current)
    }
  }

  function getPrevHighlightableItem(
    currentItem: HTMLElement
  ): HTMLElement | null | undefined {
    const prev = getPrevItem(currentItem)
    if (prev) {
      if (isItem(prev)) {
        if (isGroup(prev)) {
          return getPrevHighlightableItem(prev)
        } else {
          return prev
        }
      }
    } else if (circular) {
      return getLastItem(ref.current)
    }

    return prev
  }

  function getNextHighlightableItem(
    currentItem: HTMLElement
  ): HTMLElement | null | undefined {
    const next = getNextItem(currentItem)

    if (next) {
      if (isItem(next)) {
        if (isGroup(next)) {
          return getNextHighlightableItem(next)
        }
        return next
      }
    } else if (circular) {
      return getFirstItem(ref.current)
    }
  }

  function highlightFirstItem(shouldScroll: boolean = true) {
    highlightItem(getFirstItem(ref.current), shouldScroll)
  }

  function highlightLastItem(shouldScroll: boolean = true) {
    highlightItem(getLastItem(ref.current), shouldScroll)
  }

  function highlightPrevItem() {
    if (highlightedItem && ref.current?.contains(highlightedItem)) {
      const prev = getPrevHighlightableItem(highlightedItem)
      if (prev) {
        highlightItem(prev)
      }
    } else {
      highlightLastItem()
    }
  }

  function highlightNextItem() {
    if (highlightedItem && ref.current?.contains(highlightedItem)) {
      const next = getNextHighlightableItem(highlightedItem)
      if (next) {
        highlightItem(next)
      }
    } else {
      highlightFirstItem()
    }
  }

  return {
    handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
      if (ref.current) {
        if (event.key === 'ArrowUp' || event.key === 'Up') {
          event.preventDefault()
          currentDrag?.isActive() ? currentDrag.moveUp() : highlightPrevItem()
        } else if (event.key === 'ArrowDown' || event.key === 'Down') {
          event.preventDefault()
          currentDrag?.isActive() ? currentDrag.moveDown() : highlightNextItem()
        } else if (event.key === 'Enter') {
          event.preventDefault()
          if (event.ctrlKey || event.metaKey) {
            const draggableId = highlightedItem?.getAttribute(
              'data-rbd-draggable-id'
            )
            if (draggableId) {
              const preDrag = dragAndDropApi?.tryGetLock(draggableId)
              if (preDrag) {
                currentDrag = preDrag.snapLift()
              }
            }
          } else if (currentDrag?.isActive()) {
            currentDrag.drop()
          } else {
            select(event)
          }
        } else if (event.key === 'Escape' && currentDrag?.isActive()) {
          currentDrag.cancel()
        }
      }
    },
    handleItemHover(el?: HTMLDivElement) {
      highlightItem(el, false)
    },
    useKeyboardSensor(api: SensorAPI) {
      dragAndDropApi = api
    },

    updateSelectCallback(callback: (selection: Selection) => any) {
      onSelect = callback
    },

    highlight: highlightItem,

    highlighted() {
      return JSON.parse(highlightedItem?.dataset.value || 'null')
    },

    highlightFirst: highlightFirstItem,
    highlightLast: highlightLastItem,
    rehighlightCurrent() {
      // Rehighlights an item that may have lost its styling due to the re-render of the list items
      const draggableId = highlightedItem?.getAttribute('data-rbd-draggable-id')
      if (draggableId) {
        const newItem = ref.current?.querySelector<HTMLElement>(
          `[data-rbd-draggable-id="${draggableId.replace(/["\\]/g, '\\$&')}"]`
        )
        highlightItem(newItem)
      }
    },

    highlightSelected(shouldScroll: boolean = true) {
      const selectedItem = ref.current?.querySelector<HTMLElement>(
        '[data-selected="true"]'
      )

      highlightItem(selectedItem, shouldScroll)
    },

    highlightSuggested(shouldScroll: boolean = true) {
      const suggestedItem = ref.current?.querySelector<HTMLElement>(
        '[data-suggested="true"]'
      )

      highlightItem(suggestedItem, shouldScroll)
    },

    prev: highlightPrevItem,
    next: highlightNextItem,
    select,
  }
}
