import styled from 'styled-components'
import { Avatar } from '../Avatar'
import { ContactItem } from '../ContactItem'
import { Modal } from '../Modal'
import type { Color } from '../_styles/colors'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

const maxModalWidth = 528

const StyledBaseAvatar = styled(Avatar)`
  border-color: ${colors.white};
  border-style: solid;
  border-width: 1px;

  &:hover {
    z-index: 1;
  }
  &[role='img']:focus {
    outline: none;
  }
`

export const StyledAvatar = styled(StyledBaseAvatar)<{ $color: Color }>`
  background-color: ${(props: { $color: Color }) => colors[props.$color]};
`

export const StyledContactItems = styled.div`
  padding-top: ${spacing.sm}px;
  padding-bottom: ${spacing.sm}px;
  padding-left: ${spacing.xs}px;
`

export const StyledWrapper = styled.div`
  display: flex;

  ${StyledAvatar} + ${StyledAvatar} {
    margin-left: -${spacing.sm}px;
  }
`

export const StyledViewAllWrapper = styled.div`
  display: flex;
  margin-left: ${spacing.md}px;
  margin-bottom: ${spacing.lg}px;
`

export const StyledModalBody = styled(Modal.Body)`
  max-width: ${maxModalWidth}px;
`

export const StyledContactItem = styled(ContactItem)`
  flex-shrink: 0;
`
