import styled, { css } from 'styled-components'
import { colors } from '../_styles/colors'
import { getGapOutlineFocus } from '../_styles/mixins'
import type { BaseAvatarProps } from './Avatar.types'

interface StyledAvatarProps {
  $clickable: BaseAvatarProps['clickable']
  $disabled: BaseAvatarProps['disabled']
  $size: BaseAvatarProps['size']
}

const activeOverlayOpacity = 0.3

const containerSize = { xl: 96, lg: 40, md: 32, sm: 24 } as const

const fontSize = { xl: 40, lg: 14, md: 14, sm: 12 } as const

const fontWeights = { xl: 700, lg: 600, md: 600, sm: 600 } as const

const iconSize = { xl: 48, lg: 24, md: 16, sm: 16 } as const

export const StyledAvatarOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  opacity: 0;
`

export const StyledIconContainer = styled.div`
  display: inline-flex;
`

export const StyledLabelContainer = styled.div`
  text-transform: uppercase;
`

export const StyledPortraitContainer = styled.div<{ $imageUrl: string }>`
  background-color: ${colors.white};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  ${({ $imageUrl }) =>
    css`
      background-image: url(${$imageUrl});
    `}
`

export const StyledAvatarContainer = styled.div<StyledAvatarProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  cursor: default;
  color: ${colors.white};
  background-color: ${colors.gray30};
  text-decoration: none;

  a:has(> &) {
    text-decoration: none;
  }

  &:focus {
    ${getGapOutlineFocus}
  }
  // For safety, separate rule block with 'has' for supported browsers
  // this supports any anchor directly wrapping an Avatar.
  // 'outline' works better on display inline-block and inline-flex,
  // elements, unfortunately anchors and inline by default.
  a:focus:has(> &) {
    ${getGapOutlineFocus}
    border-radius: 100%;
  }

  ${({ $size = 'md' }) => css`
    font-size: ${fontSize[$size]}px;
    font-weight: ${fontWeights[$size]};
    height: ${containerSize[$size]}px;
    width: ${containerSize[$size]}px;
    min-height: ${containerSize[$size]}px;
    min-width: ${containerSize[$size]}px;

    ${StyledIconContainer} {
      svg {
        height: ${iconSize[$size]}px;
        width: ${iconSize[$size]}px;
      }
    }
  `}

  ${({ $disabled, $clickable }) => {
    if (!$disabled && $clickable) {
      return css`
        cursor: pointer;

        &:hover {
          ${StyledAvatarOverlay} {
            opacity: ${activeOverlayOpacity};
          }
        }

        ${StyledAvatarOverlay} {
          background-color: ${colors.black};
        }
      `
    }

    if ($disabled && !$clickable) {
      return css`
        background-color: ${colors.gray85};

        ${StyledAvatarOverlay} {
          background-color: ${colors.white};
          opacity: ${activeOverlayOpacity};
        }
      `
    }

    if ($disabled && $clickable) {
      return css`
        cursor: pointer;
        background-color: ${colors.gray85};

        &:hover {
          ${StyledAvatarOverlay} {
            background-color: ${colors.black};
            opacity: ${activeOverlayOpacity};
          }
        }

        ${StyledAvatarOverlay} {
          background-color: ${colors.white};
          opacity: ${activeOverlayOpacity};
        }
      `
    }
  }};
`
