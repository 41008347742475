import { Clear } from '@procore/core-icons/dist'

import React from 'react'
import { Button } from '../Button/Button'
import { StyledModal, StyledModalScrim } from '../Modal/Modal.styles'
import { useModalDialogLike } from '../OverlayTrigger/a11yPresets'
import { Portal } from '../Portal/Portal'
import { useEventRegistry } from '../_hooks/EventRegistry'
import { OverridableFocusScope } from '../_hooks/FocusScopeOverride'
import { useHotkey } from '../_hooks/Hotkey'
import { useI18nContext } from '../_hooks/I18n'
import { useScrollLock } from '../_hooks/ScrollLock'
import { useUpdateEffect } from '../_hooks/useUpdateEffect'
import {
  CloseWithConfirmContext,
  useCloseWithConfirmState,
} from '../_utils/closeWithConfirm'
import {
  closingPhasesInOrder,
  openingPhasesInOrder,
} from './Tearsheet.constants'
import {
  animationSpeed,
  StyledButtonCard,
  StyledButtonContainer,
  StyledScrimContainer,
  StyledTearsheetBody,
  StyledTearsheetContent,
} from './Tearsheet.styles'
import type {
  AnimationPhase,
  AnimationStatus,
  TearsheetContextProps,
  TearsheetProps,
} from './Tearsheet.types'
import { getAnimationState, getScrimClassName, wait } from './Tearsheet.util'

export const TearsheetContext = React.createContext<TearsheetContextProps>({
  placement: null,
  addListener: () => () => {},
})

/**

 The Tearsheet is a container for complex content placed over another layout,
 such as the Detail Page layout. They slide out from one direction on the screen.
 They allow users complete complex and detail rich tasks without leaving the page they were on.

 @since 10.25.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-tearsheet--demo)

  @see [Design Guidelines](https://design.procore.com/tearsheet)

 */
export const Tearsheet = React.forwardRef<HTMLDivElement, TearsheetProps>(
  function Tearsheet(
    {
      ['aria-describedby']: ariaDescribedby,
      ['aria-details']: ariaDetails,
      ['aria-labelledby']: ariaLabelledby,
      ['aria-label']: ariaLabel,
      block = false,
      children,
      id,
      open,
      onClose: onClose_,
      afterShow,
      afterHide,
      placement = 'right',
      role,
      stretch = false,
      qa,
    },
    ref
  ) {
    const I18n = useI18nContext()
    const [status, setStatus] = React.useState<AnimationStatus>('closed')
    const [phase, setPhase] = React.useState<AnimationPhase>('disabled')
    const [isVisible, setIsVisible] = React.useState<boolean>(open)
    const closeBtnRef = React.useRef<HTMLButtonElement>(null)
    const closeWithConfirmState = useCloseWithConfirmState()
    const { closeWithConfirm } = closeWithConfirmState
    const onClose = closeWithConfirm(onClose_)

    const { dialogProps, focusScopeProps } = useModalDialogLike({
      'aria-describedby': ariaDescribedby,
      'aria-details': ariaDetails,
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      id,
      isModal: false,
      isOpen: isVisible && status === 'open',
      role,
    })

    const { registry, addListener } = useEventRegistry()

    const ctx = React.useMemo(
      () => ({
        addListener,
        placement,
      }),
      [placement, addListener]
    )

    useScrollLock(status !== 'closed')

    React.useEffect(() => {
      // do not run 'closing' animation if the initial state is closed
      if (open) {
        setStatus('opening')
      }
    }, [])

    useUpdateEffect(() => {
      setStatus(open ? 'opening' : 'closing')
    }, [open])

    function handleClose() {
      if (status === 'open') {
        onClose()
      }
    }

    useHotkey({
      key: ['Escape', 'Esc'],
      handler: handleClose,
    })

    React.useEffect(() => {
      ;(async () => {
        if (status === 'opening') {
          setIsVisible(true)

          for (const openingPhase of openingPhasesInOrder) {
            setPhase(openingPhase)
            await wait(animationSpeed[openingPhase])
          }

          setStatus('open')
          setPhase('disabled')
          closeBtnRef.current?.focus()
          await wait(0)
          registry.current.afterShow?.forEach((cb) => cb())
          afterShow?.()

          return
        }

        if (status === 'closing') {
          for (const closingPhase of closingPhasesInOrder) {
            setPhase(closingPhase)
            await wait(animationSpeed[closingPhase])
          }

          setStatus('closed')
          setPhase('disabled')
          setIsVisible(false)
          afterHide?.()
        }
      })()
    }, [status])

    if (!isVisible) {
      return null
    }

    return (
      <Portal>
        <OverridableFocusScope {...focusScopeProps} restoreFocus>
          <StyledModal ref={ref} className={getScrimClassName(status, phase)}>
            <StyledModalScrim />
            <StyledTearsheetContent
              {...dialogProps}
              ref={ref}
              $placement={placement}
              {...getAnimationState('tearsheet', phase, status)}
            >
              <StyledScrimContainer
                $placement={placement}
                onClick={handleClose}
                data-qa={qa?.scrim}
              >
                <StyledButtonContainer $placement={placement}>
                  <div>
                    <StyledButtonCard
                      {...getAnimationState('closeButton', phase, status)}
                    >
                      <Button
                        ref={closeBtnRef}
                        aria-label={I18n.t('core.tearsheet.a11y.close')}
                        onClick={(e) => {
                          e.stopPropagation()
                          onClose()
                        }}
                        size="lg"
                        variant="tertiary"
                        icon={<Clear />}
                        data-qa={qa?.closeButton}
                      />
                    </StyledButtonCard>
                  </div>
                </StyledButtonContainer>
              </StyledScrimContainer>
              <StyledTearsheetBody
                onScroll={() => {
                  registry.current.scroll?.forEach((cb) => cb())
                }}
                $placement={placement}
                $stretch={stretch}
                $block={block}
              >
                <TearsheetContext.Provider value={ctx}>
                  <CloseWithConfirmContext.Provider
                    value={closeWithConfirmState}
                  >
                    {children}
                  </CloseWithConfirmContext.Provider>
                </TearsheetContext.Provider>
              </StyledTearsheetBody>
            </StyledTearsheetContent>
          </StyledModal>
        </OverridableFocusScope>
      </Portal>
    )
  }
) as (
  props: TearsheetProps & {
    /**
     * @deprecatedSince 11.11.0
     * @deprecated This ref is passed to two elements, in the future it will only be provided to the inner element (StyledTearsheetContent).
     */
    ref?: React.ComponentPropsWithRef<'div'>['ref']
  }
) => React.ReactElement
