import { Editor } from '@tinymce/tinymce-react'
import React, { useState } from 'react'
import { useOverridableFocusScope } from '../_hooks/FocusScopeOverride'
import { useI18nContext } from '../_hooks/I18n'
import { useZIndexContext } from '../_hooks/ZIndex'
import {
  defaultPlugins,
  generateExternalPlugins,
  generateLanguageUrl,
  getValidLookupLocale,
  tinyMCEConfig,
  tinyMCESource,
  tinyMCETabMarkup,
  tinyMCEVersion,
} from '../_utils/TinyMCE'
import { GlobalStyle } from './TextEditor.styles'
import type {
  TextEditorProps,
  TextEditorProviderProps,
} from './TextEditor.types'

const errorRed = '#E61920'

function noop() {}

const TextEditorContext = React.createContext<
  Omit<TextEditorProviderProps, 'children'>
>({
  features: {
    tabAsNavigation: undefined,
    stickyToolbar: undefined,
  },
})

export function TextEditorProvider({
  children,
  features,
}: TextEditorProviderProps) {
  return (
    <TextEditorContext.Provider value={{ features }}>
      {children}
    </TextEditorContext.Provider>
  )
}

function setupDefaultShortcuts(editor: any) {
  editor.shortcuts.add('meta+shift+s', 'Strikethrough', 'Strikethrough')
}

/**
 * editor.addShortcut does not notify on Tab key presses
 */
function setupShortcuts(editor: any) {
  setupDefaultShortcuts(editor)
  editor.on('keydown', function checkTabPress(event: React.KeyboardEvent) {
    if (event.altKey && event.key === 'Tab') {
      editor.execCommand('mceInsertContent', false, tinyMCETabMarkup)
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  })
}

/**
 * The following plugins open a TinyMCE modal.
 * When used with React Aria contained FocusScope,
 * focus does not move into the TinyMCE modal.
 * This patch gets critical functionality back at
 * the cost of possibly failing accessbility.
 */
function useFocusScopePatch(plugins: string[]) {
  const focusScope = useOverridableFocusScope()
  React.useEffect(() => {
    if (
      [...defaultPlugins, ...plugins].some(
        // Add plugins that open a tinymce modal here
        (plugin) =>
          plugin === 'link' || plugin === 'table' || plugin === 'image'
      )
    ) {
      focusScope.setProps({ contain: false })
    }
  }, [focusScope, plugins])
}

/**

 We use text editors to allow users to enter multiple lines of formatted text.

 If users need to add unformatted text, see Text Area.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-texteditor--demo)

 @see [Design Guidelines](https://design.procore.com/text-editor)

 */
export function TextEditor({
  error,
  init: initOverrides,
  locale,
  onChange = noop,
  onInit: _onInit,
  plugins = defaultPlugins,
  ...props
}: TextEditorProps) {
  const { locale: contextLocale } = useI18nContext()
  const { value: zIndex } = useZIndexContext()

  const { features } = React.useContext(TextEditorContext)
  const stickyToolbar = features?.stickyToolbar ? { toolbar_sticky: true } : {}
  const tabControls = features?.tabAsNavigation
    ? {
        external_plugins: generateExternalPlugins(
          plugins,
          defaultPlugins.filter((p) => p !== 'nonbreaking')
        ),
        nonbreaking_force_tab: false,
        setup: setupShortcuts,
      }
    : {
        setup: setupDefaultShortcuts,
      }

  const onEditorChange: React.ComponentProps<
    typeof Editor
  >['onEditorChange'] = (_: any, editor: any) => {
    onChange(editor.getContent(), editor.isDirty())
  }

  const ref = React.useRef<HTMLElement>()

  const updateError = () => {
    if (ref.current) {
      if (error) {
        ref.current.style.borderColor = errorRed
      } else {
        ref.current.style.borderColor = ''
      }
    }
  }

  React.useEffect(() => {
    updateError()
  }, [error])

  useFocusScopePatch(plugins)

  const onInit: React.ComponentProps<typeof Editor>['onInit'] = (
    event,
    editor
  ) => {
    ref.current = editor.editorContainer
    updateError()
    _onInit && _onInit(event, editor)
  }

  const [derivedInitial] = useState(props.initialValue ?? props.value)

  // zIndex + 1 because on first render zIndex of Portal(Modal) is higher
  return (
    <>
      <GlobalStyle $zIndex={zIndex + 1} />
      <Editor
        init={{
          ...tinyMCEConfig,
          external_plugins: generateExternalPlugins(plugins),
          language_url: generateLanguageUrl(locale || contextLocale),
          language: getValidLookupLocale(locale || contextLocale),
          content_style: `p { margin: 0; }`,
          ...stickyToolbar,
          ...tabControls,
          ...initOverrides,
        }}
        tinymceScriptSrc={`${tinyMCESource}/${tinyMCEVersion}/tinymce.min.js`}
        onInit={onInit}
        onEditorChange={onEditorChange}
        {...props}
        value={props.value ?? props.initialValue}
        initialValue={derivedInitial}
      />
    </>
  )
}

TextEditor.displayName = 'TextEditor'
