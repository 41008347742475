import React from 'react'
import {
  StyledCircle,
  StyledSpinnerContainer,
  StyledSpinnerContainerWithLabel,
  StyledSpinnerOverlay,
  StyledSpinnerOverlayContainer,
  StyledSpinnerOverlayLabel,
  StyledSpinnerSVG,
} from './Spinner.styles'
import type { SpinnerProps, SpinnerSize } from './Spinner.types'

const radiusMap: Record<SpinnerSize, number> = {
  xs: 18.5,
  sm: 18,
  md: 20,
  lg: 20.5,
}

const smallSizes: SpinnerSize[] = ['xs', 'sm']

export const defaultSpinnerSize: SpinnerSize = 'lg'

const Circle = React.forwardRef<
  HTMLDivElement,
  SpinnerProps & {
    mountTime: number
  }
>(function Circle(
  {
    className = '',
    color,
    loading = false,
    mountTime,
    size = defaultSpinnerSize,
    variant = 'default',
  },
  ref
) {
  return (
    <StyledSpinnerContainer
      className={className}
      $color={color}
      $size={size}
      $variant={variant}
      ref={ref}
    >
      <StyledSpinnerSVG
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        $animate={loading}
        $mountTime={mountTime}
      >
        <StyledCircle
          cx="24"
          cy="24"
          r={radiusMap[size]}
          $animate={loading}
          $mountTime={mountTime}
        />
      </StyledSpinnerSVG>
    </StyledSpinnerContainer>
  )
})

/**

 Spinners indicate a loading state on either a full page or smaller component,
 like a menu, select or button.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-spinner--demo)

 @see [Design Guidelines](https://design.procore.com/spinner)

 */
export const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  function Spinner(props, ref) {
    const {
      children,
      className = '',
      color,
      label,
      loading = false,
      size = defaultSpinnerSize,
      variant = 'default',
      centered = false,
      ...rest
    } = props
    const mountTime = React.useRef(Date.now())

    if (!children) {
      // We are preserving the current behavior of the Spinner component,
      // where the loading prop does not apply when no children are passed.
      return (
        <Circle {...props} loading mountTime={mountTime.current} ref={ref} />
      )
    }

    return (
      <StyledSpinnerOverlayContainer
        $loading={loading}
        className={className}
        $centered={centered}
        {...rest}
      >
        <StyledSpinnerOverlay />
        <StyledSpinnerContainerWithLabel>
          <Circle
            {...props}
            className=""
            mountTime={mountTime.current}
            ref={ref}
          />
          {!smallSizes.includes(size) && (
            <StyledSpinnerOverlayLabel>{label}</StyledSpinnerOverlayLabel>
          )}
        </StyledSpinnerContainerWithLabel>
        {children}
      </StyledSpinnerOverlayContainer>
    )
  }
)

Spinner.displayName = 'Spinner'
