import type { FunctionComponent, PropsWithChildren } from 'react'
import React from 'react'
import { useEventListener } from '../_hooks/EventListener'
import { mergeRefs } from '../_utils/mergeRefs'
import {
  StyledButton,
  StyledContent,
  StyledLabel,
  StyledLinkButton,
  StyledSpinner,
} from './Button.styles'
import type {
  ButtonProps,
  ButtonVariant,
  LinkButtonProps,
} from './Button.types'

const SPINNER_VARIANT: Record<ButtonVariant, 'white' | 'gray15'> = {
  primary: 'white',
  secondary: 'gray15',
  tertiary: 'gray15',
  tertiaryContrast: 'gray15',
  UNSAFE_helixNav: 'white',
}

const ButtonContent: FunctionComponent<
  PropsWithChildren<
    Pick<ButtonProps, 'icon' | 'iconRight' | 'size' | 'variant'>
  >
> = ({ children, icon, iconRight, size, variant }) => {
  return (
    <>
      <StyledContent>
        {React.isValidElement<{ ['data-icon-prop']: string }>(icon)
          ? React.cloneElement(icon, { ['data-icon-prop']: 'true' })
          : null}
        {React.Children.map(children, (child) => {
          return <StyledLabel>{child}</StyledLabel>
        })}
        {React.isValidElement<{ ['data-icon-prop']: string }>(iconRight)
          ? React.cloneElement(iconRight, { ['data-icon-prop']: 'true' })
          : null}
      </StyledContent>
      <StyledSpinner
        size={size === 'lg' ? 'md' : 'sm'}
        color={variant ? SPINNER_VARIANT[variant] : 'gray15'}
      />
    </>
  )
}

/**

 We use buttons to link to other pages or to trigger an action.
 Buttons clearly define what happens when a user clicks it.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-button--demo)

 @see [Design Guidelines](https://design.procore.com/button)

 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      block = false,
      children,
      disabled = false,
      icon,
      iconRight,
      loading = false,
      size = 'md',
      type = 'button',
      variant = 'primary',
      ...props
    },
    ref
  ) {
    return (
      <StyledButton
        aria-busy={loading || undefined}
        data-a11y-skip={variant === 'primary' ? 'color-contrast' : undefined}
        tabIndex={0}
        {...props}
        $block={block}
        disabled={disabled || loading}
        $loading={loading}
        $size={size}
        $variant={variant}
        type={type}
        ref={ref}
      >
        <ButtonContent
          icon={icon}
          iconRight={iconRight}
          size={size}
          variant={variant}
        >
          {children}
        </ButtonContent>
      </StyledButton>
    )
  }
)

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButton(
    {
      block = false,
      children,
      disabled: disabled_,
      href: href_,
      icon,
      iconRight,
      size = 'md',
      variant = 'primary',
      ...props
    },
    ref
  ) {
    const innerRef = React.useRef<HTMLAnchorElement>(null)

    const mimicButtonRole = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      const { key } = e

      if (['Enter', 'Spacebar', ' '].indexOf(key) >= 0) {
        // Prevents the page to scroll when "space" is pressed
        e.preventDefault()
        innerRef.current?.click()
      }
    }

    useEventListener({
      event: 'keydown',
      handler: mimicButtonRole,
      scope: innerRef,
    })

    const disabled = disabled_ || !href_
    const href = disabled ? undefined : href_

    return (
      <StyledLinkButton
        data-a11y-skip={variant === 'primary' ? 'color-contrast' : undefined}
        role="button"
        {...props}
        aria-disabled={disabled}
        href={href}
        $disabled={disabled}
        $block={block}
        $size={size}
        $variant={variant}
        ref={mergeRefs(ref, innerRef)}
      >
        <ButtonContent
          icon={icon}
          iconRight={iconRight}
          size={size}
          variant={variant}
        >
          {children}
        </ButtonContent>
      </StyledLinkButton>
    )
  }
)
