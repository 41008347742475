import { ChevronRight } from '@procore/core-icons'
import type { ForwardedRef } from 'react'
import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  DivAttributes,
  LiAttributes,
  NavAttributes,
} from '../_utils/types'
import {
  StyledContainer,
  StyledCrumb,
  StyledList,
  StyledSeparator,
} from './Breadcrumbs.styles'
import type { BreadcrumbCrumbProps, BreadcrumbProps } from './Breadcrumbs.types'
import { BreadcrumbVariants } from './Breadcrumbs.types'

const separator = (
  <StyledSeparator aria-hidden={true}>
    <ChevronRight />
  </StyledSeparator>
)

const Breadcrumbs_ = React.forwardRef<
  HTMLElement,
  NavAttributes & BreadcrumbProps
>(function Breadcrumbs(
  { children, variant = BreadcrumbVariants.LEGACY, ...props },
  ref
) {
  const i18n = useI18nContext()
  const childrenArray = React.Children.toArray(children)
  const isLegacy = variant === BreadcrumbVariants.LEGACY

  const navigationList = childrenArray
    .filter((child) => child)
    .map((child, i) => {
      const isLast = i === childrenArray.length - 1
      const aria = {
        'aria-current': (child as React.ReactElement).props.active || undefined,
      }
      const cloneProps = isLegacy
        ? aria
        : {
            ...aria,
            isLast,
            variant,
          }

      return (
        <React.Fragment key={`separator-${i}`}>
          {React.cloneElement(child as React.ReactElement, cloneProps)}
          {isLegacy && !isLast ? separator : <></>}
        </React.Fragment>
      )
    })
  return (
    <StyledContainer
      aria-label={i18n.t('core.breadcrumbs.label')}
      {...props}
      ref={ref}
    >
      {!isLegacy ? <StyledList>{navigationList}</StyledList> : navigationList}
    </StyledContainer>
  )
})

type NavCrumbProps = LiAttributes & BreadcrumbCrumbProps
type LegacyCrumbProps = DivAttributes & BreadcrumbCrumbProps

export const Crumb = React.forwardRef<
  HTMLLIElement | HTMLDivElement,
  NavCrumbProps | LegacyCrumbProps
>(function Crumb({ variant = BreadcrumbVariants.LEGACY, ...props }, ref) {
  const childrenWithAriaAttrs = React.Children.toArray(props.children)
    .filter((child) => child)
    .map((child) => {
      if (React.isValidElement<{ 'aria-current'?: boolean }>(child)) {
        return React.cloneElement(child, {
          'aria-current': props.active || undefined,
        })
      } else {
        return child
      }
    })
  const isLegacy = variant === BreadcrumbVariants.LEGACY
  const showSeparator =
    !isLegacy && props.hasOwnProperty('isLast') && !props.isLast
  const { isLast, ...crumbProps } = props
  return (
    <StyledCrumb
      as={isLegacy ? 'div' : 'li'}
      {...(crumbProps as NavCrumbProps)}
      ref={ref as ForwardedRef<HTMLLIElement>}
    >
      {childrenWithAriaAttrs}
      {showSeparator ? separator : <></>}
    </StyledCrumb>
  )
})

Breadcrumbs_.displayName = 'Breadcrumbs'

Crumb.displayName = 'Breadcrumbs.Crumb'

//  * @codemod npx ...
//  * @deprecatedSince 12.x.x
/**
 * @description  We use breadcrumbs to provide navigational information to the user on every
 * tool’s child page in Procore. The last element is the active view.
 *
 * __Important!__ Prior versions support placing an anchor element in-between `Breadcrumbs` and
 * `Breadcrumbs.Crumb` elements; going forward anchor elements must be inside `Breadcrumbs.Crumb`.
 *
 * Additionally __set `variant="list"` to enable list markup__, it will eventually be the default
 * but requires users to place hyperlinks inside the `Breadcrumbs.Crumb` (`li`) to be proper HTML.

BEFORE:
```
<Breadcrumbs>
  <a href="#">
    <Breadcrumbs.Crumb>Deprecated</Breadcrumbs.Crumb>
  </a>
</Breadcrumbs>
```

AFTER:
```
<Breadcrumbs variant="list">
  <Breadcrumbs.Crumb>
    <a href="#">Standard</a>
  </Breadcrumbs.Crumb>
</Breadcrumbs>
```

 @a11y Use `variant="list"` for semantic `ol`, `li` elements in addition to `nav`. When multiple
 breadcrumbs are in view, it is recommended each have an `aria-label` or `aria-labelledby`.

 @example
  <Breadcrumbs variant="list">
    <Breadcrumbs.Crumb>
      <a href="#">List Items</a>
    </Breadcrumbs.Crumb>
    <Breadcrumbs.Crumb active>
      Current Item
    <Breadcrumbs.Crumb>
  </Breadcrumbs>

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-breadcrumbs--demo)

 @see [Design Guidelines](https://design.procore.com/breadcrumb)

 */
export const Breadcrumbs = addSubcomponents(
  {
    Crumb,
  },
  Breadcrumbs_
)
