import React from 'react'
import { ulid } from 'ulid'
import {
  StyledDropdownFlyoutExpandIcon,
  StyledDropdownFlyoutLabel,
} from './DropdownFlyout.styles'
import type {
  Action,
  DropdownFlyoutProps,
  DropdownOption,
  FlyoutOption,
  FlyoutOptionId,
  State,
} from './DropdownFlyout.types'
import { rootId } from './DropdownFlyout.types'

export function noop() {}

export function returnFalse() {
  return false
}

function getFirst<T extends any>(arr: T[]) {
  return arr[0]
}
function isRootOption(optionId: FlyoutOptionId) {
  return optionId === rootId
}

export function hasChildren(option: DropdownOption) {
  return Array.isArray(option.children)
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'highlight':
      return {
        ...state,
        highlighted: action.option,
      }
    case 'expand':
      return {
        ...state,
        highlighted: action.option,
        expanded: [
          ...collectParentIds(state.options, action.option.id),
          action.option.id,
        ],
      }
    case 'collapse':
      return {
        ...state,
        highlighted: action.option,
        expanded: collectParentIds(state.options, action.option.id),
      }
    case 'close':
      return {
        ...state,
        expanded: [],
        highlighted: getFirst(state.options),
        isOpen: false,
      }
    case 'open':
      return {
        ...state,
        isOpen: true,
      }
    case 'setMouseOver':
      return {
        ...state,
        isMouseOver: action.isMouseOver,
      }
    case 'setFocused':
      return {
        ...state,
        isFocused: action.isFocused,
      }
    case 'updateOptions':
      return {
        ...state,
        options: action.options,
      }
    default:
      return state
  }
}

function findById(
  options: FlyoutOption[],
  optionId: FlyoutOption['id'],
  initialValue: FlyoutOption = getFirst(options)
): FlyoutOption {
  return options.reduce((result, current) => {
    if (current.id === optionId) {
      return current
    }

    if (current.children) {
      return findById(current.children, optionId, result)
    }

    return result
  }, initialValue)
}

export function transformOption(
  options: DropdownOption[],
  parentId: FlyoutOption['parentId']
): FlyoutOption[] {
  return options.reduce<FlyoutOption[]>((acc, option) => {
    const id = ulid()
    const updatedOption = {
      id,
      parentId,
      origin: option,
      ...(Array.isArray(option.children) && {
        children: transformOption(option.children, id),
      }),
    }

    return [...acc, updatedOption]
  }, [])
}

function collectParentIds(
  options: FlyoutOption[],
  optionId: FlyoutOption['id']
): FlyoutOption['id'][] {
  const option = findById(options, optionId)

  if (!isRootOption(option.parentId)) {
    return [...collectParentIds(options, option.parentId), option.parentId]
  }
  return [option.parentId]
}

export function useDropdownFlyout({
  flyoutOptions,
  onClick: _onClick = noop,
  onKeyDown: _onKeyDown,
}: Pick<DropdownFlyoutProps, 'onClick' | 'onKeyDown'> & {
  flyoutOptions: FlyoutOption[]
}) {
  const [
    { options, expanded, highlighted, isOpen, isMouseOver, isFocused },
    dispatch,
  ] = React.useReducer(reducer, {
    options: flyoutOptions,
    expanded: [],
    highlighted: getFirst(flyoutOptions),
    isMouseOver: false,
    isFocused: false,
    isOpen: false,
  })

  const setMouseOver = React.useCallback(
    (isMouseOver: boolean) => dispatch({ type: 'setMouseOver', isMouseOver }),
    []
  )

  const setFocused = React.useCallback(
    (isFocused: boolean) => dispatch({ type: 'setFocused', isFocused }),
    []
  )

  const isExpanded = (option: FlyoutOption) => expanded.includes(option.id)

  const isHighlighted = (option: FlyoutOption): boolean => {
    const parentIds = collectParentIds(options, highlighted.id)
    const isCorrectId =
      option.id === highlighted.id || parentIds.some((id) => id === option.id)
    return (isMouseOver || isFocused) && isCorrectId
  }

  const findOptionById = React.useCallback(
    (id: FlyoutOptionId) => findById(options, id),
    [options]
  )

  const expand = React.useCallback(
    (option: FlyoutOption) =>
      dispatch({
        type: 'expand',
        option,
      }),
    []
  )

  const collapse = React.useCallback(
    (option: FlyoutOption) =>
      dispatch({
        type: 'collapse',
        option,
      }),
    []
  )

  const highlight = React.useCallback(
    (option: FlyoutOption) =>
      dispatch({
        type: 'highlight',
        option,
      }),
    []
  )

  const traversingHighlight = React.useCallback(
    (item: 'next' | 'prev' | 'children' | 'parent') => {
      const tier = isRootOption(highlighted.parentId)
        ? options
        : findOptionById(highlighted.parentId).children || []
      const optionIndex = tier.findIndex((item) => item.id === highlighted.id)

      switch (item) {
        case 'next':
          const nextIndex = optionIndex + 1 >= tier.length ? 0 : optionIndex + 1

          highlight(tier[nextIndex])
          break
        case 'prev':
          const prevIndex =
            optionIndex - 1 < 0 ? tier.length - 1 : optionIndex - 1

          highlight(tier[prevIndex])
          break
        case 'children':
          if (Array.isArray(highlighted.children)) {
            expand(highlighted)

            highlight(getFirst(highlighted.children))
          }
          break
        case 'parent':
          if (!isRootOption(highlighted.parentId)) {
            const parentOption = findOptionById(highlighted.parentId)
            collapse(parentOption)
          }
          break
        default:
          return
      }
    },
    [options, findOptionById, collapse, highlighted, expand, highlight]
  )

  const closeDropdown = React.useCallback(() => {
    dispatch({ type: 'close' })
  }, [])

  const closeSelectedDropdown = React.useCallback(
    (e: Event | React.FocusEvent) => {
      if (
        e &&
        e.type === 'click' &&
        !(e as MouseEvent)
          .composedPath()
          .some((node) => (node as HTMLElement).dataset?.flyout)
      ) {
        dispatch({ type: 'close' })
      }
    },
    []
  )

  const openDropdown = React.useCallback(() => {
    dispatch({ type: 'open' })
  }, [])

  const onClick = React.useCallback(
    (option: FlyoutOption) => {
      if (!hasChildren(option.origin)) {
        _onClick(option.origin)
        closeDropdown()
      }
    },
    [_onClick, closeDropdown]
  )

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = e

      switch (key) {
        case 'Enter':
          e.preventDefault()
          if (!isOpen && isFocused) {
            openDropdown()
          } else {
            if (hasChildren(highlighted.origin)) {
              traversingHighlight('children')
            } else {
              onClick(highlighted)
            }
          }
          break
        case 'Escape':
        case 'Esc':
          e.preventDefault()
          if (isRootOption(highlighted.parentId)) {
            if (isOpen) {
              e.stopPropagation?.()
            }
            closeDropdown()
          } else {
            e.stopPropagation?.()
            traversingHighlight('parent')
          }
          break
        case 'ArrowDown':
        case 'Down':
          e.preventDefault()
          traversingHighlight('next')
          break
        case 'ArrowUp':
        case 'Up':
          e.preventDefault()
          traversingHighlight('prev')
          break
      }
      _onKeyDown?.(e)
    },
    [
      closeDropdown,
      openDropdown,
      traversingHighlight,
      highlighted,
      isOpen,
      isFocused,
      onClick,
      _onKeyDown,
    ]
  )
  // There was an issue that was fixed in scope of UISP-215 https://procoretech.atlassian.net/browse/UISP-215
  // UseEffect is necessary for updating state in UseReducer because props don't automatically update state. (In this case: property "options")
  React.useEffect(() => {
    dispatch({ type: 'updateOptions', options: flyoutOptions })
    dispatch({ type: 'highlight', option: getFirst(flyoutOptions) })
  }, [flyoutOptions])

  return {
    isFocused,
    isMouseOver,
    isOpen,
    options,
    expanded,
    highlighted,
    expand,
    collapse,
    isHighlighted,
    isExpanded,
    closeDropdown,
    closeSelectedDropdown,
    openDropdown,
    onKeyDown,
    onClick,
    setMouseOver,
    setFocused,
  }
}

export function defaultOptionRenderer(option: DropdownOption) {
  return (
    <>
      <StyledDropdownFlyoutLabel>{option.label}</StyledDropdownFlyoutLabel>
      {hasChildren(option) && (
        <StyledDropdownFlyoutExpandIcon data-qa="core-dropdown-flyout-option-expand-icon" />
      )}
    </>
  )
}

export const DropdownFlyoutContext = React.createContext<{
  onClick: (option: FlyoutOption) => void

  expand: (option: FlyoutOption) => void
  collapse: (option: FlyoutOption) => void
  optionRenderer: (option: DropdownOption) => React.ReactNode

  isExpanded: (option: FlyoutOption) => boolean
  isHighlighted: (option: FlyoutOption) => boolean
}>({
  onClick: noop,

  expand: noop,
  collapse: noop,
  optionRenderer: defaultOptionRenderer,

  isExpanded: returnFalse,
  isHighlighted: returnFalse,
})

export const useDropdownFlyoutContext = () =>
  React.useContext(DropdownFlyoutContext)
