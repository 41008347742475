import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "change-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#change-log",
        "aria-label": "change log permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Change Log`}</h1>
    <h2 {...{
      "id": "12181",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12181",
        "aria-label": "12181 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.18.1`}</h2>
    <h3 {...{
      "id": "patch-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes",
        "aria-label": "patch changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`a34bc6a: Upgrades `}<inlineCode parentName="li">{`@tinymce/tinymce-react`}</inlineCode>{`, removes `}<inlineCode parentName="li">{`tinymce`}</inlineCode>{` as a dependency`}</li>
      <li parentName="ul">{`789f671: Fix typo in translation key name.`}</li>
    </ul>
    <h2 {...{
      "id": "12180",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12180",
        "aria-label": "12180 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.18.0`}</h2>
    <h3 {...{
      "id": "minor-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes",
        "aria-label": "minor changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`d167d90: `}{`[UXI-1088]`}{` Migration to pragmatic-dnd`}</li>
      <li parentName="ul">{`abb108e: Introduce `}<inlineCode parentName="li">{`TextInput`}</inlineCode>{`component instead to replace `}<inlineCode parentName="li">{`Input`}</inlineCode>{`, planning to deprecate `}<inlineCode parentName="li">{`Input`}</inlineCode>{` component`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-1",
        "aria-label": "patch changes 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`cb84286: Fixed `}<inlineCode parentName="li">{`Spinner`}</inlineCode>{` to disable animations when `}<inlineCode parentName="li">{`loading`}</inlineCode>{` is false.`}</li>
      <li parentName="ul">{`c447ddc: Tinymce to 6.8.5`}</li>
    </ul>
    <h2 {...{
      "id": "12178",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12178",
        "aria-label": "12178 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.8`}</h2>
    <h3 {...{
      "id": "patch-changes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-2",
        "aria-label": "patch changes 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`53110d4: Deprecate `}<inlineCode parentName="li">{`compact`}</inlineCode>{` property, add `}<inlineCode parentName="li">{`isHorizontal`}</inlineCode>{` and `}<inlineCode parentName="li">{`size`}</inlineCode>{` properties to EmptyState component`}</li>
    </ul>
    <h2 {...{
      "id": "12177",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12177",
        "aria-label": "12177 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.7`}</h2>
    <h3 {...{
      "id": "patch-changes-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-3",
        "aria-label": "patch changes 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`5cadbc9: Add default icon to Banner componet depending on variant`}</li>
      <li parentName="ul">{`98780c8: Deprecate `}<inlineCode parentName="li">{`iconRight`}</inlineCode>{` property and `}<inlineCode parentName="li">{`tertiaryContrast`}</inlineCode>{` variant for Button. Decouple FilterToken component from Button`}</li>
    </ul>
    <h2 {...{
      "id": "12176",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12176",
        "aria-label": "12176 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.6`}</h2>
    <h3 {...{
      "id": "patch-changes-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-4",
        "aria-label": "patch changes 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`864ac9d: Update dependency package versions`}</li>
    </ul>
    <h2 {...{
      "id": "12175",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12175",
        "aria-label": "12175 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.5`}</h2>
    <h3 {...{
      "id": "patch-changes-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-5",
        "aria-label": "patch changes 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`b19e0a0f42: Fix `}<inlineCode parentName="li">{`isTierDisabled`}</inlineCode>{` in `}<inlineCode parentName="li">{`TieredSelect`}</inlineCode>{` component with selectable tiers`}</li>
      <li parentName="ul">{`c33b95d3b0: `}<inlineCode parentName="li">{`SegmentedController.Segment`}</inlineCode>{` `}<inlineCode parentName="li">{`selected`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`active`}</inlineCode>{`, deprecate `}<inlineCode parentName="li">{`active`}</inlineCode></li>
      <li parentName="ul">{`c861e39b23: Deprecate `}<inlineCode parentName="li">{`active`}</inlineCode>{` prop in `}<inlineCode parentName="li">{`Tabs`}</inlineCode>{` component, replaced by the `}<inlineCode parentName="li">{`selected`}</inlineCode>{` prop`}</li>
      <li parentName="ul">{`b6784bf2bc: `}<inlineCode parentName="li">{`Spinner`}</inlineCode>{` `}<inlineCode parentName="li">{`variant`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`color`}</inlineCode>{`, add new variants`}
        <ul parentName="li">
          <li parentName="ul">{`Hide label on small sizes (`}<inlineCode parentName="li">{`xs`}</inlineCode>{`, `}<inlineCode parentName="li">{`sm`}</inlineCode>{`).`}</li>
          <li parentName="ul">{`Deprecate `}<inlineCode parentName="li">{`color`}</inlineCode>{` property.`}</li>
          <li parentName="ul">{`Un-deprecate `}<inlineCode parentName="li">{`variant`}</inlineCode>{` property, add `}<inlineCode parentName="li">{`default`}</inlineCode>{`, `}<inlineCode parentName="li">{`button`}</inlineCode>{` and `}<inlineCode parentName="li">{`focus`}</inlineCode>{` variants`}</li>
        </ul>
      </li>
      <li parentName="ul">{`caacaa17b5: Deprecate `}<inlineCode parentName="li">{`traditional`}</inlineCode>{` `}<inlineCode parentName="li">{`variant`}</inlineCode>{` for `}<inlineCode parentName="li">{`Form`}</inlineCode>{` field layouts`}</li>
    </ul>
    <h2 {...{
      "id": "12174",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12174",
        "aria-label": "12174 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.4`}</h2>
    <h3 {...{
      "id": "patch-changes-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-6",
        "aria-label": "patch changes 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`3f23de248: Deprecate `}<inlineCode parentName="li">{`gray`}</inlineCode>{` variant from Link component`}</li>
      <li parentName="ul">{`cce0b1f41: Axios package bump for security`}</li>
    </ul>
    <h2 {...{
      "id": "12173",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12173",
        "aria-label": "12173 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.3`}</h2>
    <h3 {...{
      "id": "patch-changes-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-7",
        "aria-label": "patch changes 7 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`fe7e10cbe: Deprecate `}<inlineCode parentName="li">{`sm`}</inlineCode>{` size in Avatar component`}</li>
      <li parentName="ul">{`1d8188268: Tearsheet deprecate `}<inlineCode parentName="li">{`placement`}</inlineCode>{` and `}<inlineCode parentName="li">{`stretch`}</inlineCode>{`, add `}<inlineCode parentName="li">{`block`}</inlineCode>{` property as a drop-in replacement for `}<inlineCode parentName="li">{`stretch`}</inlineCode></li>
      <li parentName="ul">{`340e07485: Deprecate `}<inlineCode parentName="li">{`size`}</inlineCode>{` `}<inlineCode parentName="li">{`sm`}</inlineCode>{` in AvatarStack component`}</li>
      <li parentName="ul">{`72cca9636: Popover deprecate `}<inlineCode parentName="li">{`fluid`}</inlineCode>{` property, add `}<inlineCode parentName="li">{`block`}</inlineCode>{` property as a drop-in replacement`}</li>
    </ul>
    <h2 {...{
      "id": "12172",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12172",
        "aria-label": "12172 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.2`}</h2>
    <h3 {...{
      "id": "patch-changes-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-8",
        "aria-label": "patch changes 8 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`844a38816d: Updated the file upload error messages to display the file's relative path.`}</li>
      <li parentName="ul">{`144ef26610: Deprecate `}<inlineCode parentName="li">{`disabled`}</inlineCode>{` and `}<inlineCode parentName="li">{`error`}</inlineCode>{` props for the Search component`}</li>
      <li parentName="ul">{`d6f8cdd9cd: Fix some accessibility a11y warnings`}</li>
      <li parentName="ul">{`33607f479c: Update Dropzone error messaging.`}</li>
    </ul>
    <h2 {...{
      "id": "12171",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12171",
        "aria-label": "12171 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.1`}</h2>
    <h3 {...{
      "id": "patch-changes-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-9",
        "aria-label": "patch changes 9 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`59ce2207fa: Improve messaging for blocking zero byte uploads in Dropzone component.`}</li>
      <li parentName="ul">{`Updated dependencies `}{`[fd00589dce]`}</li>
      <li parentName="ul">{`Updated dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`@procore/`}<a parentName="li" {...{
              "href": "mailto:core-icons@12.3.0",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`core-icons@12.3.0`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "12170",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12170",
        "aria-label": "12170 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.17.0`}</h2>
    <h3 {...{
      "id": "minor-changes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-1",
        "aria-label": "minor changes 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`bbaaa149de: Removed text transform from the Spinner label`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-10",
        "aria-label": "patch changes 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`e6cff39e8e: Fix invalid html attributes for Typography and Tabs, use styled-components transient prefix $`}</li>
      <li parentName="ul">{`fe5abed75a: Added animationFrame: true to autoUpdate to prevent ResizeObserver bug in @floating-ui`}</li>
    </ul>
    <h2 {...{
      "id": "12162",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12162",
        "aria-label": "12162 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.16.2`}</h2>
    <h3 {...{
      "id": "patch-changes-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-11",
        "aria-label": "patch changes 11 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`221c980472: Removed unnecessary re-renders for TextEditor by memoize the focusScope for the useEffect to avoid over-triggering.`}</li>
      <li parentName="ul">{`758409d4d0: Add data-core-react attribute for tracking NextGen Layouts utilization`}</li>
    </ul>
    <h2 {...{
      "id": "12161",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12161",
        "aria-label": "12161 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.16.1`}</h2>
    <h3 {...{
      "id": "patch-changes-12",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-12",
        "aria-label": "patch changes 12 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`09eb576e78: Focus can leave a Modal or Tearsheet when the TinyMCE TextEditor has a plugin enabled that opens its own TinyMCE modal`}</li>
    </ul>
    <h2 {...{
      "id": "12160",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12160",
        "aria-label": "12160 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.16.0`}</h2>
    <h3 {...{
      "id": "minor-changes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-2",
        "aria-label": "minor changes 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`cb9ef0cf13: disable buttons during the closing transitions of the modal and tearsheets components.`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-13",
        "aria-label": "patch changes 13 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`5633033fec: Dropped core-css and scss in GroupBySelect`}</li>
      <li parentName="ul">{`d50705608f: `}{`[UXI-885]`}{` Updating colors for the animated progress bar`}</li>
    </ul>
    <h2 {...{
      "id": "12150",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12150",
        "aria-label": "12150 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.15.0`}</h2>
    <h3 {...{
      "id": "minor-changes-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-3",
        "aria-label": "minor changes 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`4e51fffd2: Add header prop to Select component`}</li>
    </ul>
    <h2 {...{
      "id": "12140",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12140",
        "aria-label": "12140 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.14.0`}</h2>
    <h3 {...{
      "id": "minor-changes-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-4",
        "aria-label": "minor changes 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`e660ed1ce: Adding option renderer props to the Form.Select and MultiSelect types so they can be used without error by the consumer`}</li>
      <li parentName="ul">{`85403fc79: Updating dependencies`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-14",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-14",
        "aria-label": "patch changes 14 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`081ac7922: fix React warning with Spinner and EmptyState for invalid attributes from styled-components`}</li>
      <li parentName="ul">{`d1e891702: EmptyState's description using `}<inlineCode parentName="li">{`body`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`h3`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "12130",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12130",
        "aria-label": "12130 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.13.0`}</h2>
    <h3 {...{
      "id": "minor-changes-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-5",
        "aria-label": "minor changes 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`23cdefdd1a: Updating ToggleButton styles, adding loading prop`}</li>
      <li parentName="ul">{`2718e17f50: Added size sm to Avatar and AvatarStack`}</li>
    </ul>
    <h2 {...{
      "id": "12121",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12121",
        "aria-label": "12121 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.12.1`}</h2>
    <h3 {...{
      "id": "patch-changes-15",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-15",
        "aria-label": "patch changes 15 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`d6e90a75c: Fix placeholder for de-DE in DateInput component`}</li>
      <li parentName="ul">{`aeac00464: Fix issue with resetting ungrouped/grouped columns (UISP-215)`}{`[UISP-215 https://procoretech.atlassian.net/browse/UISP-215]`}</li>
    </ul>
    <h2 {...{
      "id": "12120",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12120",
        "aria-label": "12120 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.12.0`}</h2>
    <h3 {...{
      "id": "minor-changes-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-6",
        "aria-label": "minor changes 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`ce4c89f36: Add onClickViewAll prop to AvatarStack`}</li>
      <li parentName="ul">{`b973bf06d: Thumbnail Placeholder variant file`}</li>
      <li parentName="ul">{`2a9c4441d: Improve accessibility to dropdownflyout component which is used in data-table component`}</li>
      <li parentName="ul">{`36ae9f382: update required label capitalization`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-16",
        "aria-label": "patch changes 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`ba1a46661: Panel Footer show shadow only while scrolling`}</li>
    </ul>
    <h2 {...{
      "id": "12111",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12111",
        "aria-label": "12111 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.11.1`}</h2>
    <h3 {...{
      "id": "patch-changes-17",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-17",
        "aria-label": "patch changes 17 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`14551f2c3: Fixed passing a deprecated locale and its translations to I18nProvider was resulting in showing no translations`}</li>
    </ul>
    <h2 {...{
      "id": "12110",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12110",
        "aria-label": "12110 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.11.0`}</h2>
    <h3 {...{
      "id": "minor-changes-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-7",
        "aria-label": "minor changes 7 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`b034c8b7e7: Introducing GhostPlaceholder`}</li>
      <li parentName="ul">{`977a03d2e3: Allow to change the strategy for restoring focus in OverlayTrigger. This fixes closing Select by Tab button.`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-18",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-18",
        "aria-label": "patch changes 18 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies `}{`[9e378e94cf]`}
        <ul parentName="li">
          <li parentName="ul">{`@procore/`}<a parentName="li" {...{
              "href": "mailto:core-icons@12.2.0",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`core-icons@12.2.0`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "12100",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12100",
        "aria-label": "12100 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.10.0`}</h2>
    <h3 {...{
      "id": "minor-changes-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-8",
        "aria-label": "minor changes 8 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`6df512a30: - Add Polish support`}
        <ul parentName="li">
          <li parentName="ul">{`Mark Locale type as deprecated`}</li>
          <li parentName="ul">{`Mark getI18nProviderLocale as deprecated`}</li>
        </ul>
      </li>
      <li parentName="ul">{`6c2153dea: Changes have been made in the translation for Page keyword in pagination to include the colon along with the translation.`}</li>
      <li parentName="ul">{`76a28038d: Panel Header Action improve screen reader and keyboard support with aria-label and focus trigger`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-19",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-19",
        "aria-label": "patch changes 19 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`ae429cddb: SplitViewCard no outer spacing on mobile`}</li>
      <li parentName="ul">{`Updated dependencies `}{`[8c613f543]`}
        <ul parentName="li">
          <li parentName="ul">{`@procore/`}<a parentName="li" {...{
              "href": "mailto:core-i18n-js@10.29.0",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`core-i18n-js@10.29.0`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "1290",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1290",
        "aria-label": "1290 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.9.0`}</h2>
    <h3 {...{
      "id": "minor-changes-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-9",
        "aria-label": "minor changes 9 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`f4bba2564d: Add ToolLandingPage layout and deprecate the ListPage layout`}</li>
      <li parentName="ul">{`2385ee3dc2: Add SplitViewCard component`}</li>
      <li parentName="ul">{`e72d0229e4: Add unsafe button variant for helix header`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-20",
        "aria-label": "patch changes 20 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`c82363b770: Detail Page Body and Detail Page Card reduce leading space between`}</li>
    </ul>
    <h2 {...{
      "id": "1281",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1281",
        "aria-label": "1281 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.8.1`}</h2>
    <h3 {...{
      "id": "patch-changes-21",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-21",
        "aria-label": "patch changes 21 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`f238516964: fix internal tab styles for helix header`}</li>
    </ul>
    <h2 {...{
      "id": "1280",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1280",
        "aria-label": "1280 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.8.0`}</h2>
    <h3 {...{
      "id": "minor-changes-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-10",
        "aria-label": "minor changes 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`77ab117fd4: Added `}<inlineCode parentName="li">{`StyledFormFieldMain`}</inlineCode>{` export related to Form`}</li>
      <li parentName="ul">{`b9bbcf6427: Added internal prop to Modal parts to enable mobile breakpoint responsiveness for Helix Header use. Not intended for widespread use. (`}<inlineCode parentName="li">{`compact`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`a7bb749cf8: Added internal prop for Tab styles to be used for global navigation. Not intended for widespread use. (`}<inlineCode parentName="li">{`UNSAFE_helixHeader`}</inlineCode>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1270",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1270",
        "aria-label": "1270 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.7.0`}</h2>
    <h3 {...{
      "id": "minor-changes-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-11",
        "aria-label": "minor changes 11 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`d62b76bd8: tinymce strikethrough text formatting`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-22",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-22",
        "aria-label": "patch changes 22 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`2ab36a650: Add max_height property to TextEditorOutput component`}</li>
      <li parentName="ul">{`55fed6084: Added `}<inlineCode parentName="li">{`SpanAttributes`}</inlineCode>{` type export to fix `}<inlineCode parentName="li">{`styled-components`}</inlineCode>{` type error.`}</li>
      <li parentName="ul">{`a5daf2815: Add export for Tier`}</li>
      <li parentName="ul">{`Updated dependencies `}{`[716e98717]`}
        <ul parentName="li">
          <li parentName="ul">{`@procore/`}<a parentName="li" {...{
              "href": "mailto:core-icons@12.1.0",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`core-icons@12.1.0`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "1260",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1260",
        "aria-label": "1260 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.6.0`}</h2>
    <h3 {...{
      "id": "minor-changes-12",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-12",
        "aria-label": "minor changes 12 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`77396f11c: Add TinyMCE advlist plugin to default plugins for more bullet and counter styles`}</li>
    </ul>
    <h2 {...{
      "id": "1250",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1250",
        "aria-label": "1250 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.5.0`}</h2>
    <h3 {...{
      "id": "minor-changes-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-13",
        "aria-label": "minor changes 13 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`898ad719e: Add support for multiple fallbacks on the i18nProvider`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-23",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-23",
        "aria-label": "patch changes 23 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`58d7677bf: Add export for DivAttributes`}</li>
    </ul>
    <h2 {...{
      "id": "1242",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1242",
        "aria-label": "1242 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.4.2`}</h2>
    <h3 {...{
      "id": "patch-changes-24",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-24",
        "aria-label": "patch changes 24 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`74dc723988: Extend MenuItemProps with AnchorItemProps`}</li>
    </ul>
    <h2 {...{
      "id": "1241",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1241",
        "aria-label": "1241 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.4.1`}</h2>
    <h3 {...{
      "id": "patch-changes-25",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-25",
        "aria-label": "patch changes 25 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`ecc17b836: Added missing exports that were excluded after removing dist imports`}</li>
    </ul>
    <h2 {...{
      "id": "1240",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1240",
        "aria-label": "1240 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.4.0`}</h2>
    <h3 {...{
      "id": "minor-changes-14",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-14",
        "aria-label": "minor changes 14 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`c3b8bc91c0: Add anchor navigation to DetailPage and SettingsPage layouts`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-26",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-26",
        "aria-label": "patch changes 26 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`b832463cbe: TextArea allows rows for larger than min-height default sizes (removes fixed height)`}</li>
      <li parentName="ul">{`6c63b2cf22: Added `}<inlineCode parentName="li">{`UNSAFE_EmptyStateSvg`}</inlineCode>{` export from `}<inlineCode parentName="li">{`EmptyState`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`6c63b2cf22: Added `}<inlineCode parentName="li">{`UNSAFE_ThumbnailDocumentIcon`}</inlineCode>{` export from `}<inlineCode parentName="li">{`Thumbnail`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`1ba4e0af1c: Add exports for celi-select package dependencies`}</li>
      <li parentName="ul">{`004a11a69c: Added `}<inlineCode parentName="li">{`UNSAFE_TreeChevron`}</inlineCode>{`, `}<inlineCode parentName="li">{`UNSAFE_TreeChevronContainer`}</inlineCode>{`, `}<inlineCode parentName="li">{`UNSAFE_TreeFilenameCaption`}</inlineCode>{`, `}<inlineCode parentName="li">{`UNSAFE_TreeIconContainer`}</inlineCode>{`, `}<inlineCode parentName="li">{`UNSAFE_TreeRowContainer`}</inlineCode>{`, and `}<inlineCode parentName="li">{`UNSAFE_TreeRowContent`}</inlineCode>{` export from the `}<inlineCode parentName="li">{`Tree`}</inlineCode>{` component.`}</li>
    </ul>
    <h2 {...{
      "id": "1230",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1230",
        "aria-label": "1230 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.3.0`}</h2>
    <h3 {...{
      "id": "minor-changes-15",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-15",
        "aria-label": "minor changes 15 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`9f36515057: Add SettingsPage layout`}</li>
      <li parentName="ul">{`d378956ad7: Adds exports for TinyMCE`}</li>
    </ul>
    <h2 {...{
      "id": "1220",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1220",
        "aria-label": "1220 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.2.0`}</h2>
    <h3 {...{
      "id": "minor-changes-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-16",
        "aria-label": "minor changes 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`952834a3c0: Add exports intended for json-formulator v12 support`}</li>
    </ul>
    <h3 {...{
      "id": "patch-changes-27",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-27",
        "aria-label": "patch changes 27 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`2614939306: Update label typography font weight from 700 to 600`}</li>
    </ul>
    <h2 {...{
      "id": "1210",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1210",
        "aria-label": "1210 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.1.0`}</h2>
    <h3 {...{
      "id": "minor-changes-17",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-17",
        "aria-label": "minor changes 17 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`d159f925da: Add missed exports of Dropzone for file-select library internal development`}</li>
    </ul>
    <h2 {...{
      "id": "1200-2024-05-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1200-2024-05-28",
        "aria-label": "1200 2024 05 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.0.0 (2024-05-28)`}</h2>
    <h3 {...{
      "id": "major-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#major-changes",
        "aria-label": "major changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Major Changes`}</h3>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://core.procore.com/latest/web/releases/migration-guides/core-react/v11-to-v12/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`v12 migration guide`}</a>{` for the full list of changes and migration guides.`}</p>
    <p>{`General changes:`}</p>
    <ul>
      <li parentName="ul">{`Imports from `}<inlineCode parentName="li">{`dist`}</inlineCode>{` folder do not work anymore.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@procore/globalization-toolkit`}</inlineCode>{` is moved from dependencies to required peerDependencies.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react-beautiful-dnd`}</inlineCode>{` is moved from dependencies to required peerDependencies.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`formik`}</inlineCode>{` peerDependency is now optional. Necessary when using `}<inlineCode parentName="li">{`Form`}</inlineCode>{` components and hooks.`}</li>
      <li parentName="ul">{`resize observer polyfill remove`}</li>
    </ul>
    <p>{`Other changes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`NumberInput`}</inlineCode>{` no longer uses the `}<inlineCode parentName="li">{`locale`}</inlineCode>{` as prop from the component.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Page`}</inlineCode>{` and `}<inlineCode parentName="li">{`DetailPage`}</inlineCode>{` subcomponent padding refactor.`}</li>
      <li parentName="ul">{`Removed deprecated components, hooks, and contexts:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SuccessBanner`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`Sidebar`}</inlineCode>{`, `}<inlineCode parentName="li">{`Sidebar.*`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`Analytics`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnalyticsContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnalyticsProvider`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`AnchorContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`AnchorProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useAnchorContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Autofocus`}</inlineCode>{`, `}<inlineCode parentName="li">{`useAutofocus`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`BoundingRectContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`useBoundingRectContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`useBoundingRect`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`BufferContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`useBufferContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Event`}</inlineCode>{`, `}<inlineCode parentName="li">{`EventProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useEvent`}</inlineCode>{`, `}<inlineCode parentName="li">{`useEventContext`}</inlineCode>{` etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`InjectedScriptContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`InjectedScriptProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useInjectedScriptContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ListNavigationContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`ListNavigationProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useListNavigationContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ResizeObserver`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`TextEntryContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`TextEntryProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useTextEntryContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`TimerContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`TimerProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useTimerContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`TriggerContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`TriggerProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useTriggerContext`}</inlineCode>{`, etc`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`VisibilityContext`}</inlineCode>{`, `}<inlineCode parentName="li">{`VisibilityProvider`}</inlineCode>{`, `}<inlineCode parentName="li">{`useVisibilityContext`}</inlineCode>{`, etc`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      