import { useFocusRing } from '@react-aria/focus'
import { useRadio, useRadioGroup } from '@react-aria/radio'
import { mergeProps, useId } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import React from 'react'
import type { RadioGroupState } from 'react-stately'
import { useRadioGroupState } from 'react-stately'
import { Tooltip as TooltipBase } from '../Tooltip/Tooltip'
import { addSubcomponents } from '../_utils/addSubcomponents'
import {
  StyledContainer,
  StyledLabel,
  StyledSegment,
  StyledSegmentDisabledInteractiveWrapper,
} from './SegmentedController.styles'
import type {
  SegmentedControllerProps,
  SegmentedControllerSegmentProps,
  SegmentedControllerTooltipProps,
} from './SegmentedController.types'

function isSegment(
  node: unknown
): node is React.ReactElement<HTMLInputElement> {
  return (
    React.isValidElement(node as React.ReactElement) &&
    (node as React.ReactElement).type === Segment
  )
}

const RadioButtonContext = React.createContext<RadioGroupState | null>(null)

const SegmentedController_ = React.forwardRef<
  HTMLDivElement,
  SegmentedControllerProps
>(function SegmentedController(
  {
    // TODO make label props required in type?
    // React Aria logs warnings without 1 of 2. Axe does not fail
    ['aria-label']: ariaLabel,
    ['aria-labelledby']: ariaLabelledby,
    children,
    block = false,
    disabled,
    onChange,
    ...props
  },
  ref
) {
  const name = useId()
  const radioGroupState = useRadioGroupState({
    name,
    isDisabled: disabled,
    onChange: (rawValue) => {
      const num = Number(rawValue)
      const value = !isNaN(num) ? num : rawValue
      onChange?.(value as number)
    },
  })
  const { radioGroupProps } = useRadioGroup(
    {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      orientation: 'horizontal',
    },
    radioGroupState
  )
  return (
    <StyledContainer ref={ref} block={block} {...radioGroupProps} {...props}>
      <RadioButtonContext.Provider value={radioGroupState}>
        {React.Children.toArray(children)
          .filter((child) => isSegment(child))
          .map((child, index) =>
            React.cloneElement(child as React.ReactElement, {
              internalIndex: index,
            })
          )}
      </RadioButtonContext.Provider>
    </StyledContainer>
  )
})

export const Segment = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & SegmentedControllerSegmentProps
>(function Segment(
  {
    active,
    children,
    placement = 'top',
    tooltip,
    disabled,
    value,
    internalIndex,
    selected,
    ...props
  },
  ref
) {
  const adjustedValue = String(value ?? internalIndex)
  const state = React.useContext(RadioButtonContext)
  const adjustedState = React.useMemo(() => {
    return state! ?? {}
  }, [state])

  const innerRef = React.useRef<HTMLInputElement>(null)
  const actualRef = ref ?? innerRef
  const { inputProps, isSelected, isDisabled } = useRadio(
    {
      isDisabled: disabled ?? adjustedState.isDisabled,
      value: adjustedValue as string,
      children,
    },
    adjustedState,
    actualRef as any // TODO fix polymorphic ref type
  )
  const { focusProps, isFocusVisible } = useFocusRing()

  React.useEffect(() => {
    if (active || selected) {
      adjustedState.setSelectedValue(adjustedValue)
    }
  }, [active, adjustedState, adjustedValue, selected])

  return (
    <Tooltip overlay={tooltip} placement={placement}>
      <StyledSegmentDisabledInteractiveWrapper>
        <StyledSegment
          $focused={isFocusVisible}
          $disabled={isDisabled}
          $selected={active || selected || isSelected}
        >
          <VisuallyHidden>
            <input
              {...mergeProps(props, inputProps, focusProps)}
              ref={actualRef}
            />
          </VisuallyHidden>
          <StyledLabel>{children}</StyledLabel>
        </StyledSegment>
      </StyledSegmentDisabledInteractiveWrapper>
    </Tooltip>
  )
})

/**
 * @deprecated
 *
 * The `SegmentedController.Tooltip` component has been deprecated will be
 * removed in a future version.
 *
 * Tooltips are now built into `SegmentedController.Segment`s, and can be added
 * by configuring the `tooltip` prop.
 *
 * **Before**
 *
 * ```
 * <SegmentedController>
 *   <SegmentedController.Tooltip tooltip="Item 1 is the first item">
 *     <SegmentedController.Segment>
 *       Item 1
 *     </SegmentedController.Segment>
 *   </SegmentedController.Tooltip>
 * </SegmentedController>
 * ```
 *
 * **After**
 *
 * ```
 * <SegmentedController>
 *   <SegmentedController.Segment tooltip="Item 1 is the first item">
 *     Item 1
 *   </SegmentedController.Segment>
 * </SegmentedController>
 * ```
 *
 * @deprecatedSince 10.20.0
 */
export const Tooltip = React.forwardRef<
  HTMLElement,
  SegmentedControllerTooltipProps
>(function Tooltip({ children, overlay, ...props }, ref) {
  return overlay ? (
    <TooltipBase {...props} overlay={overlay} ref={ref} trigger="hover">
      {children}
    </TooltipBase>
  ) : (
    <>{children}</>
  )
})

const withState = (config: any) => (Component: any) => (props: any) => {
  const [selectedIndex, setSelected] = React.useState(0)

  return <Component {...{ ...props, selectedIndex, setSelected }} />
}

/**
 * @deprecated
 *
 * This `SegmentedController.State` utility has been deprecated. Please just
 * use a simple state management hook instead.
 *
 *
 * **Before**
 *
 * ```
 * <SegmentedController.State>
 *   {({ selectedIndex, setSelected }) => (
 *     <SegmentedController>
 *       <SegmentedController.Segment onClick={() => setSelected(0)} active={selectedIndex === 0}>
 *         Item 1
 *       </SegmentedController.Segment>
 *     </SegmentedController>
 *   )}
 * </SegmentedController.State>
 * ```
 *
 * **After**
 *
 * ```
 * const [selected, setSelected] = React.useState(0)
 *
 * <SegmentedController onChange={setSelected}>
 *   <SegmentedController.Segment active={selectedIndex === 0}>
 *     Item 1
 *   </SegmentedController.Segment>
 * </SegmentedController>
 * ```
 *
 * @deprecatedSince 10.20.0
 */
export const State = withState({})(({ children, ...props }: any) =>
  children(props)
)

SegmentedController_.displayName = 'SegmentedController'

Segment.displayName = 'SegmentedController.Segment'

Tooltip.displayName = 'SegmentedController.Tooltip'

/**

 Segmented controllers allow users to switch between related but mutually
 exclusive content or actions.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-segmentedcontroller--demo)

 @see [Design Guidelines](https://design.procore.com/segmented-controller)

 */
export const SegmentedController = addSubcomponents(
  {
    Segment,
    State,
    Tooltip,
  },
  SegmentedController_
)
