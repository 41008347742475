import React from 'react'
import { clamp, maxProgressValue, minProgressValue } from '../Loader/Loader'
import {
  StyledProgressBar,
  StyledProgressBarLoader,
} from './ProgressBar.styles'
import type { ProgressBarProps } from './ProgressBar.types'

const finalAnimationDuration = 900

/**

 Progress bars visually show system loading progress, such as uploading a file
 or loading a page, as well as user progress, like filling out a form.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-progressbar--demo)

 @see [Design Guidelines](https://design.procore.com/progress-bar)

 @a11y Please use an `aria-label`. The `aria-label` value should not include the term "Progress Bar" as a role already identifies it as such

 */
export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  function ProgressBar({ animated = false, value, ...props }, ref) {
    const isProgressValueComplete = value >= maxProgressValue
    const [isFinalized, setFinalized] = React.useState(false)
    const [isProgressComplete, setProgressComplete] = React.useState(
      isProgressValueComplete
    )
    const didMountRef = React.useRef(false)

    React.useEffect(() => {
      const isFinalized = didMountRef.current && isProgressValueComplete
      const timerId: number | null = isFinalized
        ? window.setTimeout(() => {
            setFinalized(false)
          }, finalAnimationDuration)
        : null

      setProgressComplete(isProgressValueComplete)
      setFinalized(isFinalized)

      if (!didMountRef.current) {
        didMountRef.current = true
      }

      return () => {
        timerId && clearTimeout(timerId)
      }
    }, [isProgressValueComplete])

    const clampedValue = clamp(value)

    return (
      <StyledProgressBar
        $complete={isProgressComplete}
        $finalized={isFinalized}
        ref={ref}
        role="progressbar"
        aria-valuenow={clampedValue}
        aria-valuemin={minProgressValue}
        aria-valuemax={maxProgressValue}
        {...props}
        aria-valuetext={
          typeof props['aria-valuetext'] === 'function'
            ? props['aria-valuetext']({ value: clampedValue })
            : props['aria-valuetext']
        }
      >
        <StyledProgressBarLoader value={value} animated={animated} />
      </StyledProgressBar>
    )
  }
)

ProgressBar.displayName = 'ProgressBar'
