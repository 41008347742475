import styled, { css, keyframes } from 'styled-components'
import { getTypographyIntent } from '../Typography'
import type { Color } from '../_styles/colors'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'
import type { SpinnerSize, SpinnerVariant } from './Spinner.types'

const duration = 1500

const durationOffset = 500

const transition = '0.5s ease'

const zContainer = 3

const zOverlay = 2

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

type SyncAnimationProps = {
  $animate: boolean
  $mountTime: number
}
export const StyledCircle = styled.circle<SyncAnimationProps>`
  ${(p) => {
    if (p.$animate) {
      const animationDelay = -(p.$mountTime % duration)
      return css`
        animation: ${dash} ${duration}ms ease-in-out ${animationDelay}ms
          infinite;
      `
    }
  }}
  stroke-linecap: round;
`
export const spinnerDimensions: Record<SpinnerSize, number> = {
  xs: spacing.md,
  sm: spacing.lg,
  md: spacing.xl,
  lg: 48,
}

const spinnerColorMap: Record<SpinnerVariant, Color> = {
  default: 'gray60',
  button: 'gray15',
  light: 'white',
  focus: 'blue50',
}

const spinnerStroke: Record<SpinnerSize, number> = {
  xs: 10,
  sm: 8,
  md: 8,
  lg: 6,
}

function getHeightWidthStroke({ $size }: { $size: SpinnerSize }) {
  const dimension = spinnerDimensions[$size]
  const strokeWidth = spinnerStroke[$size]

  return css`
    height: ${dimension}px;
    width: ${dimension}px;
    stroke-width: ${strokeWidth}px;
  `
}

function getStrokeColor({
  $color,
  $variant,
}: {
  $color?: Color
  $variant: SpinnerVariant
}) {
  if ($color) {
    return css`
      stroke: ${colors[$color]};
    `
  }

  return css`
    stroke: ${colors[spinnerColorMap[$variant]]};
  `
}

interface StyledSpinnerContainerProps {
  $color?: Color
  $size: SpinnerSize
  $variant: SpinnerVariant
}

export const StyledSpinnerContainer = styled.div<StyledSpinnerContainerProps>`
  border-radius: 100%;
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  position: relative;

  ${getHeightWidthStroke}

  ${StyledCircle} {
    ${getStrokeColor}
  }
`

export const StyledSpinnerOverlayLabel = styled.span`
  ${getTypographyIntent('body')}
  color: ${colors.gray15};

  display: block;
  min-height: 20px;
  padding-top: ${spacing.md}px;
  text-align: center;
  transition: opacity ${transition};
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const StyledSpinnerSVG = styled.svg<SyncAnimationProps>`
  ${(p) => {
    if (p.$animate) {
      const animationDelay = -(p.$mountTime % (duration - durationOffset))
      return css`
        animation: ${rotate} ${duration - durationOffset}ms linear
          ${animationDelay}ms infinite;
      `
    }
  }}
  fill: none;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
`

const startsWithNoInteraction = css`
  opacity: 0;
  pointer-events: none;
  transition: opacity ${transition};
`

export const StyledSpinnerOverlay = styled.div`
  ${startsWithNoInteraction};
  background-color: ${colors.white};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${zOverlay};
`

export const StyledSpinnerContainerWithLabel = styled.div`
  ${startsWithNoInteraction};
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 400px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${zContainer};
`

export const StyledSpinnerOverlayContainer = styled.div<{
  $loading: boolean
  $centered: boolean
}>`
  min-height: 150px;
  position: relative;

  ${({ $loading }) => {
    if ($loading) {
      return css`
        ${StyledSpinnerContainerWithLabel}, ${StyledSpinnerOverlay} {
          opacity: 1;
          pointer-events: auto;
        }
      `
    }
  }}

  ${({ $centered }) => {
    if ($centered) {
      return css`
        ${StyledSpinnerContainerWithLabel} {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      `
    }
  }}
`
