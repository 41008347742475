import { CaretDown, CaretUp } from '@procore/core-icons/dist'
import React from 'react'

import { useButton } from '@react-aria/button'
import { MenuImperative } from '../MenuImperative/MenuImperative'
import type { MenuRef, Selection } from '../MenuImperative/MenuImperative.types'
import {
  OverlayTrigger,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { useI18nContext } from '../_hooks/I18n'
import { useResize } from '../_hooks/Resize'
import { spacing } from '../_styles/spacing'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { getBoundingRect } from '../_utils/dom'
import { mergeRefs } from '../_utils/mergeRefs'
import type {
  DivAttributes,
  NavAttributes,
  Props,
  SpanAttributes,
} from '../_utils/types'
import {
  getStyledComponents,
  StyledOverlay,
  StyledTabList,
  StyledTabs,
} from './Tabs.styles'
import type {
  BaseTabProps,
  TabDropdownProps,
  TabProps,
  TabRef,
  TabRole,
  TabRoleType,
  TabsProps,
} from './Tabs.types'

const ExtraTabSpacing = spacing.xxl
const HelixHeaderExtraTabSpacing = spacing.sm

function noop() {}

export const Link = React.forwardRef<
  HTMLSpanElement,
  SpanAttributes &
    Props & {
      /** @deprecated @experimental Alternative style variant. To be used on Helix Header ONLY */
      UNSAFE_helixHeader?: boolean
    }
>(function Link({ children, UNSAFE_helixHeader = false, ...props }, ref) {
  const Styled = getStyledComponents(UNSAFE_helixHeader)

  return (
    <Styled.Link ref={ref} {...props}>
      {children}
    </Styled.Link>
  )
})

function TabInternal(
  {
    as,
    disabled,
    role,
    active = false,
    selected = false,
    children,
    onBlur,
    onFocus,
    onMount = noop,
    onUnmount = noop,
    overflowing = false,
    dropdown = false,
    variant = '',
    UNSAFE_helixHeader = false,
    ...props
  }: TabProps<TabRoleType>,
  ref: TabRef<TabRoleType>
) {
  const { onPress } = props as TabProps<'button'>

  const { buttonProps } = useButton(
    {
      ...props,
      onBlur: onBlur as (e: React.FocusEvent) => void,
      onFocus: onFocus as (e: React.FocusEvent) => void,
      elementType: role === 'link' ? 'a' : role === 'button' ? 'button' : 'div',
      isDisabled: disabled,
      onPress,
      type: 'button',
    },
    ref as any // TODO fix polymorphic type
  )

  const a11yProps =
    role === 'button'
      ? {
          ...buttonProps,
          'aria-current': selected || active,
          as: as || 'button',
          $clickable: !disabled,
        }
      : role === 'link'
      ? {
          ...buttonProps,
          'aria-current': selected || active,
          as: as || 'a',
          $clickable: !disabled,
        }
      : role === 'tab'
      ? { role: 'tab', onBlur, onFocus }
      : { onBlur, onFocus }

  const innerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    onMount(innerRef.current)
    return onUnmount
  }, [])

  const validChild = React.isValidElement(children)
  const tabSelected = active || variant === 'active' || selected

  const Styled = getStyledComponents(UNSAFE_helixHeader)

  return (
    <Styled.Tab
      $selected={tabSelected}
      $dark={props.dark}
      // @ts-ignore
      ref={mergeRefs(ref, innerRef)}
      {...a11yProps}
      {...props}
    >
      <Styled.TabInner $selected={tabSelected} $dark={props.dark}>
        {validChild
          ? React.cloneElement(children as React.ReactElement<any, any>, {
              'aria-current': tabSelected ? true : undefined,
            })
          : children}
      </Styled.TabInner>
    </Styled.Tab>
  )
}

export const Tab = React.forwardRef(TabInternal) as <Role extends TabRole>(
  props:
    | TabProps<Role>
    | (BaseTabProps & { ref?: React.ComponentPropsWithRef<'div'>['ref'] })
) => ReturnType<typeof TabInternal>

export const MoreMenu = React.forwardRef<
  HTMLDivElement,
  DivAttributes & TabDropdownProps
>(function MoreMenu({ children, id }, ref) {
  const ctx = useOverlayTriggerContext()

  const menuRef = React.useRef<MenuRef | null>(null)

  React.useEffect(function () {
    menuRef.current?.el?.focus()
    menuRef.current?.highlightFirst()
    menuRef.current?.highlightSelected()
  }, [])

  function onSelect(selection: Selection) {
    ctx.hide(selection.event)
  }

  return (
    <StyledOverlay ref={ref}>
      <MenuImperative id={id} role="menu" ref={menuRef} onSelect={onSelect}>
        <MenuImperative.Options>
          {React.Children.map(children, (child, i) => {
            if (React.isValidElement<BaseTabProps>(child)) {
              return (
                <MenuImperative.Item
                  item={null}
                  key={i}
                  selected={child.props.selected || child.props.active}
                >
                  {React.cloneElement(child as any, { ref: null })}
                </MenuImperative.Item>
              )
            }
          })}
        </MenuImperative.Options>
      </MenuImperative>
    </StyledOverlay>
  )
})

const Tabs_ = React.forwardRef<HTMLDivElement, NavAttributes & TabsProps>(
  function Tabs(
    {
      children,
      className,
      dark = false,
      qa,
      UNSAFE_helixHeader = false,
      ...props
    },
    ref
  ) {
    const i18n = useI18nContext()

    const [moreMenuIsShown, setMoreMenuIsShown] = React.useState(false)
    const [tabElements, setTabElements] = React.useState<
      (HTMLDivElement | null)[]
    >([])
    const [cutoffIndex, setCutoffIndex] = React.useState(0)

    const containerRef = React.useRef<HTMLDivElement>(null)

    function recalculate() {
      const container = getBoundingRect(containerRef.current!)

      const { subtotals } = tabElements.reduce(
        (acc, el) => {
          if (!el) {
            return acc
          }
          const rect = getBoundingRect(el)
          const extraSpace = UNSAFE_helixHeader
            ? HelixHeaderExtraTabSpacing
            : ExtraTabSpacing
          return {
            subtotals: [...acc.subtotals, acc.total + rect.width],
            total: acc.total + rect.width + extraSpace,
          }
        },
        { subtotals: new Array(), total: 0 }
      )

      const index = subtotals.findIndex((val: number) => val >= container.width)

      setCutoffIndex(index)
    }

    useResize({ onResize: recalculate })

    React.useEffect(recalculate)

    const filtered = React.Children.toArray(children).filter((child) => child)

    const overflowing = filtered.slice(cutoffIndex - 1)

    const isOverflowingSelected = React.Children.toArray(overflowing).some(
      (child) =>
        React.isValidElement(child) &&
        (child.props.active || child.props.selected)
    )

    const Styled = getStyledComponents(UNSAFE_helixHeader)

    return (
      <StyledTabs ref={ref} $dark={dark} index={cutoffIndex} {...props}>
        <StyledTabList ref={containerRef}>
          {filtered.map((child, i) => {
            if (React.isValidElement(child))
              return React.cloneElement(child, {
                dark,
                onMount: (el: HTMLDivElement) => {
                  setTabElements((elements) => {
                    const oldElements = [...elements]
                    oldElements[i] = el
                    return oldElements
                  })
                },
                onUnmount: () => {
                  setTabElements((elements) => {
                    const oldElements = [...elements]
                    oldElements[i] = null
                    return oldElements
                  })
                },
              } as any) // TODO fix type
          })}

          <OverlayTrigger
            autoFocus
            beforeShow={() => setMoreMenuIsShown(true)}
            afterHide={() => setMoreMenuIsShown(false)}
            trigger="click"
            passA11yPropsToOverlay
            placement="bottom-right"
            overlay={<MoreMenu>{overflowing}</MoreMenu>}
            ref={ref}
            role="menu"
          >
            <Styled.DropdownTab
              $selected={isOverflowingSelected}
              $dark={dark}
              aria-hidden={!overflowing.length}
              as="button"
            >
              <Styled.DropdownTabInner
                $selected={isOverflowingSelected}
                $dark={dark}
              >
                <Styled.Link data-qa={qa?.more}>
                  {i18n.t('core.tabs.more')}
                </Styled.Link>
                {moreMenuIsShown ? (
                  <CaretUp size="sm" />
                ) : (
                  <CaretDown size="sm" />
                )}
              </Styled.DropdownTabInner>
            </Styled.DropdownTab>
          </OverlayTrigger>
        </StyledTabList>
      </StyledTabs>
    )
  }
)

Tabs_.displayName = 'Tabs'

Link.displayName = 'Tabs.Link'

// @ts-ignore
Tab.displayName = 'Tabs.Tab'

/**

 We use tabs to navigate between different, but related content.
 Tabs are commonly used on tool landing pages and on item detail pages.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-tabs--demo)

 @see [Design Guidelines](https://design.procore.com/tabs)

 */
export const Tabs = addSubcomponents(
  {
    Link,
    Tab,
  },
  Tabs_
)
