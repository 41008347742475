import type { FocusScopeProps } from '@react-aria/focus'
import { FocusScope } from '@react-aria/focus'
import React, { useMemo } from 'react'

/**
 * STOP - READ BEFORE USING
 *
 * The following wrapper was added to help TinyMCE modals.
 * When used with React Aria contained Focus Scope,
 * focus does not move into the TinyMCE modal.
 * This patch gets critical functionality back at
 * the cost of possibly failing accessbility.
 * This hack keeps bugfixes for TinyMCE internal.
 * If a TextEditor isn't really a valid child,
 * use the plain FocusScope from React Aria.
 */

type OverrideProps = Omit<FocusScopeProps, 'children'>

const FocusScopeOverrideContext = React.createContext<{
  setProps: (arg: OverrideProps) => void
}>({
  setProps: () => {},
})

export function useOverridableFocusScope() {
  return React.useContext(FocusScopeOverrideContext)
}

export function OverridableFocusScope(props: FocusScopeProps) {
  const [overrideProps, setProps] = React.useState<OverrideProps>()

  const value = useMemo(() => ({ setProps }), [setProps])
  return (
    <FocusScopeOverrideContext.Provider value={value}>
      <FocusScope {...props} {...overrideProps} />
    </FocusScopeOverrideContext.Provider>
  )
}
