import styled, { css, keyframes } from 'styled-components'
import { StyledAnchorNavigation } from '../AnchorNavigation/AnchorNavigation.styles'
import { minScrimSize } from '../Tearsheet/Tearsheet.styles'
import type { Placement } from '../Tearsheet/Tearsheet.types'
import { colors } from '../_styles/colors'
import { getShadow } from '../_styles/shadows'
import { spacing } from '../_styles/spacing'
import { isIE11 } from '../_utils/isIE11'
import { mediaBreakpointsDown } from './PageLayout.utils'

const headerVerticalSpacing = spacing.sm
const outerPageSpacing = spacing.lg
const outerPageSpacingSmaller = spacing.md

export const StyledPageHeader = styled.div<{ $transparent?: boolean }>`
  padding-top: ${headerVerticalSpacing}px;
  padding-right: ${outerPageSpacing}px;
  padding-left: ${outerPageSpacing}px;

  @media ${mediaBreakpointsDown.tabletMd} {
    padding-right: ${outerPageSpacingSmaller}px;
    padding-left: ${outerPageSpacingSmaller}px;
  }

  ${({ $transparent }) =>
    !$transparent &&
    css`
      ${getShadow(1.5)}
      background-color: ${colors.white};
    `}
`

const StyledPageHeaderItem = styled.div`
  margin-top: ${headerVerticalSpacing}px;

  &:first-child {
    margin-top: 0;
  }
`

export const StyledPageTitle = styled(StyledPageHeaderItem)`
  &:last-child {
    padding-bottom: ${headerVerticalSpacing}px;
  }
`

export const StyledPageBreadcrumbs = styled(StyledPageHeaderItem).attrs({
  as: 'nav',
})``

export const StyledPageBanner = styled(StyledPageHeaderItem)``

export const getActions = () => css`
  flex-shrink: 0;
  padding-left: ${spacing.xl}px;

  > button,
  > a {
    &:first-child {
      margin-left: 0;
    }

    margin-left: ${spacing.sm}px;
  }
`

export const StyledPageActions = styled.div`
  ${getActions()}
`

export const StyledPageTabs = styled(StyledPageHeaderItem)``

export const StyledBody = styled.div`
  padding-top: ${outerPageSpacing}px;
  padding-left: ${outerPageSpacing}px;
  padding-right: ${outerPageSpacing}px;

  @media ${mediaBreakpointsDown.tabletMd} {
    padding-top: ${outerPageSpacingSmaller}px;
    padding-left: ${outerPageSpacingSmaller}px;
    padding-right: ${outerPageSpacingSmaller}px;
  }
`

const navigationWidth = 200

export const StyledNavigation = styled.div`
  float: left;
  max-height: 100vh;
  min-width: ${navigationWidth}px;
  overflow-y: auto;
  position: sticky;
  top: 0;
  width: ${navigationWidth}px;

  ${StyledAnchorNavigation} {
    padding: 20px ${spacing.sm}px 0 0;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const StyledContent = styled.div``

const panelWidth = 400
const zIndexes = {
  panelWideScreen: 3,
  panelNarrowScreen: 1,
  footer: 2,
}

export const animationDuration = {
  panel: 600,
}

export const StyledFooter = styled.div<{
  $isAsideOpen: boolean
  $hasShadow: boolean
}>`
  background-color: ${colors.white};

  ${({ $hasShadow }) => $hasShadow && getShadow(2, 'top')}

  position: sticky;
  bottom: 0;
  z-index: ${zIndexes.footer};

  ${({ $isAsideOpen }) =>
    $isAsideOpen &&
    css`
      @media ${mediaBreakpointsDown.tabletMd} {
        z-index: ${zIndexes.footer};
      }
    `}
`

export const StyledAside = styled.div<{ $open: boolean; $closed: boolean }>`
  ${({ $closed, $open }) =>
    isIE11() &&
    css`
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 0;

      transition: flex-basis ${animationDuration.panel}ms;

      ${$closed &&
      css`
        flex-basis: 0;
      `}

      ${$open &&
      css`
        flex-basis: ${panelWidth}px;
      `}
    `}

  // remove aside block from the main flow on smaller screens, enabling overlay-like positioning
  @media ${mediaBreakpointsDown.tabletMd} {
    position: absolute;
    right: 0;
  }
`

const slideDistance = '100%'

const slideIn = keyframes`
  from {
    transform: translateX(${slideDistance});
  }
  to {
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(${slideDistance});
  }
`

const getPanelAnimation = () => css<{ $opening: boolean; $closing: boolean }>`
  ${({ $opening }) =>
    $opening &&
    css`
      animation: ${slideIn} ease-out ${animationDuration.panel}ms;
    `}

  ${({ $closing }) =>
    $closing &&
    css`
      animation: ${slideOut} ease-out ${animationDuration.panel}ms;
    `}
`

export const StyledAsidePanel = styled.div<{
  $offsetTop: number | null
  $minusHeight: number
  $closing: boolean
  $closed: boolean
  $opening: boolean
  $rightOffset: number
  $altAnimation: boolean
}>`
  // position panel behind the page sticky footer on smaller screens
  ${({ $rightOffset }) => css`
    @media ${mediaBreakpointsDown.tabletMd} {
      ${getShadow(2)}
      border-left: 0;
      z-index: ${zIndexes.panelNarrowScreen};
      right: ${$rightOffset}px;
    }
  `}

  /*
   * On smaller screens, the panel is positioned absolutely,
   *  so the animation needs to happen here
  */
  @media ${mediaBreakpointsDown.tabletMd} {
    ${getPanelAnimation()}
  }

  ${({ $altAnimation }) => $altAnimation && getPanelAnimation()}

  ${({ $closed }) =>
    $closed &&
    css`
      display: none;
    `}

  border-left: 1px solid ${colors.gray85};
  width: ${panelWidth}px;
  position: fixed;
  background-color: ${colors.gray96};

  overflow: auto;
  z-index: ${zIndexes.panelWideScreen};

  ${({ $minusHeight }) => {
    return css`
      height: calc(100dvh - ${$minusHeight}px);
    `
  }}

  ${({ $offsetTop }) =>
    $offsetTop !== null &&
    css`
      top: ${$offsetTop}px;
    `}
`

export const StyledPageMain = styled.div<{
  $tearsheetPlacement: Placement | null
}>`
  position: relative;
  max-width: 100%;
  background-color: ${colors.gray96};

  ${() =>
    isIE11() &&
    css`
      flex: 1 0 0%;
    `}

  ${({ $tearsheetPlacement }) => {
    if (!$tearsheetPlacement) {
      return ''
    }

    if (['top', 'bottom'].includes($tearsheetPlacement)) {
      return css`
        min-height: calc(100vh - ${minScrimSize}px);
      `
    }

    return css`
      min-height: 100vh;
    `
  }}
`

export const StyledPageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${() =>
    isIE11() &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
`

export const StyledAsideFluidContainer = styled.div<{
  $open: boolean
  $closed: boolean
}>`
  ${({ $closed, $open }) =>
    !isIE11() &&
    css`
      transition: width ${animationDuration.panel}ms;

      ${$closed &&
      css`
        width: 0;
      `}

      ${$open &&
      css`
        width: ${panelWidth}px;
      `}
    `}
`
