import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "deprecation-cycle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecation-cycle",
        "aria-label": "deprecation cycle permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecation Cycle`}</h2>
    <p>{`Packages in the Core repository adhere to a one full major version deprecation cycle.`}</p>
    <p>{`For example, if something is deprecated in version `}<inlineCode parentName="p">{`10.27.0`}</inlineCode>{`, then it should not be removed until version `}<inlineCode parentName="p">{`12.0.0`}</inlineCode>{`.`}</p>
    <p>{`However, if someone was deprecated `}<em parentName="p">{`with`}</em>{` a major version release, that thing can be safely removed in the next major version release.`}</p>
    <p>{`For example, deprecating an item in the `}<inlineCode parentName="p">{`10.0.0`}</inlineCode>{` release, it can be safely removed when `}<inlineCode parentName="p">{`11.0.0`}</inlineCode>{` is released.`}</p>
    <h2 {...{
      "id": "from-deprecation-notice-to-the-migration-guide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-deprecation-notice-to-the-migration-guide",
        "aria-label": "from deprecation notice to the migration guide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From Deprecation Notice to the Migration Guide`}</h2>
    <p>{`Whenever an item in the library is deprecated, the deprecation notes will include information on how to transition away from the item being deprecated. Eventually these notes become the migration guide when the deprecation becomes a decommission in a major version release.`}</p>
    <p>{`The information is sourced from the JSDoc `}<inlineCode parentName="p">{`@deprecated`}</inlineCode>{` and `}<inlineCode parentName="p">{`@deprecatedSince`}</inlineCode>{` tags. The deprecation text should include some basic description of why the change and a `}<inlineCode parentName="p">{`BEFORE`}</inlineCode>{` and `}<inlineCode parentName="p">{`AFTER`}</inlineCode>{` code sample block.`}</p>
    <p>{`For situations where we don't have something to migrate to, we should be very careful to validate with the UI Foundations team that deprecating is the right strategy.`}</p>
    <h2 {...{
      "id": "dual-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dual-support",
        "aria-label": "dual support permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dual support`}</h2>
    <p>{`There are times when a component needs to be drastically changed, but we don't want to introduce breaking changes just yet. An example of this has been `}<inlineCode parentName="p">{`DetailPage`}</inlineCode>{` in the library.`}</p>
    <p>{`For situations like this, we recommend prefixing the "new" component that is being built with the `}<inlineCode parentName="p">{`Next`}</inlineCode>{` prefix, e.g. `}<inlineCode parentName="p">{`NextDetailPage`}</inlineCode>{`. This allows continued development of similar new components while avoiding the necessity to do a breaking version change in the library.`}</p>
    <p>{`The old component that is being superseded should be marked as `}<inlineCode parentName="p">{`@deprecated`}</inlineCode>{`, with a migration guide to use the new, `}<inlineCode parentName="p">{`NextXYZ`}</inlineCode>{` component.`}</p>
    <p>{`During the next major version release we would delete the deprecated component and drop `}<inlineCode parentName="p">{`Next`}</inlineCode>{` from the new component.`}</p>
    <h2 {...{
      "id": "documenting-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#documenting-deprecations",
        "aria-label": "documenting deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Documenting Deprecations`}</h2>
    <h3 {...{
      "id": "annotating-components-for-deprecation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#annotating-components-for-deprecation",
        "aria-label": "annotating components for deprecation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Annotating components for deprecation`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`@deprecated`}</inlineCode>{` tag includes the message that goes along with the deprecation, along with a simple `}<inlineCode parentName="p">{`BEFORE`}</inlineCode>{` and `}<inlineCode parentName="p">{`AFTER`}</inlineCode>{` example code block.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`@deprecatedSince`}</inlineCode>{` tag should list the current version of the library when the deprecation occurs. This will allow us to more easily adhere to the two version deprecation lifecycle.`}</p>
    <h3 {...{
      "id": "deprecating-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#deprecating-components",
        "aria-label": "deprecating components permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecating components`}</h3>
    <p>{`To deprecate a component, the `}<strong parentName="p">{`component export`}</strong>{` should be annotated with a JSDoc comment including the `}<inlineCode parentName="p">{`@deprecated`}</inlineCode>{` and `}<inlineCode parentName="p">{`@deprecatedSince`}</inlineCode>{` tags.`}</p>
    <p>{`e.g. if we are deprecating the `}<inlineCode parentName="p">{`SuccessBanner`}</inlineCode>{` component, it would look like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/**
 * @deprecated Short note about why the change, please use XYZ instead.
 *
 * BEFORE
 * \`<SuccessBanner />\`
 *
 * AFTER
 * \`<XYZ />\`
 *
 * @deprecatedSince 10.27.0
 *
*/
const SuccessBanner = () => {

}
`}</code></pre>
    <h4 {...{
      "id": "deprecating-props",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#deprecating-props",
        "aria-label": "deprecating props permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecating props`}</h4>
    <p>{`To deprecate a prop on a component, the types should be updated to be annotated with a JSDOC comment included the `}<inlineCode parentName="p">{`@deprecated`}</inlineCode>{` tag and the `}<inlineCode parentName="p">{`@deprecatedSince`}</inlineCode>{` tags.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface Example {
  data: Promise
  /**
   * @deprecated Having a non-async prop did not allow good loading experiences. AFTER Use data prop with a promise
   * @deprecatedSince 123
   */
  value: any
}
`}</code></pre>
    <h4 {...{
      "id": "deprecating-parts-of-union-types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#deprecating-parts-of-union-types",
        "aria-label": "deprecating parts of union types permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecating parts of union types`}</h4>
    <p>{`It is not currently possible to deprecate individual parts of a union type using inline JSDoc comments.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type Size = 
  | /** @deprecated Use 'md' or 'lg' instead */ 'sm'
  | 'md'
  | 'lg';
`}</code></pre>
    <p>{`In this case, while the comment is attached to 'sm', it will not enforce any deprecation  warnings or alter behavior for string literals. The only viable approach is to include a general deprecation note on the entire union type rather than on individual values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`/** @deprecated Use 'md' or 'lg' instead of 'sm' */
type Size = 'sm' | 'md' | 'lg'
`}</code></pre>
    <h3 {...{
      "id": "deprecation-steps-specific-to-libraries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#deprecation-steps-specific-to-libraries",
        "aria-label": "deprecation steps specific to libraries permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecation Steps Specific To Libraries`}</h3>
    <h4 {...{
      "id": "core-react",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#core-react",
        "aria-label": "core react permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`core-react`}</h4>
    <p>{`Core-React's `}<inlineCode parentName="p">{`addSubcomponents`}</inlineCode>{` re-export requires specific locations of JSDocs comments. It also requires `}<inlineCode parentName="p">{`@parent`}</inlineCode>{` on the subcomponents themself, and duplicating subcomponent notes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// This JSDoc comment makes it to Core Docs, but not editors.
/**
 * @parent Comp
 * @deprecated Example
 * @deprecatedSince 10
 */
export const Subcomp = () => null

const Comp_ = () => null

// This JSDoc comment makes it to Core Docs and editors.
/**
 * Some former description
 * @deprecated Example
 * @deprecatedSince 10
 * @since 1
 */
export const Comp = addSubcomponents(
  {
    // This JSDoc comment appears in editors, but not Core Docs.
    /**
     * Some former description
     * @deprecated Example
     * @deprecatedSince 10
     * @since 1
     */
    Subcomp,
  },
  Comp_
)
`}</code></pre>
    <p>{`Finally we need to make sure the typedoc can import them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// packages/core-react/src/_utils/propsTypedoc.ts
export * from './Thing/Thing'
`}</code></pre>
    <h4 {...{
      "id": "core-i18n-js",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#core-i18n-js",
        "aria-label": "core i18n js permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`core-i18n-js`}</h4>
    <p><inlineCode parentName="p">{`core-i18n-js`}</inlineCode>{` library is essentially a fork of `}<a parentName="p" {...{
        "href": "https://github.com/fnando/i18n-js",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://github.com/fnando/i18n-js`}</a>{`. We have diverged from the upstream slightly, and generally this library should not be touched or have things deprecated.`}</p>
    <h4 {...{
      "id": "core-icons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#core-icons",
        "aria-label": "core icons permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`core-icons`}</h4>
    <p><inlineCode parentName="p">{`core-icons`}</inlineCode>{` are updated in by an automation via a script, it handles breaking changes correctly but there is no current way to deprecate.`}</p>
    <h4 {...{
      "id": "data-table",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#data-table",
        "aria-label": "data table permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`data-table`}</h4>
    <p><inlineCode parentName="p">{`data-table`}</inlineCode>{` component should follow the same rules as `}<inlineCode parentName="p">{`core-react`}</inlineCode></p>
    <h4 {...{
      "id": "ui-service-utils",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#ui-service-utils",
        "aria-label": "ui service utils permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ui-service-utils`}</h4>
    <p><inlineCode parentName="p">{`ui-service-utils`}</inlineCode>{` library should follow the same rules as `}<inlineCode parentName="p">{`core-react`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      