import { Input } from '../Input'

/**

 @since 12.18.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-textinput--demo)

 @see [Design Guidelines](https://design.procore.com/text-input)

 */

export const TextInput = Input

TextInput.displayName = 'TextInput'
