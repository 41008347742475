import { Warning } from '@procore/core-icons'
import styled, { css } from 'styled-components'
import { Card } from '../Card/Card'
import { StyledSection, StyledSectionInner } from '../Section/Section.styles'
import { Typography } from '../Typography/Typography'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'
import type { ModalPlacement, ModalWidth } from './Modal.types'

export const fadeInClassName = 'fade-in'
export const fadeOutClassName = 'fade-out'
export const modalMaxWidth = '640px'
export const modalPixelGutter = spacing.xl
export const modalPixelWidths = {
  sm: 432,
  md: 688,
  lg: 1152,
  xl: 1920,
}

const compactModalFullscreenMaxHeight = 940
const screenWidthPixelBreakpoints = {
  mobile: {
    small: {
      min: 0,
      max: 439,
    },
    large: {
      min: 440,
      max: 767,
    },
  },
}

export const StyledModal = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  opacity: 0;
  transition: 0.3s opacity ease;
  width: 100vw;
  z-index: 0;
  pointer-events: none;

  &.${fadeInClassName} {
    opacity: 1;
    pointer-events: auto;
  }

  &.${fadeOutClassName} {
    opacity: 0;
    pointer-events: none;
  }
`

export const StyledModalButtons = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-left: ${spacing.lg}px;

  > * + * {
    margin-left: ${spacing.sm}px;
  }
`

export const StyledModalContainer = styled(Card)<{
  $compact: boolean
  $placement: ModalPlacement
  $width?: ModalWidth
}>`
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 2;

  ${({ $compact }) => {
    if (!$compact) {
      return ''
    }

    return css`
      @media (max-width: ${screenWidthPixelBreakpoints.mobile.small
          .max}px) and (max-height: ${compactModalFullscreenMaxHeight}px) {
        border-radius: 0;
        height: 100vh;
        width: 100vw;
      }
      @media (min-width: ${screenWidthPixelBreakpoints.mobile.large
          .min}px) and (max-width: ${screenWidthPixelBreakpoints.mobile.large
          .max}px) {
        max-height: calc(100vh - 24px);
        width: 416px;
      }
      @media (min-height: ${compactModalFullscreenMaxHeight + 1}px) {
        height: 916px;
        max-width: 100vw;
      }
    `
  }}

  ${({ $placement }) => {
    if ($placement === 'top') {
      return css`
        top: 5vh;
      `
    }

    return css`
      top: 50vh;
      transform: translateY(-50%);
    `
  }}

  ${({ $width, $placement }) => {
    if ($width) {
      return css`
        left: ${modalPixelGutter}px;
        right: ${modalPixelGutter}px;
        margin-left: auto;
        margin-right: auto;
        max-width: ${modalPixelWidths[$width]}px;

        // TODO deprecated, moving width up. delete line in breaking change
        ${StyledModalBody} {
          max-width: none;
        }
      `
    }
    // TODO deprecated, uses new centering, required width
    return css`
      left: 50vw;
      transform: translateX(-50%)
        ${$placement === 'center' && 'translateY(-50%)'};
    `
  }}
`

export const StyledModalFooter = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: ${spacing.xl}px ${spacing.xxl}px ${spacing.xxl}px;
`

export const StyledModalBody = styled.div<{
  $compact: boolean
  $isOverflowingY: boolean
  $noSideSpacing: boolean
}>`
  ${getTypographyIntent('body')}

  box-sizing: border-box;
  color: ${colors.gray15};
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ $noSideSpacing }) => {
    return css`
      padding: ${spacing.xl}px ${$noSideSpacing ? 0 : spacing.xxl}px;
    `
  }}

  ${({ $compact }) => {
    if (!$compact) {
      // TODO deprecated, moving width up. delete line in breaking change
      return css`
        max-width: ${modalMaxWidth};
      `
    }

    return css`
      padding: 8px 16px;
    `
  }}

  > ${StyledSection} {
    padding-left: 0;
    padding-right: 0;

    &:first-child > ${StyledSectionInner} {
      padding-top: 0;
    }
  }

  ${({ $isOverflowingY }) =>
    $isOverflowingY &&
    css`
      & + ${StyledModalFooter} {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: ${colors.gray85};
        ${StyledModalFooterSummary} {
          border-top: none;
        }
      }
    `}
`

export const StyledModalContent = styled.div<{ $compact?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow: hidden;
  width: 100%;

  ${({ $compact = false }) => {
    if (!$compact) {
      return ''
    }

    return css`
      max-height: unset;
    `
  }}
`

export const StyledModalFooterNotation = styled(Typography)`
  flex-grow: 1;
`

export const StyledModalHeader = styled.div<{
  $compact: boolean
  $isBodyScrolled: boolean
}>`
  align-items: flex-start;
  color: ${colors.gray10};
  display: flex;
  flex: 0 0 auto;
  position: relative;
  ${({ $compact, $isBodyScrolled }) => {
    if ($compact) {
      return css`
        border-bottom: 1px solid ${colors.gray85};
      `
    }

    return css`
      border-bottom: 1px solid
        ${$isBodyScrolled ? colors.gray85 : 'transparent'};
    `
  }}

  ${({ $compact }) => {
    let spacings = `${spacing.xl}px ${spacing.xxl}px`

    if ($compact) {
      spacings = `${spacing.md}px ${spacing.lg}px`
    }

    return css`
      padding: ${spacings};
    `
  }}
`

export const StyledModalHeading = styled.div<{ $compact: boolean }>`
  ${({ $compact }) => getTypographyIntent($compact ? 'h3' : 'h2')}
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  min-height: 36px;
  padding-right: ${({ $compact }) => ($compact ? 36 : spacing.lg)}px;

  ${({ $compact }) => {
    if (!$compact) {
      return ''
    }

    return css`
      min-height: unset;
    `
  }}
`

export const StyledModalCancel = styled.div`
  flex: 0 0 auto;
`

export const StyledModalScrim = styled.div`
  background: hsla(200, 8%, 10%, 0.6);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
`

export const StyledModalWarningIcon = styled(Warning)`
  color: ${colors.yellow40};
  margin-right: ${spacing.sm}px;
`
export const StyledModalFooterSummary = styled.div`
  ${getTypographyIntent('small')}
  border-top: 1px solid ${colors.gray85};
  border-bottom: 1px solid ${colors.gray85};
  padding: ${spacing.md}px ${spacing.xxl}px;
  color: ${colors.gray15};
  font-style: italic;
  background-color: ${colors.gray98};
  margin: -${spacing.xl}px -${spacing.xxl}px ${spacing.xl}px -${spacing.xxl}px;
  flex: 1 1 100%;
`
