import { Clear } from '@procore/core-icons/dist'
import React from 'react'
import { Box } from '../Box/Box'
import {
  MenuImperative,
  useMenuImperativeControlNavigation,
} from '../MenuImperative/MenuImperative'
import type { MenuRef, Selection } from '../MenuImperative/MenuImperative.types'
import { OverlayTrigger } from '../OverlayTrigger/OverlayTrigger'
import { Spinner } from '../Spinner/Spinner'
import { Token } from '../Token/Token'
import { Typography } from '../Typography/Typography'
import { isEventSource } from '../_hooks/ClickOutside'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import {
  StyledMultiSelectArrow,
  StyledMultiSelectArrowContainer,
  StyledMultiSelectButton,
  StyledMultiSelectClearIcon,
  StyledMultiSelectMenu,
  StyledMultiSelectSearch,
  StyledMultiSelectSearchIcon,
  StyledMultiSelectSearchInput,
  StyledMultiSelectToken,
  StyledMultiSelectValues,
} from './MultiSelect.styles'
import type {
  ChangeEvent,
  GroupedOptionsProps,
  GroupHeader,
  GroupItem,
  MultiSelectConfig,
  MultiSelectHook,
  MultiSelectOptionProps,
  MultiSelectOptionRendererProps,
  MultiSelectProps,
  MultiSelectTokenRendererProps,
  OptionItem,
} from './MultiSelect.types'

const targetShowKeys = ['ArrowDown', 'Down']

const targetHideKeys = ['Esc', 'Escape']

const overlayHideKeys = targetHideKeys

const emptyArray: OptionItem[] = []

function noop() {}

function returnTrue() {
  return true
}

function isAlphaNumeric(str: string) {
  return str.length === 1 && str.match(/[A-Za-z0-9 _.,!"'/$]*/i)
}

function setFocusTo(ref: React.RefObject<HTMLElement>) {
  if (ref.current) {
    ref.current.focus()
  }
}

function isOption(obj: React.ReactChild) {
  return typeof obj === 'object' && obj.type === Option
}

function defaultIsOptionDisabled() {
  return false
}

function defaultGetId(item: OptionItem) {
  return item.id
}

function defaultGetLabel(item: OptionItem) {
  return item.label
}

function defaultGetGroup(item: OptionItem) {
  return item.groupId
}

function defaultIsSelectable(obj: React.ReactChild) {
  typeof obj === 'object' && obj.type === Option
}

function defaultOptionRenderer(
  item: OptionItem,
  { getId, getLabel, qa, value, ...props }: MultiSelectOptionRendererProps
) {
  return (
    <Option
      key={getId(item)}
      value={value || item}
      data-qa={qa?.option?.(item)}
      {...props}
    >
      {getLabel(item)}
    </Option>
  )
}

function defaultGroupHeaderRenderer(group: GroupItem) {
  return (
    <MenuImperative.Group key={`group_${group.id}`} clickable={false}>
      {group.label}
    </MenuImperative.Group>
  )
}

function defaultTokenRenderer({
  focused,
  disabled,
  option,
  removeToken,
  getLabel,
  qa,
}: MultiSelectTokenRendererProps) {
  return (
    <Token disabled={disabled} focused={focused} data-qa={qa?.token?.(option)}>
      <Token.Label>{getLabel(option)}</Token.Label>
      <Token.Remove
        data-close
        onClick={removeToken}
        data-qa={qa?.tokenClear?.(option)}
      />
    </Token>
  )
}

function useMultiSelect({
  getId,
  getLabel,
  getGroup,
  menuRef,
  onChange = noop,
  onSearch: onSearch_,
  options: options_,
  value,
  optgroups,
}: MultiSelectConfig): MultiSelectHook {
  const [index, setIndex] = React.useState<number | null>(null)

  const [search, setSearch] = React.useState<string>('')

  const valueIds = value.reduce<Set<number | string>>((acc, cur) => {
    acc.add(getId(cur))

    return acc
  }, new Set())

  const count = value.length

  const options = React.useMemo(
    function () {
      if (onSearch_) {
        return options_
      }

      return options_.filter((opt) =>
        getLabel(opt).toLowerCase().includes(search.toLowerCase())
      )
    },
    [options_, onSearch_, getLabel, search]
  )

  const onSearch = React.useCallback(
    function (e: ChangeEvent) {
      if (onSearch_) {
        onSearch_(e)
      }

      setSearch(e.target.value)

      // TODO: because menu highlighting is now imperative, we have to wait
      // for a render to highlight the first item. Is there a better way
      // to schedule this?
      setTimeout(() => menuRef.current?.highlightFirst(), 0)
    },
    [menuRef, onSearch_]
  )

  const clearToken = () => setIndex(null)

  const removeSelection = React.useCallback(
    function (selection: OptionItem, selected: OptionItem[]) {
      return selected.filter((s) => getId(s) !== getId(selection))
    },
    [getId]
  )

  const onSelect = React.useCallback(
    (selection: Selection) => {
      const newSelected = valueIds.has(getId(selection.item))
        ? removeSelection(selection.item, value)
        : [...value, selection.item]

      setSearch('')

      onChange(newSelected)
    },
    [setSearch, onChange, getId, removeSelection, value, valueIds]
  )

  const removeToken = React.useCallback(
    function (i: number) {
      onChange(removeSelection(value[i], value))
    },
    [value, onChange, removeSelection]
  )

  const decrementToken = React.useCallback(
    function () {
      if (index === null) {
        // index is null, set it to the last token index
        setIndex(count - 1)
      } else {
        // decrementToken by one, don't go below 0
        setIndex(Math.max(0, index - 1))
      }
    },
    [count, index, setIndex]
  )

  const incrementToken = React.useCallback(
    function () {
      if (index === count - 1) {
        setIndex(null)
      } else if (index !== null) {
        setIndex(index + 1)
      }
    },
    [count, index, setIndex]
  )

  const onKeyDown = React.useCallback(
    function (event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === 'Enter') {
        menuRef.current?.select(event)
      }

      if (event.key === 'ArrowUp' || event.key === 'Up') {
        menuRef.current?.prev()
      }

      if (event.key === 'ArrowDown' || event.key === 'Down') {
        menuRef.current?.next()
      }

      if (search === '') {
        if (event.key === 'Backspace') {
          // to prevent accidentally deleting too many tokens, don't do anything
          // on repeating key events
          if (event.repeat) {
            return
          }

          // if we are focused on a token, remove it, otherwise remove the last token
          removeToken(index !== null ? index : count - 1)

          if (count >= 1) {
            decrementToken()
          } else {
            clearToken()
          }
        }

        if (event.key === 'ArrowLeft' || event.key === 'Left') {
          decrementToken()
        }

        if (event.key === 'ArrowRight' || event.key === 'Right') {
          incrementToken()
        }
      }
    },
    [count, decrementToken, incrementToken, index, menuRef, removeToken, search]
  )

  React.useEffect(
    function () {
      // if we've deleted a token and our new count is less than
      // our current index, clear the index
      if (count <= (index || 0)) {
        clearToken()
      }
    },
    [count, index]
  )

  const groupedOptions = React.useMemo(() => {
    if (!optgroups || !optgroups.length) {
      return options
    }

    const optionsByGroup: Record<string, (OptionItem | GroupHeader)[]> =
      optgroups.reduce((acc, { id }) => {
        return {
          ...acc,
          [id]: [],
        }
      }, {})

    const orphanOptions = [] as OptionItem[]

    options.forEach((option) => {
      const groupId = getGroup(option)
      const groupOptions = optionsByGroup[groupId]

      if (!groupId || !groupOptions) {
        orphanOptions.push(option)
        return
      }

      optionsByGroup[groupId].push(option)
    })

    if (orphanOptions.length) {
      console.warn(
        `Could not find option groups for the options below. Make sure that every option has a proper "groupId" field or if "getGroup" is implemented correctly.`,
        orphanOptions
      )
    }

    return optgroups.flatMap((group: GroupItem) => {
      const groupHeader = { ...group, isGroupHeader: true }
      const groupOptions = optionsByGroup[group.id]

      if (!groupOptions.length) {
        return []
      }

      return [groupHeader, ...groupOptions]
    }, [])
  }, [options, getGroup, optgroups])

  return {
    ids: valueIds,
    index,
    menuRef,
    options: groupedOptions,
    search,

    clearToken,
    decrementToken,
    incrementToken,
    onSearch,
    onSelect,
    removeToken,

    domHandlers: {
      onKeyDown,
    },
  }
}

export const Option = React.forwardRef<HTMLDivElement, MultiSelectOptionProps>(
  function Option({ value, ...props }, ref) {
    return <MenuImperative.Item ref={ref} {...props} item={value} />
  }
)

function GroupedOptions({
  multiselect,
  groupHeaderRenderer,
  optionRenderer,
  getId,
  getLabel,
  isOptionDisabled,
  qa,
}: GroupedOptionsProps) {
  return (
    <MenuImperative.Options>
      {multiselect.options.map((opt) => {
        if (opt.isGroupHeader) {
          const groupHeader: GroupHeader = opt
          return groupHeaderRenderer(groupHeader)
        }

        return optionRenderer(opt, {
          getId,
          getLabel,
          // @ts-ignore
          // Object literal may only specify known properties, and 'key' does not exist in type 'OptionRendererProps'.
          key: getId(opt),
          selected: multiselect.ids.has(getId(opt)),
          disabled: isOptionDisabled(opt),
          value: opt,
          qa,
        })
      })}
    </MenuImperative.Options>
  )
}

/**

 Multi selects allow our users to choose one to many options from a list,
 presented in a dropdown. We typically see these selects on forms.

 Other select components are group select, select, and tiered select.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_multiselect--basic)

 @see [Design Guidelines](https://design.procore.com/multi-select)

 */
const MultiSelect_ = React.forwardRef<HTMLDivElement, MultiSelectProps>(
  function MultiSelect(
    {
      afterHide = noop,
      afterShow = noop,
      beforeHide = returnTrue,
      beforeShow = returnTrue,
      block = false,
      disabled = false,
      emptyMessage,
      error = false,
      footer,
      getId = defaultGetId,
      getLabel = defaultGetLabel,
      getGroup = defaultGetGroup,
      loading = false,
      onChange = noop,
      onScrollBottom,
      onSearch,
      options = emptyArray,
      isOptionDisabled = defaultIsOptionDisabled,
      optionRenderer = defaultOptionRenderer,
      optgroups,
      groupHeaderRenderer = defaultGroupHeaderRenderer,
      tokenRenderer = defaultTokenRenderer,
      placeholder,
      placement = 'bottom-left',
      value = emptyArray,
      tabIndex = 0,
      ['aria-labelledby']: ariaLabelledBy,
      qa = {},
      ...props
    },
    ref
  ) {
    const i18n = useI18nContext()
    const ownRef = React.useRef<HTMLDivElement>()
    const targetRef = (ref as React.RefObject<HTMLDivElement>) || ownRef

    const searchRef = React.useRef<HTMLInputElement>(null)

    const menuRef = React.useRef<MenuRef | null>(null)
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const { menuNavigationTriggerProps, menuProps } =
      useMenuImperativeControlNavigation(menuRef, isMenuOpen)

    const I18n = useI18nContext()

    const multiselect = useMultiSelect({
      getId,
      getLabel,
      getGroup,
      menuRef,
      onSearch,
      options,
      onChange: function (value) {
        onChange(value)

        setFocusTo(searchRef)
      },
      value,
      optgroups,
    })

    const hasClearIcon = value.length > 0 && !loading

    const isNavigatingTokens = multiselect.index !== null

    const overlay = (
      <StyledMultiSelectMenu shadowStrength={2}>
        <MenuImperative
          {...menuProps}
          ref={menuRef}
          onScrollBottom={onScrollBottom}
          onSelect={multiselect.onSelect}
          data-qa="multi-select-menu"
          role="listbox"
        >
          {multiselect.options.length ? (
            <GroupedOptions
              multiselect={multiselect}
              getId={getId}
              getLabel={getLabel}
              isOptionDisabled={isOptionDisabled}
              groupHeaderRenderer={groupHeaderRenderer}
              optionRenderer={optionRenderer}
              qa={qa}
            />
          ) : (
            <Box padding="md lg">
              <Typography color="gray45" intent="small" italic>
                {emptyMessage || I18n.t('core.multiSelect.noResults')}
              </Typography>
            </Box>
          )}
          {footer && <MenuImperative.Footer>{footer}</MenuImperative.Footer>}
        </MenuImperative>
      </StyledMultiSelectMenu>
    )

    return (
      <OverlayTrigger
        canFlip={true}
        afterHide={() => {
          multiselect.clearToken()
          setIsMenuOpen(false)

          afterHide()
        }}
        afterShow={() => {
          multiselect.clearToken()

          menuRef.current?.highlightFirst()
          setIsMenuOpen(true)

          afterShow()
        }}
        beforeHide={(e) => {
          // if we are clicking somewhere in the target, set focus on the search
          if (e instanceof MouseEvent && isEventSource(targetRef, e)) {
            setFocusTo(searchRef)
          }

          return beforeHide(e)
        }}
        beforeShow={(e) => {
          setFocusTo(searchRef)

          return beforeShow(e)
        }}
        hideKeys={{
          overlay: overlayHideKeys,
          target: targetHideKeys,
        }}
        overlay={overlay}
        placement={placement}
        ref={targetRef}
        showKeys={targetShowKeys}
        trigger="click"
      >
        {({ disable, enable, isVisible }) => {
          return (
            <StyledMultiSelectButton
              $block={block}
              $error={error}
              $emptyValue={value.length === 0}
              $disabled={disabled}
              $hasClearIcon={hasClearIcon}
              $loading={loading}
              $open={isVisible}
              {...props}
            >
              <StyledMultiSelectValues>
                {value.map((selection, i) => {
                  return (
                    <StyledMultiSelectToken key={getId(selection)}>
                      {tokenRenderer({
                        option: selection,
                        focused: i === multiselect.index,
                        disabled: disabled,
                        getLabel,
                        removeToken: function () {
                          multiselect.removeToken(i)
                        },
                        qa,
                      })}
                    </StyledMultiSelectToken>
                  )
                })}

                <StyledMultiSelectSearch>
                  <StyledMultiSelectSearchInput
                    aria-labelledby={ariaLabelledBy}
                    data-qa="core-multiselect-input"
                    $isNavigatingTokens={isNavigatingTokens}
                    disabled={disabled}
                    onBlur={multiselect.clearToken}
                    onChange={function (e) {
                      multiselect.onSearch(e)
                    }}
                    {...menuNavigationTriggerProps}
                    onKeyDown={function (e) {
                      if (isNavigatingTokens && e.key !== 'Tab') {
                        // if we are navigating tokens don't do any input but allow Tab
                        e.preventDefault()
                      }

                      if (isVisible) {
                        // if the overlay is visible, close it and keep focus
                        if (e.key === 'Tab') {
                          e.preventDefault()

                          disable(e)
                        }
                        // if open, don't notify parents (like Modal) but still let OverlayTrigger close it and keep it focus
                        if (e.key === 'Esc' || e.key === 'Escape') {
                          e.stopPropagation()
                          disable(e)
                        }
                      } else {
                        // if we type in an alphanumeric character, show the overlay
                        if (isAlphaNumeric(e.key)) {
                          enable(e)
                        }
                      }

                      multiselect.domHandlers.onKeyDown(e)
                    }}
                    placeholder={
                      value?.length > 0
                        ? ''
                        : placeholder ?? I18n.t('core.multiSelect.selectValues')
                    }
                    ref={searchRef}
                    tabIndex={tabIndex}
                    value={multiselect.search}
                  />
                </StyledMultiSelectSearch>
              </StyledMultiSelectValues>
              <StyledMultiSelectSearchIcon>
                <StyledMultiSelectClearIcon
                  aria-label={i18n.t('core.multiSelect.clearAll')}
                  data-close
                  data-qa="core-multiselect-clear"
                  size="sm"
                  variant="tertiary"
                  disabled={disabled}
                  icon={<Clear />}
                  onClick={() => onChange([])}
                  tabIndex={-1} // TODO revisit this accessibility
                />
                {loading ? (
                  <Spinner color="blue50" size="xs" />
                ) : (
                  <StyledMultiSelectArrowContainer
                    data-qa="multiselect-select-arrow"
                    onClick={(e) => isVisible && disable(e)}
                  >
                    <StyledMultiSelectArrow />
                  </StyledMultiSelectArrowContainer>
                )}
              </StyledMultiSelectSearchIcon>
            </StyledMultiSelectButton>
          )
        }}
      </OverlayTrigger>
    )
  }
)

MultiSelect_.displayName = 'MultiSelect'

/**
 * @see [Storybook](https://procore.github.io/core/latest/?path=/story/demos-multiselect--demo)
 * @see [Design Guidelines](https://design.procore.com/multi-select)
 */
export const MultiSelect = addSubcomponents(
  {
    Option,
  },
  MultiSelect_
)
