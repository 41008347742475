import React from 'react'
import type { SpanAttributes } from '../_utils/types'
import { StyledTypography } from './Typography.styles'
import type { TypographyProps } from './Typography.types'

/**

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-typography--demo)

 */
export const Typography = React.forwardRef<
  HTMLSpanElement,
  SpanAttributes & TypographyProps
>(function Typography(
  { children, color, breakWord, intent, italic, weight, ...props },
  ref
) {
  return (
    <StyledTypography
      $breakWord={breakWord}
      $color={color}
      $intent={intent}
      $italic={italic}
      $weight={weight}
      {...props}
      ref={ref}
    >
      {children}
    </StyledTypography>
  )
})

Typography.displayName = 'Typography'
