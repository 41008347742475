import styled, { css } from 'styled-components'
import { Card } from '../Card'
import { StyledItem } from '../MenuImperative/MenuImperative.styles'
import { getTypographyIntent } from '../Typography'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

type TabStyleProps = {
  $selected?: boolean
  $dark?: boolean
}

const hiddenTab = css`
  pointer-events: none;
  position: absolute;
  visibility: hidden;
`

const visible = css`
  pointer-events: all;
  position: relative;
  visibility: visible;
`

const borderRadius = css`
  border-radius: ${spacing.xs}px ${spacing.xs}px 0 0;
`

//// START BASE LAYOUT COMPONENTS FOR TABS ////

/**
 * Elements to handle general layout and browser resets.
 * Will be the referencable element (class) other selectors
 * that need to target general layout references.
 */
const StyledTabBase = styled.div<TabStyleProps>`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  white-space: nowrap;

  // Browser button and anchor style resets
  text-decoration: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  padding: 0;
`

const StyledTabInnerBase = styled.div<TabStyleProps>`
  align-self: baseline;
  box-sizing: border-box;

  &,
  > a {
    display: flex;
    text-decoration: none;
    width: 100%;
  }
`

// This so that it can be visible/hidden via CSS down below
const StyledDropdownTabBase = styled(StyledTabBase)`
  ${hiddenTab}
`

//// END BASE LAYOUT COMPONENTS FOR TABS ////

//// START TRADITIONAL DESIGN SYSTEM TAB STYLES ////

/**
 * Styles specific to the Design System general cases (not Global nav)
 */
function getTabStyle() {
  return css<TabStyleProps>`
    ${borderRadius};
    height: 26px;

    & + ${StyledTabBase} {
      margin-left: ${spacing.xl}px;
    }

    a& {
      ${borderRadius}
    }

    &,
    a&,
    button& {
      ${({ $selected }) => {
        if ($selected) {
          return css`
            border-bottom: 3px solid ${colors.gray15};
          `
        }
      }}
    }

    &:focus,
    &:focus-within,
    &:hover {
      background: ${({ $dark }) => ($dark ? 'transparent' : colors.gray90)};
    }

    &:focus,
    &:focus-within {
      box-shadow: inset 0 0 0 2px ${colors.blue40};
      outline: none;

      a {
        outline: none;
      }
    }
  `
}

const StyledTab = styled(StyledTabBase)<TabStyleProps>`
  ${getTabStyle}
`

const StyledTabInner = styled(StyledTabInnerBase)<TabStyleProps>`
  ${getTypographyIntent('body')}

  padding: 0 ${spacing.xs}px;
  font-weight: ${({ $selected }) => ($selected ? 600 : 400)};

  &,
  > a {
    ${borderRadius};
    color: ${colors.gray15};
    height: 23px;
  }

  /* TODO: Dark mode was not considered for helix updates,
  *  matching previous UX patterns - April 2nd 2024
  */
  ${({ $selected, $dark }) => {
    if ($dark) {
      return css`
        &,
        > a,
        button&,
        a& {
          color: ${$selected ? colors.white : colors.gray70};

          &:hover {
            color: white;
          }
        }
      `
    }
  }}
`

const StyledDropdownTab = styled(StyledDropdownTabBase)`
  ${getTabStyle}
`

const StyledDropdownTabInner = styled(StyledTabInner)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
`

const StyledLink = styled.span`
  align-items: center;
  display: flex;
  height: 23px; // TODO one-off
  ${borderRadius};
  box-sizing: border-box;
`

//// END TRADITIONAL DESIGN SYSTEM TAB STYLES ////

//// START HELIX GLOBAL NAVIGATION TAB STYLES ////

function getHelixHeaderTabStyles() {
  return css<TabStyleProps>`
    border-radius: 4px;
    color: ${colors.white};
    height: 24px;
    overflow: hidden;
    padding: ${spacing.xs}px;
    text-align: center;

    ${getTypographyIntent('small')};
    font-weight: 600; // Different than typograhy intent small, must be defined after

    & + ${StyledTabBase} {
      margin-left: ${spacing.sm}px;
    }

    &:hover {
      background: rgba(117, 131, 138, 0.3);
      color: ${colors.white};
      text-decoration: none;
    }

    &:focus {
      outline: 2px solid ${colors.blue70};
      outline-offset: -2px;
      background: rgba(117, 131, 138, 0.3);
    }

    ${({ $selected }) => {
      if ($selected) {
        return css`
          background: ${colors.gray30};
        `
      }
    }}
  `
}

const StyledGlobalNavigationTab = styled(StyledTabBase)`
  ${getHelixHeaderTabStyles}
`

const StyledGlobalNavigationDropdownTab = styled(StyledDropdownTabBase)`
  ${getHelixHeaderTabStyles}
`

const StyledGlobalNavigationDropdownTabInner = styled(StyledTabInnerBase)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
`

const StyledGlobalNavigationLink = styled.span`
  padding-left: 6px;
  padding-right: 6px;
`

//// END HELIX GLOBAL NAVIGATION TAB STYLES ////

export const StyledOverlay = styled(Card)`
  max-height: 40vh;

  ${StyledItem} {
    padding: 0;
    display: flex;
    justify-content: center;

    a {
      margin: unset;
      padding: unset;
    }
  }

  ${StyledLink} {
    padding: 0;
    display: flex;
  }

  ${StyledTabInnerBase} {
    // unsure why top and bottom are not uniform
    // design docs show uniform 4px
    padding: 2px 16px 4px 16px;
  }

  ${StyledLink},
  ${StyledTabBase} {
    color: ${colors.gray15};
    border-radius: 0;
    display: flex;
    width: 100%;

    background: inherit;
    font-weight: inherit;
    font-size: inherit;
    padding: 0;
  }

  [data-selected='true'] {
    ${StyledLink}, ${StyledTabBase} {
      border-bottom-color: transparent;
      color: ${colors.blue50};
    }
  }
`

export const StyledTabList = styled.div`
  display: flex;
  width: 100%;
`

export const StyledTabs = styled.nav<{
  $dark?: boolean
  index?: number
}>`
  background-color: ${({ $dark }) => $dark && colors.gray15};
  display: flex;
  overflow: hidden;
  width: 100%;

  ${StyledTabBase} {
    // we want to hide all tabs after overflow index
    &:nth-child(1n + ${({ index }) => index}) {
      // but not the "More" dropdown tab
      &:not(${StyledDropdownTabBase}) {
        ${hiddenTab}
      }
    }
  }

  ${({ index = -1 }) =>
    index >= 0 &&
    css`
      ${StyledDropdownTabBase} {
        ${visible}
      }
    `}
`

/**
 * Switches between different Tab styles of traditional and helix header navigation.
 * Allows separate style CSS without conditionals across all the components.
 * @returns Subcomponents to render
 */
export function getStyledComponents(helixHeader = false) {
  if (helixHeader) {
    return {
      Tab: StyledGlobalNavigationTab,
      TabInner: StyledTabInnerBase,
      Link: StyledGlobalNavigationLink,
      DropdownTab: StyledGlobalNavigationDropdownTab,
      DropdownTabInner: StyledGlobalNavigationDropdownTabInner,
    }
  }
  return {
    Tab: StyledTab,
    TabInner: StyledTabInner,
    Link: StyledLink,
    DropdownTab: StyledDropdownTab,
    DropdownTabInner: StyledDropdownTabInner,
  }
}
