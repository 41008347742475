import { ChevronRight, Help, Home, Plus } from '@procore/core-icons/dist'
import React from 'react'
import { Box } from '../Box/Box'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { Button } from '../Button/Button'
import { FlexList } from '../FlexList/FlexList'
import { Input } from '../Input/Input'
import {
  MenuImperative,
  useMenuImperativeControlNavigation,
} from '../MenuImperative/MenuImperative'
import type { MenuRef, Selection } from '../MenuImperative/MenuImperative.types'
import {
  CloseOnFocus,
  OverlayTrigger,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { Select } from '../Select/Select'
import { Spinner } from '../Spinner/Spinner'
import { Tooltip } from '../Tooltip'
import { Typography } from '../Typography/Typography'
import { isEventSource } from '../_hooks/ClickOutside'
import { useI18nContext } from '../_hooks/I18n'
import type { Props } from '../_utils/types'
import { TieredSelectContext, useHighlightItemEffects } from './TieredSelect'
import {
  StyledTieredSelect,
  StyledTieredSelectArrowButton,
  StyledTieredSelectBreadcrumbs,
  StyledTieredSelectHome,
  StyledTieredSelectIcon,
  StyledTieredSelectLoadingMore,
  StyledTieredSelectOption,
  StyledTieredSelectOverlay,
  StyledTieredSelectSpinnerOverlay,
} from './TieredSelect.styles'
import type {
  ArrowButtonProps,
  Id,
  InternalTieredSelectProps,
  OptionsProps,
  QuickCreateInputProps,
  Tier,
  TieredSelectMenuProps,
  TierSelection,
} from './TieredSelect.types'

function stopProp(e: React.KeyboardEvent<HTMLDivElement>) {
  if (e.key === 'Escape' || e.key === 'Esc') {
    e.stopPropagation()
  }
}

const empty: any[] = []

const hideKeys = {
  overlay: ['Escape', 'Esc'],
  target: ['Escape', 'Esc'],
}

function ArrowButton({ onClick, ...props }: ArrowButtonProps) {
  const i18n = useI18nContext()
  return (
    <StyledTieredSelectArrowButton
      aria-label={i18n.t('core.tieredSelect.goToTier')}
      {...props}
      icon={<ChevronRight />}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        onClick && onClick(event)
      }}
      size="sm"
      tabIndex={-1}
      variant="tertiary"
    />
  )
}

function ItemTooltipIcon() {
  return (
    <StyledTieredSelectIcon alignItems="center" justifyContent="center">
      <Help />
    </StyledTieredSelectIcon>
  )
}

const QuickCreateInput = React.forwardRef<
  HTMLInputElement,
  QuickCreateInputProps
>(function QuickCreateInput({ autoFocus, onSubmit, placeholder }, ref) {
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onSubmit(e.currentTarget.value)
    }
  }

  return (
    <Box flexGrow="1" justifyContent="center" padding="md">
      <Input
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
      />
    </Box>
  )
})

function Options({ children, emptyMessage, ...props }: OptionsProps & Props) {
  if (React.Children.count(children) === 0) {
    return (
      <MenuImperative.Options>
        <Box paddingLeft="lg" {...props}>
          <Typography color="gray45" intent="small" italic>
            {emptyMessage}
          </Typography>
        </Box>
      </MenuImperative.Options>
    )
  }

  return <MenuImperative.Options>{children}</MenuImperative.Options>
}

const TieredSelectMenu = React.forwardRef<
  HTMLDivElement,
  TieredSelectMenuProps
>(function TieredSelectMenu(
  {
    children,
    currentTier,
    footer: customFooter,
    loading,
    loadingMore,
    menuRef,
    onNavigate,
    onSearch,
    onChange,
    options = empty,
    value,
    previousValue,
    ...props
  },
  ref
) {
  const {
    getGroupId,
    getId,
    getLabel,
    getNextGroupId,
    i18nScope,
    onQuickCreate,
    onScrollBottom,
    isTierDisabled,
  } = React.useContext(TieredSelectContext)

  const quickCreateRef = React.useRef<HTMLInputElement>(null)

  const { hide } = useOverlayTriggerContext()

  const I18n = useI18nContext()

  const [isQuickCreating, setIsQuickCreating] = React.useState(false)

  const [searchValue, setSearchValue] = React.useState('')
  const { menuProps, menuNavigationTriggerProps } =
    useMenuImperativeControlNavigation(menuRef)

  const searchIsEmpty = searchValue === ''

  const lastEntryInValue = value[value.length - 1]

  const internalOnSearch = React.useCallback(
    function (e: React.ChangeEvent<HTMLInputElement>) {
      setSearchValue(e.target.value)
      onSearch && onSearch(e)
    },
    [onSearch]
  )

  // When searching (all options have been flattened) we dont filter by id
  const filteredOptions = React.useMemo(() => {
    return searchIsEmpty
      ? options.filter((tier) => {
          return getGroupId(tier) === currentTier
        })
      : options
  }, [currentTier, getGroupId, options, searchIsEmpty])

  useHighlightItemEffects({
    currentTier,
    searchValue,
    menuRef,
    loading,
    loadingMore,
  })

  const onNavigateHome = React.useCallback(
    function () {
      onNavigate(null, [])
      menuRef.current?.highlightFirst()
    },
    [menuRef, onNavigate]
  )

  const onBreadCrumbNavigate = React.useCallback(
    function (tier: Tier) {
      const newTier = value.indexOf(tier)
      onNavigate(getNextGroupId(tier), value.slice(0, newTier + 1))
      menuRef.current?.highlightFirst()
    },
    [getNextGroupId, menuRef, onNavigate, value]
  )

  function onNavigateForward(tier: Tier) {
    if (searchIsEmpty && !isQuickCreating && tier) {
      const newValue = [...value, tier]
      onNavigate(getNextGroupId(tier), newValue)
      menuRef.current?.highlightFirst()
    }
  }

  /**
   * need to skip back two levels if coming from a leaf node;
   * out from the leaf and then out of the parent. this is because
   * when tiers aren't selectectable we show the parent of the leaf
   */
  function onNavigateBackward() {
    if (value.length !== 0 && searchIsEmpty) {
      const newValue = value.slice(0, Math.max(0, value.length - 1))
      const newTier = value[value.length - 1]

      !isQuickCreating && onNavigate(getGroupId(newTier), newValue)
      menuRef.current?.highlightFirst()
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    const tier = menuRef.current?.highlighted()
    if (e.key === 'Right' || e.key === 'ArrowRight') {
      onNavigateForward(tier)
      return
    }

    if (e.key === 'Left' || e.key === 'ArrowLeft') {
      onNavigateBackward()
      return
    }

    menuNavigationTriggerProps.onKeyDown?.(e)
  }

  function onTierSelect(selection: Selection) {
    const isSameGroup = value.length
      ? getGroupId(lastEntryInValue) === getGroupId(selection.item)
      : false

    // if the selection is in the same group as the old selection, replace the old selection
    const newValue = isSameGroup
      ? [...value.slice(0, value.length - 1), selection.item]
      : [...value, selection.item]

    onChange({ ...selection, value: newValue })
    searchValue &&
      internalOnSearch({
        target: { value: '' },
      } as React.ChangeEvent<HTMLInputElement>)
    hide(selection.event)
  }

  function showBreadcrumbs() {
    return value.length && searchIsEmpty
  }

  const menuEmptyMessage = searchIsEmpty
    ? I18n.t('emptyMessage', { scope: i18nScope })
    : I18n.t('searchEmptyMessage', { scope: i18nScope })

  const handleQuickCreate = React.useCallback(
    function (e: React.KeyboardEvent<HTMLButtonElement>) {
      // stops menu from selecting item and closing on enter press
      e.stopPropagation()
      if (e.key === 'Enter') {
        setIsQuickCreating(true)
      }
    },
    [setIsQuickCreating]
  )

  const onQuickCreateSubmit = React.useCallback(
    function () {
      onQuickCreate &&
        quickCreateRef.current &&
        onQuickCreate(quickCreateRef.current.value)
      setIsQuickCreating(false)
    },
    [onQuickCreate, quickCreateRef, setIsQuickCreating]
  )

  const RenderFooter = React.useCallback(() => {
    if (customFooter) {
      // Render custom footer, if defined.
      if (typeof customFooter === 'function') {
        return <MenuImperative.Footer>{customFooter()}</MenuImperative.Footer>
      }

      return <MenuImperative.Footer>{customFooter}</MenuImperative.Footer>
    }

    if (onQuickCreate === undefined) {
      // no footer if `onQuickCreate` is not defined.
      return null
    }

    if (isQuickCreating) {
      // Render `Cancel` and `Create` labels if "quick creating".
      return (
        <MenuImperative.Footer>
          <FlexList size="xs" justifyContent="flex-end">
            <Button
              onClick={() => setIsQuickCreating(false)}
              size="sm"
              variant="tertiary"
            >
              {I18n.t('quickCreateCancelLabel', {
                scope: i18nScope,
              })}
            </Button>
            <Button onClick={onQuickCreateSubmit} size="sm">
              {I18n.t('quickCreateCreateLabel', {
                scope: i18nScope,
              })}
            </Button>
          </FlexList>
        </MenuImperative.Footer>
      )
    }

    return (
      <MenuImperative.Footer>
        <Button
          block={true}
          icon={<Plus size="md" />}
          onClick={() => setIsQuickCreating(true)}
          onKeyDown={handleQuickCreate}
          size="sm"
        >
          {I18n.t('quickCreateActionLabel', {
            scope: i18nScope,
          })}
        </Button>
      </MenuImperative.Footer>
    )
  }, [
    I18n,
    customFooter,
    handleQuickCreate,
    i18nScope,
    isQuickCreating,
    onQuickCreate,
    onQuickCreateSubmit,
  ])

  function isTierSelected(tier: Tier) {
    if (previousValue.length) {
      return getId(tier) === getId(previousValue[previousValue.length - 1])
    }
    return false
  }

  return (
    <StyledTieredSelectOverlay ref={ref} shadowStrength={2}>
      <StyledTieredSelectSpinnerOverlay
        data-qa="tiered-select-loading"
        data-loading={loading}
        loading={loading}
        label={I18n.t('spinnerLabel', { scope: i18nScope }) || ''}
        size="md"
      >
        <StyledTieredSelect
          onScrollBottom={onScrollBottom}
          onSelect={onTierSelect}
          ref={menuRef}
          {...props}
          {...menuProps}
          role="none"
        >
          <MenuImperative.Header>
            {!isQuickCreating && (
              <MenuImperative.Search
                autoComplete="false"
                onChange={internalOnSearch}
                placeholder={I18n.t('searchPlaceholder', {
                  scope: i18nScope,
                })}
                {...menuNavigationTriggerProps}
                onKeyDown={onKeyDown}
              />
            )}
            {showBreadcrumbs() ? (
              <StyledTieredSelectBreadcrumbs $nonInteractive={isQuickCreating}>
                <Breadcrumbs.Crumb onClick={onNavigateHome}>
                  <StyledTieredSelectHome
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Home data-qa="tiered-select-home" />
                  </StyledTieredSelectHome>
                </Breadcrumbs.Crumb>
                {value.map((tier, index, array) => {
                  return (
                    <Breadcrumbs.Crumb
                      active={index === array.length - 1}
                      key={`${getId(tier)}-${index}`}
                      onClick={() => onBreadCrumbNavigate(tier)}
                    >
                      {getLabel(tier)}
                    </Breadcrumbs.Crumb>
                  )
                })}
              </StyledTieredSelectBreadcrumbs>
            ) : null}
          </MenuImperative.Header>
          {!isQuickCreating ? (
            <Options emptyMessage={menuEmptyMessage}>
              {filteredOptions
                .map((tier, index) => {
                  const tooltip = isTierDisabled?.(tier) ?? false
                  const isDisabled = Boolean(tooltip)
                  const showNextTierArrow =
                    !tooltip &&
                    (Boolean(getNextGroupId(tier)) || Boolean(onQuickCreate))
                  const overlay = typeof tooltip === 'string' ? tooltip : null
                  const menuItem = (
                    <StyledTieredSelectOption
                      item={tier}
                      key={`${getId(tier)}-${index}`}
                      selected={isTierSelected(tier)}
                      disabled={isDisabled}
                    >
                      {getLabel(tier)}
                      {showNextTierArrow && (
                        <Box paddingLeft="sm">
                          <ArrowButton
                            data-qa="tiered-select-arrow"
                            onClick={() => onNavigateForward(tier)}
                          />
                        </Box>
                      )}
                      {tooltip && (
                        <Box paddingLeft="sm">
                          <ItemTooltipIcon />
                        </Box>
                      )}
                    </StyledTieredSelectOption>
                  )
                  if (isDisabled && overlay) {
                    return (
                      <Tooltip
                        key={`${getId(tier)}-${index}-tooltip`}
                        overlay={overlay}
                        trigger={'hover'}
                        placement="right"
                      >
                        {menuItem}
                      </Tooltip>
                    )
                  }

                  return menuItem
                })
                .concat(
                  loadingMore && !loading
                    ? [
                        <StyledTieredSelectLoadingMore
                          data-qa="tiered-select-loading-more"
                          key={'tiered-select-loading-more'}
                        >
                          <Spinner size="sm" />
                        </StyledTieredSelectLoadingMore>,
                      ]
                    : []
                )}
            </Options>
          ) : (
            <QuickCreateInput
              autoFocus={isQuickCreating}
              onSubmit={onQuickCreateSubmit}
              placeholder={I18n.t('quickCreatePlaceholder', {
                scope: i18nScope,
              })}
              ref={quickCreateRef}
            />
          )}
          <RenderFooter />
          <CloseOnFocus hide={hide} />
        </StyledTieredSelect>
      </StyledTieredSelectSpinnerOverlay>
    </StyledTieredSelectOverlay>
  )
})

export const TierSelectionTieredSelect = React.forwardRef<
  HTMLDivElement,
  InternalTieredSelectProps
>(function TierSelectionTieredSelect(
  {
    className,
    disabled = false,
    error = false,
    loading = false,
    onKeyDown, // goes no where
    options = empty,
    value = empty,
    ...props
  },
  ref
) {
  const {
    afterHide,
    afterShow,
    beforeHide,
    beforeShow,
    block,
    getLabel,
    getNextGroupId,
    getValueString,
    i18nScope,
    isLeaf,
    onChange,
    onClear,
    onNavigate,
    onSearch,
    tabIndex,
  } = React.useContext(TieredSelectContext)
  const I18n = useI18nContext()

  const tieredSelectMenuRef = React.useRef<MenuRef>(null)
  const tieredSelectButtonRef = React.useRef<HTMLDivElement>(null)
  const clearRef = React.createRef<HTMLButtonElement>()

  const [valueString, setValueString] = React.useState<string | undefined>('')
  const [currentTier, setCurrentTier] = React.useState<Id>(null)

  // internal value used for navigation
  const [internalValue, setInternalValue] = React.useState<Tier[]>(value)
  // value to reset to if navigation occurs but no selection has been made
  const [previousValue, setPreviousValue] = React.useState<Tier[]>(value)

  // if value prop changes, update value state, current tier, and set button label
  // else open to root
  React.useEffect(
    function () {
      const lastEntryInValue = value[value.length - 1]

      if (value.length > 0) {
        setInternalValue(value)
        setPreviousValue(value)
        setValueString(getValueString(value))
        // show children of last tier in value
        setCurrentTier(getNextGroupId(lastEntryInValue))
      } else {
        setCurrentTier(null)
        setInternalValue([])
        setPreviousValue([])
        setValueString('')
      }
    },
    [getValueString, getNextGroupId, isLeaf, value]
  )

  const internalOnChange = React.useCallback(
    function (selection: TierSelection) {
      setInternalValue(selection.value)
      setPreviousValue(selection.value)
      setValueString(getValueString(selection.value))
      setCurrentTier(getNextGroupId(selection.item))
      onChange(selection)
    },
    [getNextGroupId, getValueString, onChange]
  )

  const internalOnNavigate = React.useCallback(
    function (nextGroupId: Id, value: any[]) {
      setInternalValue(value)
      setCurrentTier(nextGroupId)
      onNavigate && onNavigate(nextGroupId, value)
    },
    [onNavigate]
  )

  const internalOnClear = React.useCallback(
    function (event: React.MouseEvent<HTMLButtonElement>) {
      setValueString('')
      setInternalValue([])
      setPreviousValue([])
      internalOnNavigate(null, [])
      onClear && onClear(event)
    },
    [internalOnNavigate, onClear]
  )

  const internalOnSearch = React.useCallback(
    function (event: any) {
      onSearch && onSearch(event)
    },
    [onSearch]
  )

  const internalBeforeHide = React.useCallback(
    function (e: Event) {
      const isEscapeKey =
        e instanceof KeyboardEvent && (e.key === 'Escape' || e.key === 'Esc')

      const isClickOutside =
        e instanceof MouseEvent &&
        !isEventSource({ current: tieredSelectMenuRef?.current?.el }, e)

      const lastValue = previousValue[previousValue.length - 1]

      if (valueString && (isClickOutside || isEscapeKey)) {
        setInternalValue(previousValue)
        setCurrentTier(getNextGroupId(lastValue))
      }
      return beforeHide && beforeHide(e)
    },
    [
      beforeHide,
      getNextGroupId,
      tieredSelectMenuRef,
      previousValue,
      valueString,
    ]
  )

  return (
    <OverlayTrigger
      autoFocus
      afterHide={afterHide}
      afterShow={afterShow}
      beforeHide={internalBeforeHide}
      beforeShow={(e: Event) => {
        return beforeShow && e.target !== clearRef.current && beforeShow(e)
      }}
      canFlip={true}
      hideKeys={hideKeys}
      overlay={
        <TieredSelectMenu
          currentTier={currentTier}
          options={options}
          loading={loading}
          menuRef={tieredSelectMenuRef}
          onNavigate={internalOnNavigate}
          onSearch={internalOnSearch}
          onChange={internalOnChange}
          value={internalValue}
          previousValue={previousValue}
          {...props}
          onKeyDown={stopProp}
        />
      }
      placement="bottom-left"
      ref={ref}
      trigger="click"
    >
      <Select.Button
        block={block}
        className={className}
        clearRef={clearRef}
        disabled={disabled}
        error={error}
        label={valueString}
        loading={loading}
        placeholder={I18n.t('placeholder', { scope: i18nScope })}
        onClear={internalOnClear}
        ref={tieredSelectButtonRef}
        tabIndex={tabIndex}
      >
        {valueString || I18n.t('placeholder', { scope: i18nScope })}
      </Select.Button>
    </OverlayTrigger>
  )
})
