import throttle from 'lodash.throttle'
import React from 'react'
import { AnchorNavigation } from '../AnchorNavigation'
import { useAnchorNavigationContext } from '../AnchorNavigation/AnchorNavigationProvider'
import { Grid as BaseGrid } from '../Grid/Grid'
import type { GridProps } from '../Grid/Grid.types'
import { TearsheetContext } from '../Tearsheet/Tearsheet'
import { useResize } from '../_hooks/Resize'
import { useScroll } from '../_hooks/Scroll'
import { useUpdateEffect } from '../_hooks/useUpdateEffect'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import type { DivAttributes, Props } from '../_utils/types'
import { pageLayoutRootDataAttr } from './PageLayout.constants'
import {
  animationDuration,
  StyledAside,
  StyledAsideFluidContainer,
  StyledAsidePanel,
  StyledBody,
  StyledContent,
  StyledFooter,
  StyledNavigation,
  StyledPageBanner,
  StyledPageBreadcrumbs,
  StyledPageContainer,
  StyledPageHeader,
  StyledPageMain,
  StyledPageTabs,
  StyledPageTitle,
} from './PageLayout.styles'
import type {
  PageAsideAnimationStatus,
  PageAsideProps,
  PageBodyNavigationProps,
  PageComponentProps,
  PageFooterProps,
  PageHeaderProps,
  PageMainProps,
} from './PageLayout.types'
import {
  getBottomOffsetForFooterUseCase,
  getGlobalBottomOffset,
  getGlobalTopOffset,
  getIsIntersectingVertically,
  getRightOffset,
  wait,
} from './PageLayout.utils'

const defaultAsideIsOpen = false

export const PageContext = React.createContext<{
  bodyRef: React.RefObject<HTMLDivElement>
  footerRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  navigationRef: React.RefObject<HTMLUListElement>
  aside: {
    open: () => Promise<void>
    close: () => Promise<void>
    isOpen: boolean
  }
}>({
  bodyRef: React.createRef(),
  footerRef: React.createRef(),
  containerRef: React.createRef(),
  navigationRef: React.createRef(),
  aside: {
    open: () => Promise.resolve(),
    close: () => Promise.resolve(),
    isOpen: defaultAsideIsOpen,
  },
})

// for some reason this does not appear in the deprecated in typedoc generated json, but Page.Row does
/**
 * @parent Page
 * @deprecated A wrapper around Grid, please use Grid instead. Page already sets
 * a default gutterX in context for children grid components.
 *
 * BEFORE `Page.Grid`, `Page.Row`, `Page.Column`
 *
 * AFTER `Grid` `Grid.Row` `Grid.Col`
 * @deprecatedSince 12
 */
export const Grid = ({ gutterX, ...props }: GridProps) => {
  return <BaseGrid gutterX={gutterX ?? 'lg'} {...props} />
}

/**
 * @parent Page
 * @deprecated A wrapper around Grid, please use Grid instead. Page already sets
 * a default gutterX in context for children grid components.
 *
 * BEFORE `Page.Row` AFTER `Grid.Row`
 * @deprecatedSince 12
 */
export const Row = BaseGrid.Row

/**
 * @parent Page
 * @deprecated A wrapper around Grid, please use Grid instead. Page already sets
 * a default gutterX in context for children grid components.
 *
 * BEFORE `Page.Column` AFTER `Grid.Col`
 * @deprecatedSince 12
 */
export const Column = BaseGrid.Col

const PageHeader = React.forwardRef<HTMLDivElement, PageHeaderProps>(
  function PageHeader({ transparent, ...props }, ref) {
    return <StyledPageHeader $transparent={transparent} {...props} ref={ref} />
  }
)

const PageBody = React.forwardRef<HTMLDivElement, PageComponentProps>(
  function PageBody({ children, ...props }, ref) {
    const { bodyRef } = React.useContext(PageContext)

    let navigation
    let content: React.ReactElement[] = []

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) return
      if (child.type === PageBodyNavigation) {
        navigation = child
      } else {
        content.push(child)
      }
    })
    if (!navigation) {
      return (
        <StyledBody
          children={children}
          {...props}
          ref={mergeRefs(ref, bodyRef)}
        />
      )
    }

    return (
      <StyledBody {...props} ref={mergeRefs(ref, bodyRef)}>
        <StyledNavigation>{navigation}</StyledNavigation>
        <StyledContent>{content}</StyledContent>
      </StyledBody>
    )
  }
)

export const PageBodyNavigation = React.forwardRef<
  HTMLUListElement,
  PageBodyNavigationProps
>(({ minSections = 2, ...props }, ref) => {
  const { navigationRef } = React.useContext(PageContext)
  const { sections, active, setActive } = useAnchorNavigationContext()
  const sectionsLength = props.sections?.length ?? sections.length

  if (sectionsLength < minSections) return null

  return (
    <AnchorNavigation
      ref={mergeRefs(ref, navigationRef)}
      sections={props.sections ?? sections}
      active={props.active ?? active}
      setActive={props.setActive ?? setActive}
      {...props}
    />
  )
})

const PageFooter = React.forwardRef<HTMLDivElement, PageFooterProps>(
  function PageFooter(props, ref) {
    const { bodyRef, footerRef, aside } = React.useContext(PageContext)
    const { addListener } = React.useContext(TearsheetContext)

    const [isIntersectingWithBody, setIsIntersectingWithBody] =
      React.useState(false)

    function calculateIntersections() {
      if (!footerRef.current || !bodyRef.current) {
        return
      }
      setIsIntersectingWithBody(
        getIsIntersectingVertically(bodyRef.current, footerRef.current)
      )
    }

    React.useEffect(() => {
      let tabWasPressed = false
      const onActiveElementChange = () => {
        if (
          tabWasPressed &&
          footerRef.current &&
          document.activeElement &&
          getIsIntersectingVertically(
            document.activeElement as HTMLElement,
            footerRef.current
          )
        ) {
          document.activeElement.scrollIntoView()
        }
      }

      const onKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'Tab') {
          tabWasPressed = true
          // setTimeout is added to the end of async queue
          // after all already attached eventListeners
          // `tabWasPressed` marked as `false` after all events
          // which can be triggered by pressing Tab
          setTimeout(() => {
            tabWasPressed = false
          }, 0)
        }
      }

      bodyRef.current?.addEventListener('focusin', onActiveElementChange)
      document.addEventListener('keydown', onKeyDown, true)

      return () => {
        bodyRef.current?.removeEventListener('focusin', onActiveElementChange)
        document.removeEventListener('keydown', onKeyDown, true)
      }
    }, [])

    React.useEffect(() => addListener('scroll', calculateIntersections), [])

    React.useEffect(calculateIntersections, [])

    useScroll({ onScroll: calculateIntersections })

    useResize({ onResize: calculateIntersections })

    return (
      <StyledFooter
        {...props}
        $isAsideOpen={aside.isOpen}
        $hasShadow={isIntersectingWithBody}
        data-sticky={isIntersectingWithBody ? 'sticky' : ''}
        ref={mergeRefs(ref, footerRef)}
      />
    )
  }
)

const PageAside = React.forwardRef<HTMLDivElement, PageAsideProps>(
  function PageAside({ children, open: controlledIsOpen, ...props }, ref) {
    const {
      aside: { isOpen: contextIsOpen, open, close },
    } = React.useContext(PageContext)
    const { placement: tearsheetPlacement, addListener: addTearsheetListener } =
      React.useContext(TearsheetContext)
    const { footerRef, containerRef } = React.useContext(PageContext)

    const innerRef = React.useRef<HTMLDivElement>(null)

    const [topOffset, setTopOffset] = React.useState<null | number>(null)
    const [bottomOffset, setBottomOffset] = React.useState(0)
    const [rightOffset, setRightOffset] = React.useState(0)

    const initialIsOpen = controlledIsOpen ?? contextIsOpen
    const [animationStatus, setAnimationStatus] =
      React.useState<PageAsideAnimationStatus>(
        initialIsOpen ? 'open' : 'closed'
      )
    const [isOpen, setIsOpen] = React.useState(initialIsOpen)

    const throttledToggle = React.useCallback(
      throttle(
        (isOpen) => {
          if (isOpen) {
            open()
          } else {
            close()
          }
        },
        // wait just a little longer than the animation duration to avoid odd blinking
        animationDuration.panel + 100,
        { trailing: true }
      ),
      []
    )

    React.useEffect(() => {
      throttledToggle(controlledIsOpen)
    }, [controlledIsOpen, throttledToggle])

    React.useEffect(() => {
      setIsOpen(contextIsOpen)
    }, [])

    useUpdateEffect(() => {
      ;(async () => {
        if (contextIsOpen) {
          setIsOpen(true)

          // allow browsers to recalculate the layout
          await wait(0)

          setAnimationStatus('opening')
          await wait(animationDuration.panel)
          setAnimationStatus('open')
          return
        }

        setAnimationStatus('closing')
        await wait(animationDuration.panel)
        setAnimationStatus('closed')
        setIsOpen(false)
      })()
    }, [contextIsOpen])

    function calculateOffsets() {
      calculateHorizontalOffsets()
      calculateVerticalOffsets()
    }

    function calculateHorizontalOffsets() {
      if (!containerRef.current) {
        return
      }

      setRightOffset(getRightOffset(containerRef.current))
    }

    function calculateVerticalOffsets() {
      if (!innerRef.current || !containerRef.current) {
        return
      }

      const globalTopOffset = getGlobalTopOffset(containerRef.current)
      setTopOffset(globalTopOffset)

      const globalBottomOffset = getGlobalBottomOffset(containerRef.current)
      setBottomOffset(globalBottomOffset)

      if (!footerRef.current) {
        return
      }

      const bottomOffset = getBottomOffsetForFooterUseCase(
        footerRef.current,
        globalBottomOffset
      )
      if (bottomOffset !== null) {
        setBottomOffset(bottomOffset)
      }
    }

    useScroll({ onScroll: calculateOffsets })

    useResize({ onResize: calculateOffsets })

    React.useEffect(() => {
      if (tearsheetPlacement) {
        return addTearsheetListener('afterShow', calculateOffsets)
      }

      calculateOffsets()
    }, [])

    useUpdateEffect(calculateOffsets, [contextIsOpen, animationStatus])

    const asideOpen = ['open', 'opening'].includes(animationStatus)
    const asideClosed = ['closed', 'closing'].includes(animationStatus)

    return isOpen ? (
      <StyledAside
        {...props}
        $open={asideOpen}
        $closed={asideClosed}
        ref={mergeRefs(ref, innerRef, calculateOffsets)}
      >
        <StyledAsideFluidContainer $open={asideOpen} $closed={asideClosed}>
          <StyledAsidePanel
            $offsetTop={topOffset}
            $rightOffset={rightOffset}
            $minusHeight={(topOffset ?? 0) + bottomOffset}
            $closing={animationStatus === 'closing'}
            $opening={animationStatus === 'opening'}
            $closed={animationStatus === 'closed'}
            $altAnimation={tearsheetPlacement === 'left'}
          >
            {children}
          </StyledAsidePanel>
        </StyledAsideFluidContainer>
      </StyledAside>
    ) : null
  }
)

const PageContainer = React.forwardRef<HTMLDivElement, DivAttributes>(
  function PageContainer(props, ref) {
    const [isAsideOpen, setIsAsideOpen] = React.useState(defaultAsideIsOpen)

    const openAside = () => {
      setIsAsideOpen(true)
      return wait(animationDuration.panel)
    }
    const closeAside = () => {
      setIsAsideOpen(false)
      return wait(animationDuration.panel)
    }

    const containerRef = React.useRef<HTMLDivElement>(null)
    const bodyRef = React.useRef<HTMLDivElement>(null)
    const navigationRef = React.useRef<HTMLUListElement>(null)
    const footerRef = React.useRef<HTMLDivElement>(null)

    return (
      <PageContext.Provider
        value={{
          bodyRef,
          footerRef,
          containerRef,
          navigationRef,
          aside: {
            open: openAside,
            close: closeAside,
            isOpen: isAsideOpen,
          },
        }}
      >
        <StyledPageContainer
          data-core-react="page"
          {...props}
          ref={mergeRefs(ref, containerRef)}
        />
      </PageContext.Provider>
    )
  }
)

const PageMain = React.forwardRef<HTMLDivElement, PageMainProps>(
  function PageMain(props, ref) {
    const { placement: tearsheetPlacement } = React.useContext(TearsheetContext)
    const attrs = { [pageLayoutRootDataAttr]: true }
    return (
      <Grid gutterX="lg">
        <StyledPageMain
          {...props}
          {...attrs}
          ref={ref}
          $tearsheetPlacement={tearsheetPlacement}
        />
      </Grid>
    )
  }
)

const PageBreadcrumbs = React.forwardRef<HTMLDivElement, Props>(
  function PageBreadcrumbs(props, ref) {
    return <StyledPageBreadcrumbs ref={ref} {...props} />
  }
)

const PageBanner = React.forwardRef<HTMLDivElement, Props>(function PageBanner(
  props,
  ref
) {
  return <StyledPageBanner ref={ref} {...props} />
})

const PageTitle = React.forwardRef<HTMLDivElement, Props>(function PageTitle(
  props,
  ref
) {
  return <StyledPageTitle ref={ref} {...props} />
})

const PageTabs = React.forwardRef<HTMLDivElement, Props>(function PageTabs(
  props,
  ref
) {
  return <StyledPageTabs ref={ref} {...props} />
})

Grid.displayName = 'Page.Grid'
PageHeader.displayName = 'Page.Header'
PageBanner.displayName = 'Page.Banner'
PageBreadcrumbs.displayName = 'Page.Breadcrumbs'
PageTitle.displayName = 'Page.Title'
PageTabs.displayName = 'Page.Tabs'
PageBody.displayName = 'Page.Body'
PageFooter.displayName = 'Page.Footer'
PageAside.displayName = 'Page.Aside'
PageContainer.displayName = 'Page'
PageMain.displayName = 'Page.Main'

/**
  Building block elements for layout that should be used to build a common skeleton of a page.
  - Page
    - Page.Main
      - Page.Header
        - Page.Breadcrumbs
          - Breadcrumbs
        - Page.Banner
          - Banner
        - Page.Title
          - Title or H1
        - Page.Tabs
          - Tabs
      - Page.Body
      - Page.Footer
    - Page.Aside
Other helpers, such as Page.Grid, Page.Row and Page.Column are thin wrappers over Grid components and share the same API.
 */
export const Page = addSubcomponents(
  {
    Header: PageHeader,
    Banner: PageBanner,
    Breadcrumbs: PageBreadcrumbs,
    Title: PageTitle,
    Tabs: PageTabs,
    Footer: PageFooter,
    Body: PageBody,
    Main: PageMain,
    Aside: PageAside,

    // Grid utilities
    /**
     * @deprecated A wrapper around Grid, please use Grid instead. Page already sets
     * a default gutterX in context for children grid components.
     *
     * BEFORE `Page.Grid` AFTER `Grid` (or `Grid gutterX="lg"`)
     * @deprecatedSince 12
     */
    Grid,
    /**
     * @deprecated A wrapper around Grid, please use Grid instead. Page already sets
     * a default gutterX in context for children grid components.
     *
     * BEFORE `Page.Column` AFTER `Grid.Col`
     * @deprecatedSince 12
     */
    Column,
    /**
     * @deprecated A wrapper around Grid, please use Grid instead. Page already sets
     * a default gutterX in context for children grid components.
     *
     * BEFORE `Page.Row` AFTER `Grid.Row`
     * @deprecatedSince 12
     */
    Row,
  },
  PageContainer
)
