import { prop, uniqBy } from 'ramda'
import React from 'react'
import { EmptyState } from '../../EmptyState/EmptyState'
import { useFileSelectContext } from '../../FileSelect/FileSelect'
import { Search } from '../../Search/Search'
import { Spinner } from '../../Spinner/Spinner'
import { ThumbnailGrid } from '../../ThumbnailGrid/ThumbnailGrid'
import type { ThumbnailGridItem } from '../../ThumbnailGrid/ThumbnailGrid.types'
import { useI18nContext } from '../../_hooks/I18n'
import { isFunction } from '../../_utils/isFunction'
import type { DivAttributes } from '../../_utils/types'
import {
  contentHeight,
  StyledEmptyState,
  StyledEmptyStateWrapper,
  StyledGrid,
  StyledSearchWrapper,
  StyledSpinnerWrapper,
  StyledToolbar,
} from './GridSource.styles'
import type {
  GetGroupId,
  GetId,
  GetThumbnailLabel,
  GetThumbnailSource,
  GetThumnbnailName,
  GridItem,
  GridSourceProps,
  SearchBarProps,
} from './GridSource.types'

const defaultGetId = prop('id') as GetId
const defaultGetThumbnailName = prop('name') as GetThumnbnailName
const defaultGetThumbnailSource = prop('src') as GetThumbnailSource
const defaultGetThumbnailLabel = prop('label') as GetThumbnailLabel
const defaultGetGroupId = prop('groupId') as GetGroupId

export const GridSource = React.forwardRef<
  HTMLDivElement,
  GridSourceProps & DivAttributes
>(function GridSource(
  {
    sourceId,
    title,
    icon,
    items,
    groups,
    onSearch,
    emptyState = {},
    searchQuery: controlledSearchQuery,
    children,
    isLoadingMore = false,
    isLoading = false,
    onScrollBottom,
    getId = defaultGetId,
    getThumbnailName = defaultGetThumbnailName,
    getThumbnailSource = defaultGetThumbnailSource,
    getThumbnailLabel = defaultGetThumbnailLabel,
    getGroupId = defaultGetGroupId,
    qa,
    ...props
  },
  ref
) {
  const I18n = useI18nContext()
  const [internalSearchQuery, setInternalSearchQuery] = React.useState('')
  const [value, setValue] = React.useState<GridItem[]>([])
  const { maxFileNumber, currentSource, register, onChange, onResetValue } =
    useFileSelectContext()

  const maxNumberIsInfinity = React.useMemo(
    () => !Number.isFinite(maxFileNumber),
    [maxFileNumber]
  )

  const isUsingControlledSearch =
    isFunction(onSearch) && typeof controlledSearchQuery !== 'undefined'

  React.useEffect(() => {
    const unregister = register(sourceId, {
      title,
      icon,
    })
    const unsubscribe = onResetValue(() => {
      setInternalSearchQuery('')
      setValue([])
    })
    return () => {
      unsubscribe()
      unregister()
    }
  }, [])

  const ensureNameAndId = (item: GridItem) => {
    const id = getId(item)
    const name = getThumbnailName(item)

    return {
      ...item,
      name,
      id,
    }
  }

  React.useEffect(() => {
    onChange(sourceId, value.map(ensureNameAndId))
  }, [value])

  const gridRef = React.useRef<HTMLDivElement>(null)
  const [isGridScrolled, setIsGridScrolled] = React.useState(false)

  const getThumbnailGridItem = React.useCallback(
    (item: GridItem) => ({
      id: getId(item),
      name: getThumbnailName(item),
      src: getThumbnailSource(item),
      label: getThumbnailLabel(item),
      groupId: getGroupId(item),
    }),
    [getId, getThumbnailName, getThumbnailLabel, getThumbnailSource, getGroupId]
  )

  const thumbnailGridItems = React.useMemo(
    () => items.map(getThumbnailGridItem),
    [items, getThumbnailGridItem]
  )

  const selectedThumbnailGridItems = value.map(getThumbnailGridItem)

  const allVisibleItems: ThumbnailGridItem[] = React.useMemo(
    () =>
      internalSearchQuery && !isFunction(onSearch)
        ? thumbnailGridItems.filter((item) => {
            const name = getThumbnailName?.(item)

            if (!name) {
              return true
            }

            const text = name.toLowerCase()
            const q = internalSearchQuery.toLowerCase()

            return text.includes(q)
          })
        : thumbnailGridItems,
    [thumbnailGridItems, internalSearchQuery, onSearch, getThumbnailName]
  )

  const allVisibleItemsIds = React.useMemo(
    () => allVisibleItems.map((item: ThumbnailGridItem) => item.id),
    [allVisibleItems]
  )

  const fetchData = React.useCallback(async () => {
    if (!isFunction(onScrollBottom)) {
      return
    }

    try {
      await onScrollBottom()
    } catch (err) {
      console.warn('Warning!: "onScrollBottom" request was ended with an error')
    }
  }, [onScrollBottom])

  if (currentSource !== sourceId) {
    return null
  }

  const hasChildren = React.Children.toArray(children).length > 0

  const onSelect = (selectedItem: ThumbnailGridItem) => {
    const origin = items.find(
      (item) => getId(item) === selectedItem.id
    ) as GridItem

    setValue([...value, origin])
  }

  const onDeselect = (removedItem: ThumbnailGridItem) => {
    setValue((prev) => prev.filter((item) => getId(item) !== removedItem.id))
  }

  const onSelectAll = () => {
    const origins: GridItem[] = items.filter((item: GridItem) => {
      return allVisibleItemsIds.includes(getId(item))
    })

    setValue(uniqBy(getId, [...value, ...origins]))
  }

  const onDeselectAll = () => {
    const filteredValues = value.filter(
      (item: GridItem) => !allVisibleItemsIds.includes(getId(item))
    )

    setValue([...filteredValues])
  }

  const onSelectAllGroup = (groupId: string) => {
    const allVisibleGroupItemsIds = allVisibleItems
      .filter((item: ThumbnailGridItem) => String(item.groupId) === groupId)
      .map((item: ThumbnailGridItem) => item.id)

    const origins: GridItem[] = items.filter((item: GridItem) => {
      return allVisibleGroupItemsIds.includes(getId(item))
    })

    setValue(uniqBy(getId, [...value, ...origins]))
  }

  const onDeselectAllGroup = (groupId: string) => {
    const allVisibleGroupItemsIds = allVisibleItems
      .filter((item: ThumbnailGridItem) => String(item.groupId) === groupId)
      .map((item: ThumbnailGridItem) => item.id)

    const filteredValues: GridItem[] = value.filter(
      (item: GridItem) => !allVisibleGroupItemsIds.includes(getId(item))
    )

    setValue([...filteredValues])
  }

  const searchQuery = isUsingControlledSearch
    ? (controlledSearchQuery as string)
    : internalSearchQuery

  const toolbar = (
    <StyledToolbar $alignRight={!hasChildren} $hasScrollShadow={isGridScrolled}>
      {children}
      <SearchBar
        query={searchQuery}
        onChange={(e) => {
          const query = e.target.value
          onSearch?.(query)
          if (!isUsingControlledSearch) {
            setInternalSearchQuery(query)
          }
        }}
      />
    </StyledToolbar>
  )

  const emptyStateContent = (
    <StyledEmptyStateWrapper>
      <StyledEmptyState compact={true}>
        {searchQuery ? (
          <>
            <EmptyState.NoResults />
            <EmptyState.Title>
              {I18n.t('core.fileExplorer.emptySearchResults')}
            </EmptyState.Title>
          </>
        ) : (
          <>
            {emptyState.image ? (
              <EmptyState.Image src={emptyState.image} />
            ) : (
              <EmptyState.NoItemsWithoutCreatePermissions />
            )}
            <EmptyState.Title>
              {emptyState.text ?? I18n.t('core.fileExplorer.noItems')}
            </EmptyState.Title>
          </>
        )}
      </StyledEmptyState>
    </StyledEmptyStateWrapper>
  )

  const gridContent = (
    <ThumbnailGrid
      qa={{ grid: qa?.grid }}
      selected={selectedThumbnailGridItems}
      items={allVisibleItems}
      onSelect={onSelect}
      onDeselect={onDeselect}
      disableSelection={{
        value: maxFileNumber < 1,
        tooltip: I18n.t('core.fileExplorer.maxNumberOfFilesSelected'),
      }}
      gridHeight={contentHeight}
      title={`${title} (${allVisibleItems.length})`}
      groups={groups}
      ref={gridRef}
      onScroll={(e) => {
        setIsGridScrolled((e.target as HTMLDivElement).scrollTop !== 0)
      }}
      isFetching={isLoadingMore}
      loadMoreItems={fetchData}
      allowSelectAll={maxNumberIsInfinity}
      onSelectAll={onSelectAll}
      onDeselectAll={onDeselectAll}
      onSelectAllGroup={onSelectAllGroup}
      onDeselectAllGroup={onDeselectAllGroup}
    />
  )

  const spinner = (
    <StyledSpinnerWrapper>
      <Spinner />
    </StyledSpinnerWrapper>
  )

  const content = isLoading
    ? spinner
    : allVisibleItems.length
    ? gridContent
    : emptyStateContent

  return (
    <StyledGrid ref={ref} $isEmpty={!allVisibleItems.length} {...props}>
      {toolbar}
      {content}
    </StyledGrid>
  )
})

function SearchBar({ onChange, query, ...props }: SearchBarProps) {
  const I18n = useI18nContext()
  return (
    <StyledSearchWrapper>
      <Search
        placeholder={I18n.t('core.fileExplorer.searchPlaceholder')}
        onChange={onChange}
        value={query}
        {...props}
      />
    </StyledSearchWrapper>
  )
}
