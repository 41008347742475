import { Clear } from '@procore/core-icons'
import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type {
  ButtonAttributes,
  DivAttributes,
  Props,
  SpanAttributes,
} from '../_utils/types'
import {
  StyledContent,
  StyledContentLabel,
  StyledFilterRemoveButton,
  StyledFilterToken,
  StyledLabel,
} from './FilterToken.styles'
import type { FilterTokenProps } from './FilterToken.types'

const Parent = React.forwardRef<
  HTMLDivElement,
  DivAttributes & FilterTokenProps
>(({ focused = false, ...props }, ref) => (
  <StyledFilterToken $focused={focused} ref={ref} {...props} />
))

const Remove = React.forwardRef<HTMLButtonElement, ButtonAttributes & Props>(
  ({ children, disabled, ...props }, ref) => {
    const i18n = useI18nContext()

    return (
      <StyledFilterRemoveButton
        $disabled={disabled ?? false}
        aria-label={i18n.t('core.token.remove')}
        tabIndex={0}
        type="button"
        ref={ref}
        {...props}
      >
        <StyledContent>
          <Clear data-icon-prop="true" />
          {React.Children.map(children, (child) => (
            <StyledContentLabel>{child}</StyledContentLabel>
          ))}
        </StyledContent>
      </StyledFilterRemoveButton>
    )
  }
)

const Label = React.forwardRef<HTMLSpanElement, SpanAttributes & Props>(
  (props, ref) => <StyledLabel ref={ref} {...props} />
)

Parent.displayName = 'FilterToken'
Remove.displayName = 'FilterToken.Remove'
Label.displayName = 'FilterToken.Label'

export const FilterToken = addSubcomponents(
  {
    Label,
    Remove,
  },
  Parent
)
