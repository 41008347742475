import { Cog } from '@procore/core-icons/dist'
import React from 'react'
import { LinkButton } from '../Button'
import { DetailPage, DetailPageViewContext } from '../DetailPage'
import { Page } from '../PageLayout'
import { Tooltip } from '../Tooltip'
import { useI18nContext } from '../_hooks/I18n'
import { colors } from '../_styles/colors'
import { addSubcomponents } from '../_utils/addSubcomponents'
import type { Props } from '../_utils/types'
import {
  StyledTitleInner,
  StyledTitleOuter,
  StyledToolLandingPageBodyContent,
} from './ToolLandingPage.styles'
import type {
  ToolLandingPageProps,
  ToolLandingPageTitleProps,
} from './ToolLandingPage.types'

export const ToolLandingPage_ = React.forwardRef<
  HTMLDivElement,
  ToolLandingPageProps
>(({ children, ...props }, ref) => {
  return (
    <DetailPageViewContext.Provider
      value={{ width: 'xl', hasNavigation: false }}
    >
      <Page ref={ref} {...props} data-core-react="tool-landing-page">
        {children}
      </Page>
    </DetailPageViewContext.Provider>
  )
})

export const Title = React.forwardRef<
  HTMLDivElement,
  ToolLandingPageTitleProps
>(({ children, settingsHref, ...props }, ref) => {
  const i18n = useI18nContext()

  return (
    <StyledTitleOuter ref={ref} {...props}>
      {settingsHref && (
        <Tooltip
          overlay={i18n.t('core.toolHeader.settingsAction')}
          trigger={['hover', 'focus']}
        >
          <LinkButton
            aria-label={i18n.t('core.toolHeader.settingsAction')}
            role="link"
            icon={<Cog color={colors.orange50} />}
            href={settingsHref}
            variant="tertiary"
          />
        </Tooltip>
      )}
      <StyledTitleInner>{children}</StyledTitleInner>
    </StyledTitleOuter>
  )
})

export const Body = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => (
    <DetailPage.Body ref={ref} {...props}>
      <StyledToolLandingPageBodyContent>
        {children}
      </StyledToolLandingPageBodyContent>
    </DetailPage.Body>
  )
)

/**
 * A list page is basically a copy of SettingsPage/DetailPage. However, it has some differences.
 * ToolLandingPage does not have Breadcrumbs, nested sections (although, we may need them in the future), and Footer.
 *
 * @since 12.9.0
 *
 * @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-toollandingpage--demo)
 *
 * @see [Design Guidelines](https://design.procore.com/tool-landing-layout)
 */

export const ToolLandingPage = addSubcomponents(
  {
    Main: Page.Main,
    Header: Page.Header,
    Banner: Page.Banner,
    Title,
    Tabs: Page.Tabs,
    Body,
    Aside: Page.Aside,
  },
  ToolLandingPage_
)

ToolLandingPage_.displayName = 'ToolLandingPage'
