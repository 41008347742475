import { CaretDown, CaretUp, EllipsisVertical } from '@procore/core-icons/dist'
import classNames from 'classnames'
import React from 'react'
import {
  MenuImperative,
  useMenuImperativeControlNavigation,
} from '../MenuImperative/MenuImperative'
import type { MenuRef, Selection } from '../MenuImperative/MenuImperative.types'
import {
  OverlayTrigger,
  useOverlayTriggerContext,
} from '../OverlayTrigger/OverlayTrigger'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import {
  StyledDropdownButton,
  StyledDropdownMenu,
  StyledDropdownSpinner,
} from './Dropdown.styles'
import type {
  ConsumerProps,
  DropdownButtonProps,
  DropdownItemProps,
  DropdownMenuProps,
  DropdownProps,
} from './Dropdown.types'

function noop() {}
function isFunction(obj: any) {
  return typeof obj === 'function'
}
function isOpen({ open = false }) {
  return open
}

const Item = React.forwardRef<HTMLDivElement, DropdownItemProps>(function Item(
  { restoreFocus = false, ...props },
  ref
) {
  return (
    <MenuImperative.Item ref={ref} restoreFocus={restoreFocus} {...props} />
  )
}) as typeof MenuImperative.Item

export const DropdownButton = React.forwardRef<
  HTMLButtonElement,
  DropdownButtonProps
>(function DropdownButton(
  {
    children,
    className,
    arrow = false,
    loading = false,
    open = false,
    focus = false,
    variant = 'secondary',
    ...props
  },
  ref
) {
  const caret =
    arrow && (open ? <CaretUp size="sm" /> : <CaretDown size="sm" />)

  const icon = loading ? (
    <StyledDropdownSpinner
      size="xs"
      variant={variant === 'primary' ? 'light' : 'default'}
    />
  ) : (
    caret
  )

  return (
    <StyledDropdownButton
      {...props}
      className={classNames(className, {
        focus: isFunction(focus) ? (focus as Function)({ open }) : focus,
      })}
      aria-busy={loading || undefined}
      iconRight={icon}
      $loading={loading}
      variant={variant}
      ref={ref}
    >
      {children}
    </StyledDropdownButton>
  )
})

const DropdownMenu = React.forwardRef<HTMLDivElement, DropdownMenuProps>(
  function DropdownMenu(
    {
      children,
      footer,
      i18nScope,
      menuRef,
      onSearch: onSearch_,
      onSelect: onSelect_ = noop,
      id,
    },
    ref
  ) {
    const overlayTriggerContext = useOverlayTriggerContext()

    const [search, setSearch] = React.useState('')
    const { menuProps, menuNavigationTriggerProps } =
      useMenuImperativeControlNavigation(menuRef, Boolean(onSearch_), {
        menuId: id,
      })

    function onSelect(selection: Selection) {
      overlayTriggerContext.hide({ restoreFocus: selection.restoreFocus })

      onSelect_(selection)
    }

    function onSearch(e: React.ChangeEvent<HTMLInputElement>) {
      setSearch(e.target.value)

      onSearch_?.(e)
    }

    React.useEffect(
      function () {
        menuRef.current?.highlightFirst()
      },
      [menuRef, search]
    )

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      // This is disconnected from the OverlayTrigger closing on Escape.
      // Closing a dropdown with Escape will not close a Modal, because stopP.
      // It is called only once because the menu component is unmounted.
      // The next Escape will propagate.
      if (e.key === 'Escape' || e.key === 'Esc') {
        // only let parent listeners (modal, overlay) know when empty
        e.stopPropagation()
      }
    }

    return (
      <StyledDropdownMenu ref={ref} shadowStrength={2}>
        <MenuImperative
          {...menuProps}
          ref={menuRef}
          onKeyDown={onKeyDown}
          onSelect={onSelect}
          role="menu"
        >
          {onSearch_ && (
            <MenuImperative.Search
              {...menuNavigationTriggerProps}
              onChange={onSearch}
              i18nScope={i18nScope}
            />
          )}
          <MenuImperative.Options>{children}</MenuImperative.Options>
          {footer && <MenuImperative.Footer>{footer}</MenuImperative.Footer>}
        </MenuImperative>
      </StyledDropdownMenu>
    )
  }
)

const Dropdown_ = React.forwardRef<HTMLButtonElement, DropdownProps>(
  function Dropdown(
    {
      afterHide: afterHide_,
      afterShow,
      beforeHide,
      beforeShow,
      children,
      error,
      footer,
      i18nScope = 'core.dropdown',
      icon,
      label,
      onKeyDown = noop,
      onSearch,
      onSelect = noop,
      placement = 'bottom-left',
      restoreFocusOnHide = false,
      variant = 'secondary',
      hasTabbableElements = false,
      ...props
    },
    ref
  ) {
    const menuRef = React.useRef<MenuRef>(null)
    const buttonRef = React.useRef<HTMLButtonElement>(null)
    const isDialog = Boolean(onSearch) || hasTabbableElements

    function afterHide(e: Event | undefined) {
      // @ts-expect-error
      if (e?.key === 'Escape' || e?.restoreFocus) {
        buttonRef.current?.focus()
      }

      afterHide_?.(e)

      onSearch?.({
        target: { value: '' },
      } as React.ChangeEvent<HTMLInputElement>)
    }

    const I18n = useI18nContext()

    const ariaLabel =
      props['aria-label'] ||
      label ||
      I18n.t(`core.dropdown.${props.loading ? 'loading' : 'moreOptions'}`)

    return (
      <OverlayTrigger
        role={isDialog ? 'dialog' : 'menu'}
        passA11yPropsToOverlay={!isDialog}
        autoFocus
        afterHide={afterHide}
        afterShow={afterShow}
        beforeHide={beforeHide}
        beforeShow={beforeShow}
        overlay={
          <DropdownMenu
            footer={footer}
            i18nScope={i18nScope}
            menuRef={menuRef}
            onSearch={onSearch}
            onSelect={onSelect}
          >
            {children}
          </DropdownMenu>
        }
        placement={placement}
        ref={mergeRefs(ref, buttonRef)}
        restoreFocusOnHide={restoreFocusOnHide}
      >
        <DropdownButton
          {...props}
          aria-label={ariaLabel}
          arrow={label !== undefined}
          focus={onSearch ? false : isOpen}
          icon={
            props.loading && !label ? undefined : !icon && !label ? (
              <EllipsisVertical />
            ) : (
              icon
            )
          }
          onKeyDown={onKeyDown}
          variant={variant}
        >
          {label}
        </DropdownButton>
      </OverlayTrigger>
    )
  }
)

export const useDropdownContext = useOverlayTriggerContext

export function DropdownConsumer({ children }: ConsumerProps) {
  return children(useDropdownContext())
}

Dropdown_.displayName = 'Dropdown'

Item.displayName = 'Dropdown.Item'

/**

 Dropdowns display a list of actions in a menu that opens and closes. We use
 dropdowns most commonly to trigger an action or to redirect the user to
 a new page or modal.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-dropdown--demo)

 @see [Design Guidelines](https://design.procore.com/dropdown)

*/
export const Dropdown = addSubcomponents(
  {
    Item,
    Group: MenuImperative.Group,
  },
  Dropdown_
)
