import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "change-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#change-log",
        "aria-label": "change log permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Change Log`}</h1>
    <h2 {...{
      "id": "1231",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1231",
        "aria-label": "1231 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.3.1`}</h2>
    <h3 {...{
      "id": "patch-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes",
        "aria-label": "patch changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`864ac9d: Update dependency package versions`}</li>
    </ul>
    <h2 {...{
      "id": "1230",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1230",
        "aria-label": "1230 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.3.0`}</h2>
    <h3 {...{
      "id": "minor-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes",
        "aria-label": "minor changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`fd00589dce: Add MyOpenItems icon`}</li>
    </ul>
    <h2 {...{
      "id": "1220",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1220",
        "aria-label": "1220 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.2.0`}</h2>
    <h3 {...{
      "id": "minor-changes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-1",
        "aria-label": "minor changes 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`9e378e94cf: Added: Copilot, CopilotBranded, Insights, Notestack, Qualifications, Webhook, Workflows
Updated: Export, File, ThumbDown`}</li>
    </ul>
    <h2 {...{
      "id": "1210",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1210",
        "aria-label": "1210 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.1.0`}</h2>
    <h3 {...{
      "id": "minor-changes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-2",
        "aria-label": "minor changes 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`716e98717: mark package as side-effect free`}</li>
    </ul>
    <h2 {...{
      "id": "1200",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1200",
        "aria-label": "1200 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.0.0`}</h2>
    <h3 {...{
      "id": "major-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#major-changes",
        "aria-label": "major changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Major Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`a147cbdd8a: - Adds `}<inlineCode parentName="p">{`Crop`}</inlineCode>{` icon.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Minor adjustments to `}<inlineCode parentName="li">{`LineFreehand`}</inlineCode>{` icon.`}</li>
          <li parentName="ul">{`Minor adjustments to `}<inlineCode parentName="li">{`Stopwatch`}</inlineCode>{` icon.`}</li>
        </ul>
        <p parentName="li"><strong parentName="p">{`Breaking Changes`}</strong></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Flowchart`}</inlineCode>{` has been renamed `}<inlineCode parentName="li">{`Workflow`}</inlineCode>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "1120",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1120",
        "aria-label": "1120 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11.2.0`}</h2>
    <h3 {...{
      "id": "minor-changes-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#minor-changes-3",
        "aria-label": "minor changes 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul">{`a1e4b45cbd: Add new icons`}</li>
    </ul>
    <h2 {...{
      "id": "1116",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1116",
        "aria-label": "1116 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11.1.6`}</h2>
    <h3 {...{
      "id": "patch-changes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-1",
        "aria-label": "patch changes 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`c97b670184: Removes the node engines requirement.`}</li>
    </ul>
    <h2 {...{
      "id": "1115-2023-04-21",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1115-2023-04-21",
        "aria-label": "1115 2023 04 21 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11.1.5 (2023-04-21)`}</h2>
    <h3 {...{
      "id": "patch-changes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patch-changes-2",
        "aria-label": "patch changes 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`35bafeeefd: Version bumps`}</li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2 {...{
      "id": "1114-2023-03-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1114-2023-03-08",
        "aria-label": "1114 2023 03 08 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.1.4-rc.0...@procore/core-icons@11.1.4",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.1.4`}</a>{` (2023-03-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1113-2023-02-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1113-2023-02-28",
        "aria-label": "1113 2023 02 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.1.3-rc.1...@procore/core-icons@11.1.3",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.1.3`}</a>{` (2023-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1112-2023-02-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1112-2023-02-11",
        "aria-label": "1112 2023 02 11 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.1.2-rc.0...@procore/core-icons@11.1.2",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.1.2`}</a>{` (2023-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1111-2023-01-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1111-2023-01-28",
        "aria-label": "1111 2023 01 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.1.1-rc.1...@procore/core-icons@11.1.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.1.1`}</a>{` (2023-01-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h1 {...{
      "id": "1110-2023-01-13",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1110-2023-01-13",
        "aria-label": "1110 2023 01 13 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h1" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.1.0-rc.0...@procore/core-icons@11.1.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.1.0`}</a>{` (2023-01-13)`}</h1>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1106-2022-11-29",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1106-2022-11-29",
        "aria-label": "1106 2022 11 29 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.6-rc.1...@procore/core-icons@11.0.6",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.6`}</a>{` (2022-11-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1105-2022-09-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1105-2022-09-08",
        "aria-label": "1105 2022 09 08 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.5-rc.0...@procore/core-icons@11.0.5",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.5`}</a>{` (2022-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1104-2022-08-03",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1104-2022-08-03",
        "aria-label": "1104 2022 08 03 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.4-rc.1...@procore/core-icons@11.0.4",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.4`}</a>{` (2022-08-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1103-2022-07-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1103-2022-07-20",
        "aria-label": "1103 2022 07 20 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.3-rc.3...@procore/core-icons@11.0.3",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.3`}</a>{` (2022-07-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1102-2022-06-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1102-2022-06-08",
        "aria-label": "1102 2022 06 08 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.2-rc.0...@procore/core-icons@11.0.2",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.2`}</a>{` (2022-06-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1101-2022-06-07",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1101-2022-06-07",
        "aria-label": "1101 2022 06 07 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.1-rc.0...@procore/core-icons@11.0.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.1`}</a>{` (2022-06-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h1 {...{
      "id": "1100-2022-05-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1100-2022-05-20",
        "aria-label": "1100 2022 05 20 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h1" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@11.0.0-rc.0...@procore/core-icons@11.0.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.0`}</a>{` (2022-05-20)`}</h1>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1100-2022-05-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1100-2022-05-16",
        "aria-label": "1100 2022 05 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@10.30.0...@procore/core-icons@11.0.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`11.0.0`}</a>{` (2022-05-16)`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://core.procore.com/11.0.0/web/releases/migration-guides/core-icons/v10-to-v11/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`v11 migration guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">{`The package root now points to `}<inlineCode parentName="li">{`dist/index.js`}</inlineCode>{`, so `}<inlineCode parentName="li">{`import icons from '@procore/core-icons/dist'`}</inlineCode>{` can now be replaces with `}<inlineCode parentName="li">{`import icons from '@procore/core-icons'`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Delete tool specific `}<inlineCode parentName="li">{`Workflows`}</inlineCode>{`, probably use `}<inlineCode parentName="li">{`Flowchart`}</inlineCode></li>
      <li parentName="ul">{`Rename/Delete `}<inlineCode parentName="li">{`Timeline`}</inlineCode>{` to `}<inlineCode parentName="li">{`Gantt`}</inlineCode></li>
      <li parentName="ul">{`Rename/Delete Icon `}<inlineCode parentName="li">{`CurrencyListUSA`}</inlineCode>{` to `}<inlineCode parentName="li">{`ListCurrencyUSA`}</inlineCode>{` to align with the naming pattern of list icons.`}</li>
    </ul>
    <h2 {...{
      "id": "10282-2022-05-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10282-2022-05-16",
        "aria-label": "10282 2022 05 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@10.28.2-rc.1...@procore/core-icons@10.28.2",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.28.2`}</a>{` (2022-05-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10281-2022-05-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10281-2022-05-10",
        "aria-label": "10281 2022 05 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@10.28.1-rc.0...@procore/core-icons@10.28.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.28.1`}</a>{` (2022-05-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10280-2022-04-08",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10280-2022-04-08",
        "aria-label": "10280 2022 04 08 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@10.27.2...@procore/core-icons@10.28.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.28.0`}</a>{` (2022-04-08)`}</h2>
    <h3 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Update icons, adds NotepadPencil, NotepadPeople, RibbonOff, Timeline (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/10462",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#10462`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10271-2022-03-30",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10271-2022-03-30",
        "aria-label": "10271 2022 03 30 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/@procore/core-icons@10.27.0...@procore/core-icons@10.27.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.27.1`}</a>{` (2022-03-30)`}</h2>
    <h3 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Core Icons webpack5 drop file-loader in favor of asset modules (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/10334",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#10334`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10270-2022-03-28",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10270-2022-03-28",
        "aria-label": "10270 2022 03 28 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.25.0...@procore/core-icons@10.27.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.27.0`}</a>{` (2022-03-28)`}</h2>
    <ul>
      <li parentName="ul">{`DO NOT USE. Bad publish for CSS icons. Contains broken font assets`}</li>
    </ul>
    <h1 {...{
      "id": "10260",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#10260",
        "aria-label": "10260 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10.26.0`}</h1>
    <ul>
      <li parentName="ul">{`DO NOT USE. Bad publish.`}</li>
    </ul>
    <h2 {...{
      "id": "10250-2022-03-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10250-2022-03-01",
        "aria-label": "10250 2022 03 01 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.24.0...v10.25.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.25.0`}</a>{` (2022-03-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10240-2022-02-25",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10240-2022-02-25",
        "aria-label": "10240 2022 02 25 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.23.0...v10.24.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.24.0`}</a>{` (2022-02-25)`}</h2>
    <h3 {...{
      "id": "features-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-1",
        "aria-label": "features 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update icons, adds ScrollBlueprint, WrenchPencil (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/10016",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#10016`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/ed2d78f80520f8d3f716936f57c96893bffc5c92",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ed2d78f`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10230-2022-02-15",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10230-2022-02-15",
        "aria-label": "10230 2022 02 15 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.22.1...v10.23.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.23.0`}</a>{` (2022-02-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10220-2022-02-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10220-2022-02-01",
        "aria-label": "10220 2022 02 01 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.21.0...v10.22.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.22.0`}</a>{` (2022-02-01)`}</h2>
    <h3 {...{
      "id": "features-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-2",
        "aria-label": "features 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`SVG Icons with displayName. fix: add aria-label to Icon only Buttons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9911",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9911`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/e247b8e4e20bb4062e57d7becada5b8670637018",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`e247b8e`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10210-2022-01-14",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10210-2022-01-14",
        "aria-label": "10210 2022 01 14 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.20.0...v10.21.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.21.0`}</a>{` (2022-01-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10200-2021-12-16",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10200-2021-12-16",
        "aria-label": "10200 2021 12 16 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.19.0...v10.20.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.20.0`}</a>{` (2021-12-16)`}</h2>
    <h3 {...{
      "id": "features-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-3",
        "aria-label": "features 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Icons update chevrons. New: StarOff, Fire, FileEye, EnvelopeOpen, Archive (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9721",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9721`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/c3b56b037585aec7218bdacfe2b0adad362e8c35",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`c3b56b0`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10180-2021-11-09",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10180-2021-11-09",
        "aria-label": "10180 2021 11 09 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.17.0...v10.18.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.18.0`}</a>{` (2021-11-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10170-2021-10-27",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10170-2021-10-27",
        "aria-label": "10170 2021 10 27 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.16.0...v10.17.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.17.0`}</a>{` (2021-10-27)`}</h2>
    <h3 {...{
      "id": "bug-fixes-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-1",
        "aria-label": "bug fixes 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`packages/core-icons/package.json to reduce vulnerabilities (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9503",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9503`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/7a25be1e649f7d077b8845bb647648989b6b491a",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`7a25be1`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "features-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-4",
        "aria-label": "features 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Update icons, adding more CurrencyUSA items (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9553",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9553`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/d189d93d6f10286989b67fbe85d79abb8d305070",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`d189d93`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10160-2021-10-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10160-2021-10-01",
        "aria-label": "10160 2021 10 01 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.15.0...v10.16.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.16.0`}</a>{` (2021-10-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10150-2021-08-30",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10150-2021-08-30",
        "aria-label": "10150 2021 08 30 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.14.0...v10.15.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.15.0`}</a>{` (2021-08-30)`}</h2>
    <h3 {...{
      "id": "features-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-5",
        "aria-label": "features 5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Update icons, align with mobile bolder style (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9445",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9445`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/7bf8cb434025e93161606dee83df742f1b22b7c3",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`7bf8cb4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10140-2021-08-23",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10140-2021-08-23",
        "aria-label": "10140 2021 08 23 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.13.0...v10.14.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.14.0`}</a>{` (2021-08-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10130-2021-08-04",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10130-2021-08-04",
        "aria-label": "10130 2021 08 04 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.12.0...v10.13.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.13.0`}</a>{` (2021-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10120-2021-07-20",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10120-2021-07-20",
        "aria-label": "10120 2021 07 20 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.11.0...v10.12.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.12.0`}</a>{` (2021-07-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "10110-2021-07-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10110-2021-07-01",
        "aria-label": "10110 2021 07 01 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.10.0...v10.11.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.11.0`}</a>{` (2021-07-01)`}</h2>
    <h3 {...{
      "id": "bug-fixes-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bug-fixes-2",
        "aria-label": "bug fixes 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`Icon Highlighter Name (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9146",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9146`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/08c8eddc17528be80da11b749352848ac61ec437",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`08c8edd`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "10100-2021-06-11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10100-2021-06-11",
        "aria-label": "10100 2021 06 11 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.9.0...v10.10.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.10.0`}</a>{` (2021-06-11)`}</h2>
    <h3 {...{
      "id": "features-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-6",
        "aria-label": "features 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9098",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9098`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/5d5acc47b842360b5bf897562eda0b858f316a8a",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`5d5acc4`}</a>{`)`}</li>
      <li parentName="ul">{`Update icons, many markup icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9085",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9085`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/4d67e5563acade75e764b85277b43dce6b257e74",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`4d67e55`}</a>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "reverts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reverts",
        "aria-label": "reverts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reverts`}</h3>
    <ul>
      <li parentName="ul">{`revert "chore(deps-dev): bump typescript from 4.2.4 to 4.3.2 (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8978",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8978`}</a>{`)" (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/9000",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#9000`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/ca69d658c21960bd8f0dea3067b92735e42d5c1d",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ca69d65`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1090-2021-05-25",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1090-2021-05-25",
        "aria-label": "1090 2021 05 25 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.8.0...v10.9.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.9.0`}</a>{` (2021-05-25)`}</h2>
    <h3 {...{
      "id": "features-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-7",
        "aria-label": "features 7 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`[FDN-601]`}{` Add Icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8945",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8945`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/c454e994e1a2560cb37729d145623b6ab072ec3e",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`c454e99`}</a>{`)`}</li>
      <li parentName="ul">{`Many New Icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8914",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8914`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/34376c3177eb4dfea113f72d52bd0bc75ca61b99",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`34376c3`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1080-2021-05-07",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1080-2021-05-07",
        "aria-label": "1080 2021 05 07 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.7.0...v10.8.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.8.0`}</a>{` (2021-05-07)`}</h2>
    <h3 {...{
      "id": "features-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-8",
        "aria-label": "features 8 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8798",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8798`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/d96ed0d926a1f1aaa54c32ea4aca660af12567ab",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`d96ed0d`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1070-2021-04-17",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1070-2021-04-17",
        "aria-label": "1070 2021 04 17 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.6.1...v10.7.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.7.0`}</a>{` (2021-04-17)`}</h2>
    <h3 {...{
      "id": "features-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-9",
        "aria-label": "features 9 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8632",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8632`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/2f060ab548b6abd698d06d24963c815b5712b29e",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`2f060ab`}</a>{`)`}</li>
      <li parentName="ul">{`update icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8698",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8698`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/e8e75b4457b4f3f68e540fa26809ee2533dc4558",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`e8e75b4`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "1060-2021-03-30",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1060-2021-03-30",
        "aria-label": "1060 2021 03 30 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.5.0...v10.6.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.6.0`}</a>{` (2021-03-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1050-2021-03-15",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1050-2021-03-15",
        "aria-label": "1050 2021 03 15 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.4.0...v10.5.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.5.0`}</a>{` (2021-03-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1040-2021-03-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1040-2021-03-10",
        "aria-label": "1040 2021 03 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.3.0...v10.4.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.4.0`}</a>{` (2021-03-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1030-2021-03-02",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1030-2021-03-02",
        "aria-label": "1030 2021 03 02 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.2.0...v10.3.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.3.0`}</a>{` (2021-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1001-2021-02-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1001-2021-02-10",
        "aria-label": "1001 2021 02 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v10.0.0...v10.0.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.0.1`}</a>{` (2021-02-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @procore/core-icons`}</p>
    <h2 {...{
      "id": "1000-2021-02-09",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1000-2021-02-09",
        "aria-label": "1000 2021 02 09 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://github.com/procore/core/compare/v9.9.0...v10.0.0",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`10.0.0`}</a>{` (2021-02-09)`}</h2>
    <h3 {...{
      "id": "features-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#features-10",
        "aria-label": "features 10 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`Swap clickable Icon with Button (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8106",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8106`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/d20324f7afceea23f8596a20a58a11e6ea3d3311",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`d20324f`}</a>{`)`}</li>
      <li parentName="ul">{`update icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8115",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8115`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/ff1302abd3d0334da8951074ccf70e9d30778cf0",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ff1302a`}</a>{`)`}</li>
      <li parentName="ul">{`update icons (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/issues/8147",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`#8147`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/procore/core/commit/a2e276be3d834c41a506c82af5784ae4ff0b0b75",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`a2e276b`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      