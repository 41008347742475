import { mergeProps } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import React from 'react'
import { useI18nContext } from '../_hooks/I18n'
import { addSubcomponents } from '../_utils/addSubcomponents'
import { mergeRefs } from '../_utils/mergeRefs'
import { useThumbnail } from './Thumbnail.hooks'
import {
  StyledFileIcon,
  StyledImageThumbnail,
  StyledImageThumbnailImage,
  StyledImageThumbnailOverlay,
  StyledLabel,
  StyledLabelText,
  StyledPlaceholderThumbnail,
  StyledThumbnailFigCaption,
  StyledThumbnailWrapper,
  StyledTickIcon,
} from './Thumbnail.styles'
import type {
  BasePlaceholderProps,
  BaseThumbnailProps,
  LabelProps,
  PlaceholderProps,
  ThumbnailProps,
  ThumbnailRef,
  ThumbnailRoles,
  ThumbnailRoleType,
  TickIconProps,
} from './Thumbnail.types'
import { getThumbnailVariantForFilename } from './Thumbnail.utils'
import { ThumbnailCaption } from './ThumbnailCaption'
import { ThumbnailPreview } from './ThumbnailPreview'

const defaultSize = 'lg'

function TickIcon({ size = defaultSize, selected }: TickIconProps) {
  const i18n = useI18nContext()
  return (
    <StyledTickIcon
      aria-hidden={selected ? false : true}
      aria-label={selected ? i18n.t('core.thumbnail.checked') : undefined}
      // in IE11, all svg element are focusable by default
      focusable={false}
      selected={selected}
      size={size}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.167 17.5L7 13.459L8.6155 12.0535L11.167 14.5285L17.3845 8.5L19 9.9055L11.167 17.5ZM13 1C6.3715 1 1 6.373 1 13C1 19.627 6.3715 25 13 25C19.627 25 25 19.627 25 13C25 6.373 19.627 1 13 1Z"
        fill="hsl(218,75%,50%)"
      />
      <path
        d="M13 24C6.92487 24 2 19.0751 2 13H0C0 20.1797 5.8203 26 13 26V24ZM24 13C24 19.0751 19.0751 24 13 24V26C20.1797 26 26 20.1797 26 13H24ZM13 2C19.0751 2 24 6.92487 24 13H26C26 5.8203 20.1797 0 13 0V2ZM13 0C5.8203 0 0 5.8203 0 13H2C2 6.92487 6.92487 2 13 2V0Z"
        fill="white"
      />
    </StyledTickIcon>
  )
}

function FigCaption({ label, disabled }: LabelProps) {
  return label ? (
    <StyledThumbnailFigCaption $color={disabled ? 'gray70' : 'gray15'}>
      {label}
    </StyledThumbnailFigCaption>
  ) : null
}

function Label({ label, disabled }: LabelProps) {
  return label ? (
    <StyledLabel>
      <StyledLabelText intent="small" weight="bold">
        {label}
      </StyledLabelText>
    </StyledLabel>
  ) : null
}

const BasePlaceholder = React.forwardRef<
  HTMLDivElement,
  PlaceholderProps<undefined>
>(function BasePlaceholder(
  props: PlaceholderProps<undefined>,
  ref: ThumbnailRef<undefined>
) {
  const thumbnail = useThumbnail(props)
  const { hasCaptionPlaceholder, ...wrapperProps } = props
  return (
    <StyledThumbnailWrapper
      ref={ref}
      layout={thumbnail.layout}
      size={thumbnail.size}
      {...wrapperProps}
    >
      <StyledPlaceholderThumbnail
        focused={thumbnail.focused}
        disabled={thumbnail.disabled}
        selected={thumbnail.selected}
        clickable={thumbnail.clickable}
        size={thumbnail.size}
      >
        <TickIcon
          size={thumbnail.size}
          selected={thumbnail.toggleState.isSelected}
        />
        <StyledFileIcon
          variant={
            props.variant ||
            getThumbnailVariantForFilename(props.filename ?? '')
          }
        />
        {thumbnail.size === 'lg' && (
          <Label label={thumbnail.label} disabled={thumbnail.disabled} />
        )}
        <StyledImageThumbnailOverlay />
      </StyledPlaceholderThumbnail>
      <ThumbnailCaption
        error={thumbnail.error}
        qa={thumbnail.qa?.caption}
        multiline={thumbnail.size === 'lg'}
        layout={thumbnail.layout}
        caption={thumbnail.caption}
        hasCaptionPlaceholder={thumbnail.hasCaptionPlaceholder}
        disabled={thumbnail.disabled}
      />
    </StyledThumbnailWrapper>
  )
})

const FigurePlaceholder = React.forwardRef<
  HTMLElement,
  PlaceholderProps<'figure'>
>(function FigurePlaceholder(
  props: PlaceholderProps<'figure'>,
  ref: ThumbnailRef<'figure'>
) {
  const thumbnail = useThumbnail(props)
  const { hasCaptionPlaceholder, ...wrapperProps } = props
  return (
    <StyledThumbnailWrapper
      ref={ref}
      layout={thumbnail.layout}
      size={thumbnail.size}
      {...wrapperProps}
    >
      <StyledPlaceholderThumbnail
        focused={thumbnail.focused}
        disabled={thumbnail.disabled}
        selected={thumbnail.selected}
        clickable={thumbnail.clickable}
        size={thumbnail.size}
        as={thumbnail.as}
      >
        <TickIcon
          size={thumbnail.size}
          selected={thumbnail.toggleState.isSelected}
        />
        <StyledFileIcon
          variant={
            props.variant ||
            getThumbnailVariantForFilename(props.filename ?? '')
          }
        />
        {thumbnail.size === 'lg' && (
          <FigCaption label={thumbnail.label} disabled={thumbnail.disabled} />
        )}
        <StyledImageThumbnailOverlay />
      </StyledPlaceholderThumbnail>
      <ThumbnailCaption
        error={thumbnail.error}
        qa={thumbnail.qa?.caption}
        multiline={thumbnail.size === 'lg'}
        layout={thumbnail.layout}
        caption={thumbnail.caption}
        hasCaptionPlaceholder={thumbnail.hasCaptionPlaceholder}
        disabled={thumbnail.disabled}
      />
    </StyledThumbnailWrapper>
  )
})

const CheckboxPlaceholder = React.forwardRef<
  HTMLInputElement,
  ThumbnailProps<'checkbox'>
>(function CheckboxPlaceholder(
  { children, as, label, name, onChange, role, value, ...props_ },
  ref
) {
  const props = {
    ...props_,
    as,
    label,
    onChange,
    role,
    value,
    isDisabled: props_.disabled,
    isSelected: props_.checked,
    defaultSelected: props_.defaultChecked,
  }
  const thumbnail = useThumbnail(props)
  const { hasCaptionPlaceholder, ...wrapperProps } = props_
  const isInputFocused = document.activeElement === thumbnail.inputRef.current

  const preventSpaceOnDisabledThumbnail = (e: React.KeyboardEvent) => {
    if (e.key === ' ' && thumbnail.disabled) {
      e.preventDefault()
    }
  }

  React.useEffect(() => {
    if (props.focused && thumbnail.inputRef.current) {
      thumbnail.inputRef.current.focus()
    }
  }, [props.focused, thumbnail.inputRef])

  return (
    <StyledThumbnailWrapper
      ref={ref}
      layout={thumbnail.layout}
      size={thumbnail.size}
      {...wrapperProps}
      onClick={(e) => {
        if (!thumbnail.disabled) {
          thumbnail.toggleState.toggle()
          thumbnail.onClick?.(e)
        }
      }}
    >
      <StyledPlaceholderThumbnail
        focused={thumbnail.focused || isInputFocused}
        disabled={thumbnail.disabled}
        selected={thumbnail.selected}
        clickable={thumbnail.clickable}
        size={thumbnail.size}
      >
        <TickIcon
          size={thumbnail.size}
          selected={thumbnail.toggleState.isSelected}
        />
        <StyledFileIcon
          variant={
            props.variant ||
            getThumbnailVariantForFilename(props.filename ?? '')
          }
        />
        {thumbnail.size === 'lg' && (
          <Label label={thumbnail.label} disabled={thumbnail.disabled} />
        )}
        <StyledImageThumbnailOverlay />
      </StyledPlaceholderThumbnail>
      <ThumbnailCaption
        error={thumbnail.error}
        qa={thumbnail.qa?.caption}
        multiline={thumbnail.size === 'lg'}
        layout={thumbnail.layout}
        caption={thumbnail.caption}
        hasCaptionPlaceholder={thumbnail.hasCaptionPlaceholder}
        disabled={thumbnail.disabled}
      />
      <VisuallyHidden>
        <input
          ref={mergeRefs(thumbnail.inputRef, ref)}
          aria-label={`${thumbnail.label} ${thumbnail.caption}` || 'checkbox'}
          disabled={thumbnail.disabled}
          {...mergeProps(thumbnail.inputProps, thumbnail.focusProps)}
          onKeyDown={preventSpaceOnDisabledThumbnail}
        />
      </VisuallyHidden>
    </StyledThumbnailWrapper>
  )
})

function PlaceholderInner(
  props: PlaceholderProps<ThumbnailRoleType>,
  ref: ThumbnailRef<ThumbnailRoleType>
) {
  switch (props.role) {
    case 'checkbox': {
      return (
        <CheckboxPlaceholder
          ref={ref as React.Ref<HTMLInputElement>}
          {...(props as PlaceholderProps<'checkbox'>)}
        />
      )
    }
    case 'figure': {
      return (
        <FigurePlaceholder
          ref={ref as React.Ref<HTMLElement>}
          {...(props as PlaceholderProps<'figure'>)}
        />
      )
    }
    default: {
      return (
        <BasePlaceholder
          ref={ref as React.Ref<HTMLDivElement>}
          {...(props as PlaceholderProps<undefined>)}
        />
      )
    }
  }
}

const BaseThumbnail = React.forwardRef<
  HTMLDivElement,
  ThumbnailProps<undefined>
>(function BaseThumbnail(
  props: ThumbnailProps<undefined>,
  ref: ThumbnailRef<undefined>
) {
  const thumbnail = useThumbnail(props)
  const { hasCaptionPlaceholder, ...wrapperProps } = props
  return (
    <StyledThumbnailWrapper
      ref={ref}
      layout={thumbnail.layout}
      size={thumbnail.size}
      {...wrapperProps}
    >
      <StyledImageThumbnail
        focused={thumbnail.focused}
        disabled={thumbnail.disabled}
        selected={thumbnail.selected}
        clickable={thumbnail.clickable}
        size={thumbnail.size}
      >
        {thumbnail.isImageURL && !thumbnail.shouldUsePlaceholder ? (
          <StyledImageThumbnailImage
            src={thumbnail.src as string}
            alt={thumbnail.alt}
          />
        ) : (
          <ThumbnailPreview
            file={thumbnail.src as File}
            aria-label={thumbnail.alt}
          />
        )}
        <TickIcon
          size={thumbnail.size}
          selected={thumbnail.toggleState.isSelected}
        />
        {thumbnail.shouldUsePlaceholder && (
          <StyledFileIcon
            variant={
              thumbnail.variant ||
              getThumbnailVariantForFilename(thumbnail.filename ?? '')
            }
          />
        )}
        {thumbnail.size === 'lg' && (
          <Label label={thumbnail.label} disabled={thumbnail.disabled} />
        )}
        <StyledImageThumbnailOverlay />
      </StyledImageThumbnail>
      <ThumbnailCaption
        qa={thumbnail.qa?.caption}
        multiline={thumbnail.size === 'lg'}
        layout={thumbnail.layout}
        caption={thumbnail.caption}
        hasCaptionPlaceholder={thumbnail.hasCaptionPlaceholder}
        disabled={thumbnail.disabled}
        error={thumbnail.error}
      />
    </StyledThumbnailWrapper>
  )
})

const FigureThumbnail = React.forwardRef<HTMLElement, ThumbnailProps<'figure'>>(
  function FigureThumbnail(
    props: ThumbnailProps<'figure'>,
    ref: ThumbnailRef<'figure'>
  ) {
    const thumbnail = useThumbnail(props)
    return (
      <StyledThumbnailWrapper layout={thumbnail.layout} size={thumbnail.size}>
        <StyledImageThumbnail
          ref={ref}
          focused={thumbnail.focused}
          disabled={thumbnail.disabled}
          selected={thumbnail.selected}
          clickable={thumbnail.clickable}
          size={thumbnail.size}
          as={thumbnail.as}
        >
          {thumbnail.isImageURL && !thumbnail.shouldUsePlaceholder ? (
            <StyledImageThumbnailImage
              src={thumbnail.src as string}
              alt={thumbnail.alt}
            />
          ) : (
            <ThumbnailPreview
              file={thumbnail.src as File}
              aria-label={thumbnail.alt}
            />
          )}
          <TickIcon
            size={thumbnail.size}
            selected={thumbnail.toggleState.isSelected}
          />
          {thumbnail.shouldUsePlaceholder && (
            <StyledFileIcon
              variant={
                thumbnail.variant ||
                getThumbnailVariantForFilename(thumbnail.filename ?? '')
              }
            />
          )}
          {thumbnail.size === 'lg' && (
            <FigCaption label={thumbnail.label} disabled={thumbnail.disabled} />
          )}
          <StyledImageThumbnailOverlay />
        </StyledImageThumbnail>
        <ThumbnailCaption
          qa={thumbnail.qa?.caption}
          multiline={thumbnail.size === 'lg'}
          layout={thumbnail.layout}
          caption={thumbnail.caption}
          hasCaptionPlaceholder={thumbnail.hasCaptionPlaceholder}
          disabled={thumbnail.disabled}
          error={thumbnail.error}
        />
      </StyledThumbnailWrapper>
    )
  }
)

const CheckboxThumbnail = React.forwardRef<
  HTMLInputElement,
  ThumbnailProps<'checkbox'>
>(function CheckboxThumbnail(
  { children, as, label, name, onChange, role, value, ...props_ },
  ref
) {
  const props = {
    ...props_,
    as,
    label,
    name,
    onChange,
    role,
    value,
    isDisabled: props_.disabled,
    isSelected: props_.checked,
    defaultSelected: props_.defaultChecked,
  }
  const thumbnail = useThumbnail(props)
  const { hasCaptionPlaceholder, ...wrapperProps } = props_
  const isInputFocused = document.activeElement === thumbnail.inputRef.current

  const preventSpaceOnDisabledThumbnail = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === ' ' && thumbnail.disabled) {
      e.preventDefault()
    }
  }

  React.useEffect(() => {
    if (props.focused && thumbnail.inputRef.current) {
      thumbnail.inputRef.current.focus()
    }
  }, [props.focused, thumbnail.inputRef])

  return (
    <StyledThumbnailWrapper
      layout={thumbnail.layout}
      size={thumbnail.size}
      {...wrapperProps}
      onClick={(e) => {
        if (!thumbnail.disabled) {
          thumbnail.toggleState.toggle()
          thumbnail.onClick?.(e)
        }
      }}
    >
      <StyledImageThumbnail
        focused={thumbnail.focused || isInputFocused}
        disabled={thumbnail.disabled}
        selected={thumbnail.selected}
        clickable={thumbnail.clickable}
        size={thumbnail.size}
      >
        {thumbnail.isImageURL && !thumbnail.shouldUsePlaceholder ? (
          <StyledImageThumbnailImage
            src={thumbnail.src as string}
            alt={thumbnail.alt}
          />
        ) : (
          <ThumbnailPreview
            file={thumbnail.src as File}
            aria-label={thumbnail.alt}
          />
        )}
        <TickIcon
          size={thumbnail.size}
          selected={thumbnail.toggleState.isSelected}
        />
        {thumbnail.shouldUsePlaceholder && (
          <StyledFileIcon
            variant={
              thumbnail.variant ||
              getThumbnailVariantForFilename(thumbnail.filename ?? '')
            }
          />
        )}
        {thumbnail.size === 'lg' && (
          <Label label={thumbnail.label} disabled={thumbnail.disabled} />
        )}
        <StyledImageThumbnailOverlay />
      </StyledImageThumbnail>
      <ThumbnailCaption
        qa={props.qa?.caption}
        multiline={thumbnail.size === 'lg'}
        layout={thumbnail.layout}
        caption={thumbnail.caption}
        hasCaptionPlaceholder={thumbnail.hasCaptionPlaceholder}
        disabled={thumbnail.disabled}
        error={props.error}
      />
      <VisuallyHidden>
        <input
          ref={mergeRefs(thumbnail.inputRef, ref)}
          type={'checkbox'}
          disabled={thumbnail.disabled}
          {...mergeProps(thumbnail.inputProps, thumbnail.focusProps)}
          onKeyDown={preventSpaceOnDisabledThumbnail}
        />
      </VisuallyHidden>
    </StyledThumbnailWrapper>
  )
})

function ThumbnailInner(
  props: ThumbnailProps<ThumbnailRoleType>,
  ref: ThumbnailRef<ThumbnailRoleType>
) {
  const isFile = props.src instanceof File
  const isImageURL = typeof props.src === 'string'

  if (!isFile && !isImageURL) {
    console.warn('Unknown Thumbnail source:', props.src)
  }

  switch (props.role) {
    case 'checkbox': {
      return (
        <CheckboxThumbnail
          ref={ref as React.Ref<HTMLInputElement>}
          {...(props as ThumbnailProps<'checkbox'>)}
        />
      )
    }
    case 'figure': {
      return (
        <FigureThumbnail
          ref={ref as React.Ref<HTMLElement>}
          {...(props as ThumbnailProps<'figure'>)}
        />
      )
    }
    default: {
      return (
        <BaseThumbnail
          ref={ref as React.Ref<HTMLDivElement>}
          {...(props as PlaceholderProps<undefined>)}
        />
      )
    }
  }
}

const Thumbnail_ = React.forwardRef(ThumbnailInner) as <
  Role extends ThumbnailRoles
>(
  props:
    | ThumbnailProps<Role>
    | (BaseThumbnailProps & { ref?: ThumbnailRef<Role> })
) => ReturnType<typeof ThumbnailInner>

const Placeholder = React.forwardRef(PlaceholderInner) as <
  Role extends ThumbnailRoles
>(
  props:
    | PlaceholderProps<Role>
    | (BasePlaceholderProps & { ref?: ThumbnailRef<Role> })
) => ReturnType<typeof PlaceholderInner>

// @ts-ignore
Placeholder.displayName = 'Thumbnail.Placeholder'

// @ts-ignore
Thumbnail_.displayName = 'Thumbnail'

/**

 We use thumbnails to display previews for images or files as either a smaller
 version of an uploaded image, or an icon representing the file type. If an
 image preview can be generated from the file (in the case of images) then
 display that image.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-thumbnail--demo-thumbnail)

 @see [Design Guidelines](https://design.procore.com/thumbnail)

 */
export const Thumbnail = addSubcomponents({ Placeholder }, Thumbnail_)
