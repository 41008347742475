import { ChevronRight } from '@procore/core-icons'
import styled from 'styled-components'
import { Box } from '../Box/Box'
import { Typography } from '../Typography/Typography'
import { colors } from '../_styles/colors'

export const StyledFilenameCaption = styled(Typography)`
  display: flex;
  width: 100%;
`

export const StyledTree = styled.div`
  width: 100%;
  height: 100%;
  min-height: 36px;
  outline: none;
  overflow: auto;
`

export const StyledTreeRowContainer = styled(Typography).withConfig({
  shouldForwardProp: (prop) =>
    !['isHighlighted', 'isSelectable', 'isSelected'].includes(prop),
})<{
  /** @deprecated Use $isHighlighted instead */
  isHighlighted?: boolean
  $isHighlighted?: boolean
  /** @deprecated Use $isSelectable instead */
  isSelectable?: boolean
  $isSelectable?: boolean
  /** @deprecated Use $isSelected instead */
  isSelected?: boolean
  $isSelected?: boolean
}>`
  display: flex;
  align-items: center;
  height: 36px;
  background: transparent;
  box-shadow: inset 4px 0px 0px transparent;
  cursor: default;
  outline: none;

  ${({ isSelectable, $isSelectable }) =>
    isSelectable || $isSelectable ? 'cursor: pointer;' : ''}

  &:hover {
    background: ${colors.gray98};
  }

  ${({ isHighlighted, $isHighlighted }) =>
    isHighlighted || $isHighlighted ? `background: ${colors.gray98};` : ''}

  ${({ isSelected, $isSelected, isHighlighted, $isHighlighted }) =>
    (isSelected || $isSelected) &&
    `
      background: ${colors.blue96};
      box-shadow: inset 4px 0px 0px ${colors.blue50};

      ${StyledFilenameCaption} {
        color: ${colors.blue40};
        font-weight: 600;
      }

      &:hover {
        background-color: ${colors.blue94};
        ${StyledFilenameCaption} {
          color: ${colors.blue45};
        }
      }

      ${
        (isHighlighted || $isHighlighted) &&
        `
          background-color: ${colors.blue94};
          ${StyledFilenameCaption} {
            color: ${colors.blue45};
          }
        `
      }
    `}
`

export const StyledTreeRowContent = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTreeRowWrapper = styled.div`
  outline: none;
`

const StyledIconBase = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`

export const StyledChevronContainer = styled(StyledIconBase)``

export const StyledIconContainer = styled(StyledIconBase)<{
  $isSelected?: boolean
}>`
  color: ${({ $isSelected }) => ($isSelected ? colors.blue45 : colors.gray40)};
`

export const StyledChevron = styled(ChevronRight)<{
  $isExpanded?: boolean
}>`
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? 90 : 0)}deg);
  transition: transform 150ms ease-out;
`
