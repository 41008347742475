import styled, { css } from 'styled-components'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledContainer = styled.div<{ block?: boolean }>`
  align-items: center;
  display: ${({ block }) => (block ? 'flex' : 'inline-flex')};
  height: 36px;
  justify-content: center;
  width: ${({ block }) => (block ? '100%' : null)};
`

export const StyledSegment = styled.label<{
  $disabled: boolean
  $focused: boolean
  $selected: boolean
}>`
  align-items: center;
  border-radius: 0;
  border: 1px solid ${colors.gray85};
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  min-width: 0;
  outline: 0;
  padding: 0 ${spacing.md}px;
  position: relative;
  background-color: ${colors.white};
  color: ${colors.gray45};

  &:hover {
    background-color: ${colors.gray94};
    border-color: ${colors.gray85};
    color: ${colors.gray45};
  }

  ${({ $focused = false }) => {
    if ($focused) {
      return css`
        border-color: ${colors.blue40};
        box-shadow: 0 0 0 1px ${colors.blue40};
        z-index: 1;
      `
    }
  }}

  ${({ $disabled = false, $selected = false }) => {
    if ($disabled && $selected) {
      return css`
        &,
        &:hover,
        &:disabled {
          background-color: ${colors.gray85};
          border-color: ${colors.gray85};
          color: ${colors.white};
          pointer-events: none;
        }
      `
    } else if ($disabled) {
      return css`
        &,
        &:hover,
        &:disabled {
          background-color: ${colors.white};
          border-color: ${colors.gray85};
          color: ${colors.gray85};
          pointer-events: none;
      `
    } else if ($selected) {
      return css`
        &,
        &:hover {
          background-color: ${colors.blue50};
          border-color: ${colors.blue50};
          color: ${colors.white};
        }
      `
    }
  }};
`

export const StyledSegmentDisabledInteractiveWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  height: 100%;
  margin-right: -1px;

  &:first-child ${StyledSegment} {
    border-bottom-left-radius: ${borderRadius.md}px;
    border-top-left-radius: ${borderRadius.md}px;
  }

  &:last-child ${StyledSegment} {
    border-bottom-right-radius: ${borderRadius.md}px;
    border-top-right-radius: ${borderRadius.md}px;
  }
`

export const StyledLabel = styled.span`
  ${getTypographyIntent('body')};

  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
