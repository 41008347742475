import { Calendar } from '@procore/core-icons/dist'
import styled, { css } from 'styled-components'
import { dataQa } from '../Input/Input.styles'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

interface StyledDateInputSegmentProps {
  disabled: boolean
  $isYear: boolean
}

const inputHeight = 36 // TODO one-off

const disabledCss = css`
  background-color: ${colors.gray94};
  color: ${colors.gray45};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray70};
  pointer-events: none;

  svg {
    color: ${colors.gray45};
  }
`

export const StyledCalendar = styled(Calendar)`
  color: ${colors.gray45};
`

export const StyledDateSegmentsContainer = styled.div`
  min-width: 104px;
  display: flex;
  gap: ${spacing.xs}px;
`

export const StyledDateInputSegment = styled.div<StyledDateInputSegmentProps>`
  ${getTypographyIntent('body')}
  outline: none;
  text-align: center;

  &:focus {
    background-color: ${colors.blue96};
  }

  &::selection {
    background-color: transparent;
  }

  &[data-placeholder='true'] {
    color: ${({ disabled }) => (disabled ? colors.gray70 : colors.gray45)};
  }
`

export const StyledDateInputDelimiter = styled.span.attrs<{ visible: boolean }>(
  (props) => ({
    ['data-qa-table-visible']: props.visible,
  })
)<{ visible: boolean }>`
  ${getTypographyIntent('body')}
  user-select: none;
`

export const StyledDateInputIconContainer = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 ${spacing.xs}px;
  height: 24px;
  width: 24px;
`

export const StyledDateInput = styled.div<{
  disabled: boolean
  error: boolean
}>`
  align-items: center;
  border-color: ${colors.gray70};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  flex-direction: row;
  height: ${inputHeight}px;
  justify-content: center;
  padding: 0 ${spacing.xs}px 0 ${spacing.sm}px;

  &::placeholder {
    color: ${colors.gray45};
  }

  ${({ error }) =>
    !error &&
    css`
      &:hover,
      &[data-qa=${dataQa.hovered}] {
        border-color: ${colors.gray45};
      }
    `}

  &:focus-within,
  &[data-qa=${dataQa.focused}] {
    ${getFocus()};
  }

  &:disabled {
    ${disabledCss}
  }

  ${({ disabled }) => disabled && disabledCss}

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.red50};
    `}
`
