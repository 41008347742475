import React from 'react'
import type { DropEvent, DropzoneOptions } from 'react-dropzone'
import { useDropzone as useDropzoneBase } from 'react-dropzone'
import { useI18nContext } from '../_hooks/I18n'
import { partition } from '../_utils/partition'
import { MultipleErrors } from './Dropzone'
import { StyledErrorBannerDetails } from './Dropzone.styles'
import type {
  DropError,
  DropErrorType,
  DropzoneHookProps,
  DropzoneHookState,
  FileRejection,
  FileRejectionReason,
} from './Dropzone.types'
import { dropErrors, fileRejectionReason } from './Dropzone.types'
import { getFileDetail, isZeroSize } from './Dropzone.utils'

const oneDropAtATimeRejectionReason = 'one-drop-at-a-time'

const dropErrorsByReason: Record<FileRejectionReason, DropErrorType> = {
  [fileRejectionReason.maxFiles]: dropErrors.fileAmount,
  [fileRejectionReason.maxFileSize]: dropErrors.maxFileSize,
  [fileRejectionReason.minFileSize]: dropErrors.minFileSize,
  [fileRejectionReason.fileType]: dropErrors.fileType,
}

const getSizeInMB = (maxSize: number): number => maxSize / 10e5

/**
 * WARNING:
 * Use ACCEPT prop carefully, because mime type determination is not reliable across platforms.
 * Details: https://github.com/react-dropzone/react-dropzone/tree/master/examples/accept#browser-limitations
 */
export const useDropzone = ({
  value = [],
  disabled = false,
  multiple = false,
  noClick = true,
  noKeyboard = true,
  onDragEnter,
  onDragOver,
  onDragLeave,
  onDropRejected,
  maxFileSize: maxSize,
  minFileSize: minSize,
  maxFileNumber = Infinity,
  onDrop: _onDrop,
  onDropAccepted,
  ...props
}: DropzoneHookProps = {}): DropzoneHookState => {
  const I18n = useI18nContext()
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([])
  const [fileRejections, setFileRejections] = React.useState<FileRejection[]>(
    []
  )

  const maxFilesRemaining = maxFileNumber - value.length

  const defaultDropError = {
    type: dropErrors.reset,
    title: '',
    message: '',
  }

  const dropErrorReducer = (
    state: DropError,
    action: DropErrorType
  ): DropError => {
    const rejectedFiles = (
      <StyledErrorBannerDetails>
        {fileRejections.map(({ file }) => {
          return <li key={file.name}>{getFileDetail(file)}</li>
        })}
      </StyledErrorBannerDetails>
    )
    const title = I18n.t('core.dropzone.errorTitle')

    switch (action) {
      case dropErrors.oneFileAtATime:
        return {
          type: dropErrors.oneFileAtATime,
          title: '',
          message: I18n.t('core.dropzone.oneFileAtATimeError'),
        }
      case dropErrors.fileAmount:
        return {
          type: dropErrors.fileAmount,
          title,
          message: I18n.t('core.dropzone.standaloneMaxFileNumberError', {
            maxFiles: maxFileNumber,
          }),
          body: rejectedFiles,
        }
      case dropErrors.fileType:
        return {
          type: dropErrors.fileType,
          title,
          message: I18n.t('core.dropzone.standaloneUnsupportedFileTypeError'),
          body: rejectedFiles,
        }
      case dropErrors.maxFileSize:
        return {
          type: dropErrors.maxFileSize,
          title,
          message: I18n.t('core.dropzone.standaloneMaxFileSizeError', {
            sizeInMegabytes: getSizeInMB(maxSize as number),
          }),
          body: rejectedFiles,
        }
      case dropErrors.minFileSize:
        const message = isZeroSize(minSize)
          ? I18n.t('core.dropzone.standaloneZeroFileSizeError')
          : I18n.t('core.dropzone.standaloneMinFileSizeError', {
              sizeInMegabytes: getSizeInMB(minSize as number),
            })

        return {
          type: dropErrors.minFileSize,
          title,
          message,
          body: rejectedFiles,
        }
      case dropErrors.multipleErrors:
        return {
          type: dropErrors.multipleErrors,
          title,
          message: I18n.t('core.dropzone.multipleErrorsMessage'),
          body: (
            <MultipleErrors
              fileRejections={fileRejections}
              maxFiles={maxFileNumber}
              maxSize={maxSize}
              minSize={minSize}
            />
          ),
        }
      case dropErrors.reset:
        return { ...defaultDropError }
      default:
        return state
    }
  }

  const withOneDropAtATimeError = (file: File) => ({
    file,
    errors: [
      {
        message: I18n.t('core.dropzone.oneDropAtATimeError'),
        code: oneDropAtATimeRejectionReason as FileRejectionReason,
      },
    ],
  })

  const [dropError, dispatchDropError] = React.useReducer<
    React.Reducer<DropError, DropErrorType>
  >(dropErrorReducer, { ...defaultDropError })

  const onDrop: DropzoneOptions['onDrop'] = React.useCallback(
    (
      acceptedFiles: File[],
      rejectedFiles: FileRejection[],
      event: DropEvent
    ) => {
      dispatchDropError(dropErrors.reset)

      const droppedFiles = [
        ...acceptedFiles,
        ...rejectedFiles.map((rej) => rej.file),
      ]
      if (!multiple && droppedFiles.length > 1) {
        dispatchDropError(dropErrors.oneFileAtATime)
        onDropRejected?.(droppedFiles.map(withOneDropAtATimeError), event)
        return
      }

      const allFilesWithRejections = [
        ...acceptedFiles.map((file) => ({ file, errors: [] })),
        ...rejectedFiles,
      ].map(({ file, errors }, i) => ({
        file,
        errors:
          i < maxFilesRemaining
            ? errors
            : [...errors, { message: '', code: fileRejectionReason.maxFiles }],
      }))

      const [actualAcceptedFiles_, actualRejectedFiles] =
        partition<FileRejection>(
          ({ errors }) => errors.length === 0,
          allFilesWithRejections
        )

      const actualAcceptedFiles = actualAcceptedFiles_.map(({ file }) => file)

      _onDrop?.(actualAcceptedFiles, actualRejectedFiles, event)

      setAcceptedFiles(actualAcceptedFiles)
      setFileRejections(actualRejectedFiles)

      if (actualAcceptedFiles.length) {
        onDropAccepted?.(actualAcceptedFiles, event)
      }

      if (actualRejectedFiles.length) {
        onDropRejected?.(actualRejectedFiles, event)
      }

      const errorCodes = actualRejectedFiles
        .flatMap((rejection) => rejection.errors)
        .map((error) => error.code)
      const hasMultipleErrors = new Set([...errorCodes]).size > 1

      if (hasMultipleErrors) {
        dispatchDropError(dropErrors.multipleErrors)
      } else {
        dispatchDropError(dropErrorsByReason[errorCodes[0]])
      }
    },
    [_onDrop, maxFilesRemaining, onDropRejected, onDropAccepted, multiple]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
    isDragReject,
    isFocused,
    isDragAccept,
    draggedFiles,
    open,
    rootRef,
    inputRef,
  } = useDropzoneBase({
    ...props,
    disabled,
    minSize,
    maxSize,
    multiple,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    noClick,
    noKeyboard,
  })

  const dragError = React.useMemo(() => {
    const getFileTypeError = () => {
      return isDragReject
        ? I18n.t('core.dropzone.incorrectFileTypeMessage', {
            count: draggedFiles.length,
          })
        : ''
    }

    const getFileAmountError = () => {
      const fileLimit = multiple ? maxFilesRemaining : 1

      const amountError = multiple
        ? I18n.t('core.dropzone.invalidAmountAttachments', {
            count: maxFileNumber,
          })
        : I18n.t('core.dropzone.incorrectFileNumber')

      return draggedFiles.length > fileLimit ? amountError : ''
    }

    return getFileAmountError() || getFileTypeError()
  }, [
    I18n,
    draggedFiles.length,
    maxFilesRemaining,
    isDragReject,
    maxFileNumber,
    multiple,
  ])

  return {
    getRootProps,
    getInputProps,

    isDragActive,
    isFileDialogActive,
    isDragReject,
    isFocused,
    isDragAccept,
    draggedFiles,
    acceptedFiles,
    fileRejections,

    open,
    rootRef,
    inputRef,

    multiple,
    dragError,
    dropError,
    dispatchDropError,
    disabled,
  }
}
