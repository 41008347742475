import styled, { css } from 'styled-components'
import { Button } from '../Button'
import { StyledContent } from '../Button/Button.styles'
import { Card } from '../Card'
import { StyledCircle } from '../Spinner/Spinner.styles'
import { getTypographyIntent } from '../Typography/Typography.styles'
import { arrowDown, arrowUp } from '../_styles/arrows'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { getEllipsis, getFocus } from '../_styles/mixins'
import { spacing } from '../_styles/spacing'

export const dataQa = {
  focused: 'core-select-focused',
  hovered: 'core-select-hovered',
}

export const tableSelectClassName = 'select--table'
const styledSelectButtonDefaultWidth = 248

export const StyledSelectMenu = styled(Card)`
  display: flex;
  max-height: 40vh;
  max-width: ${styledSelectButtonDefaultWidth}px;
  min-width: inherit;
`

export const StyledSelectButtonLabel = styled.div<{ $hoverable: boolean }>`
  ${getEllipsis()}
  flex-grow: 1;
  margin-right: ${spacing.sm}px;
  outline: none;
  pointer-events: ${({ $hoverable }) => ($hoverable ? 'initial' : 'none')};
  user-select: none;
`

export const StyledSelectSpinner = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  line-height: 0;
`

export const StyledSelectArrowContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 16px;
  justify-content: flex-end;
  margin-left: ${spacing.sm}px;
  width: 8px;
`

export const StyledSelectArrow = styled.div`
  position: relative;
  ${arrowDown(4, 'gray45')}
`

export const StyledSelectClearIcon = styled(Button)`
  ${StyledContent} {
    pointer-events: none;
  }
`

export const StyledSelectButton = styled.div<{
  $block: boolean
  $disabled: boolean
  $error: boolean
  $loading: boolean
  $open: boolean
  $placeholder?: boolean
  $hasClearIcon: boolean
}>`
  ${getTypographyIntent('body')}

  align-items: center;
  background-color: ${colors.white};
  border-color: ${colors.gray70};
  border-radius: ${borderRadius.md}px;
  border-style: solid;
  border-width: 1px;
  color: ${colors.gray15};
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  min-height: 36px;
  outline: none;
  padding: 0 ${spacing.md}px;
  position: relative;
  white-space: nowrap;
  width: ${({ $block }) =>
    $block ? '100%' : `${styledSelectButtonDefaultWidth}px`};

  &::placeholder {
    border-color: ${colors.gray45};
  }

  &:hover,
  &:active,
  &[data-qa=${dataQa.hovered}] {
    background-color: ${colors.white};
    border-color: ${colors.gray45};
    color: ${colors.gray15};
  }

  &.focus,
  &:focus,
  &[data-qa=${dataQa.focused}] {
    ${getFocus()}

    ${StyledCircle} {
      stroke: ${colors.blue50};
    }
  }

  ${({ $placeholder = false }) =>
    $placeholder &&
    css`
      ${StyledSelectButtonLabel} {
        color: ${colors.gray45};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${colors.gray94};
      border-color: ${colors.gray70};
      color: ${colors.gray45};
      cursor: default;
      pointer-events: none;

      ${StyledSelectButtonLabel} {
        color: ${colors.gray70};
      }

      ${StyledSelectArrow} {
        ${arrowDown(4, 'gray70')}
      }
    `}

  ${({ $error, $disabled, $loading }) => {
    if ($error && !$disabled) {
      return css`
        &,
        &:hover {
          border-color: ${colors.red50};
          border-color: var(--core-input-error-border-color, ${colors.red50});
        }
        &:hover:focus {
          ${getFocus()}
        }
      `
    }
  }}

  ${({ $hasClearIcon }) => css`
    ${StyledSelectClearIcon} {
      opacity: ${$hasClearIcon ? 1 : 0};
    }
  `}

  ${({ $open }) =>
    $open &&
    css`
      ${StyledSelectArrow} {
        ${arrowUp(4, 'gray45')}
      }
    `}

  &.${tableSelectClassName} {
    ${({ $open }) =>
      $open &&
      css`
        background-color: ${colors.white};

        ${StyledSelectButtonLabel} {
          white-space: nowrap;
        }

        ${StyledSelectArrow} {
          opacity: 1;
          pointer-events: all;
        }
      `}

    ${({ $open, $hasClearIcon }) =>
      $open &&
      $hasClearIcon &&
      css`
        ${StyledSelectClearIcon} {
          opacity: 1;
          pointer-events: all;
        }
      `}
  
    ${({ $open, $placeholder = false }) =>
      $open &&
      $placeholder &&
      css`
        ${StyledSelectButtonLabel} {
          opacity: 1;
          pointer-events: all;
        }
      `}
  }
`
