import styled, { css } from 'styled-components'
import { EmptyState } from '../../EmptyState/EmptyState'
import { colors } from '../../_styles/colors'
import { spacing } from '../../_styles/spacing'

const inputHeight = 32
const toolbarPaddingTop = spacing.xl
const toolbarPaddingBottom = spacing.md
const toolbarHeight = inputHeight + toolbarPaddingTop + toolbarPaddingBottom
const modalBodyBorderTopWidth = 1
const modalBodyWidth = 540
export const contentHeight =
  modalBodyWidth - modalBodyBorderTopWidth - toolbarHeight

// TODO Update box-shadow to use getShadow instead
export const StyledToolbar = styled.div<{
  $alignRight: boolean
  $hasScrollShadow: boolean
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  min-height: ${toolbarHeight}px;
  padding: 16px 24px;

  ${({ $alignRight }) =>
    css`
      justify-content: ${$alignRight ? 'flex-end' : 'space-between'};
    `}

  ${({ $hasScrollShadow }) =>
    $hasScrollShadow &&
    `
      box-shadow: 0px ${spacing.xs}px ${spacing.xs}px ${colors.gray90};
      z-index: 1;
    `}

  & > * + * {
    margin-left: ${spacing.sm}px;
  }
`

export const StyledSearchWrapper = styled.div`
  width: 286px;
  flex-shrink: 1;
  min-width: 180px;
`

export const StyledEmptyStateWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledEmptyState = styled(EmptyState)`
  margin-top: 0;
  margin-bottom: 0;

  img {
    width: 160px;
  }
`

export const StyledSpinnerWrapper = styled.div`
  height: ${contentHeight}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledGrid = styled.div<{ $isEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isEmpty }) => $isEmpty && 'height: 100%;'}
`
