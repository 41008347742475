import { Clear } from '@procore/core-icons/dist'
import React, { useEffect } from 'react'
import { Button } from '../Button/Button'
import { useModalDialogLike } from '../OverlayTrigger/a11yPresets'
import { Portal } from '../Portal/Portal'
import {
  Heading,
  LevelContext,
  Section,
  SectionProvider,
} from '../Section/Section'
import type { TypographyProps } from '../Typography/Typography.types'
import { useDeprecation } from '../_hooks/Deprecation'
import { useLayoutEventListener } from '../_hooks/EventListener'
import { OverridableFocusScope } from '../_hooks/FocusScopeOverride'
import { useI18nContext } from '../_hooks/I18n'
import { useOverflowObserver } from '../_hooks/OverflowObserver'
import { useScrollLock } from '../_hooks/ScrollLock'
import type { RenderProps } from '../_hooks/Visibility'
import { Visibility } from '../_hooks/Visibility'
import { addSubcomponents } from '../_utils/addSubcomponents'
import {
  CloseWithConfirmContext,
  useCloseWithConfirmContext,
  useCloseWithConfirmState,
} from '../_utils/closeWithConfirm'
import { mergeRefs } from '../_utils/mergeRefs'
import type { DivAttributes, SpanAttributes } from '../_utils/types'
import {
  ChildRegistryProvider,
  useModalChildRegistryDispatch,
} from './ChildRegistry.context'
import {
  fadeInClassName,
  fadeOutClassName,
  StyledModal,
  StyledModalBody,
  StyledModalButtons,
  StyledModalCancel,
  StyledModalContainer,
  StyledModalContent,
  StyledModalFooter,
  StyledModalFooterNotation,
  StyledModalFooterSummary,
  StyledModalHeader,
  StyledModalHeading,
  StyledModalScrim,
  StyledModalWarningIcon,
} from './Modal.styles'
import type {
  ConfirmModalProps,
  ModalBodyProps,
  ModalCloseableHeaderProps,
  ModalCloseContextApi,
  ModalContainerProps,
  ModalProps,
} from './Modal.types'
import { useInitializeModalFocus } from './useInitializeModalFocus'

function noop() {}
const defaultStartLevel = 2
const initialBodyLevel = 3

const ModalCloseContext = React.createContext<ModalCloseContextApi>({
  howToClose: [],
  onClose: undefined,
})

interface ModalBodyScrollContextApi {
  isBodyScrolled: boolean
  setIsBodyScrolled: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalBodyScrollContext = React.createContext<ModalBodyScrollContextApi>({
  isBodyScrolled: false,
  setIsBodyScrolled: noop,
})

const ModalClosableHeader = React.forwardRef<
  HTMLDivElement,
  ModalCloseableHeaderProps
>(
  (
    { children, compact = false, onClose: externalOnClose, qa, ...props },
    ref
  ) => {
    const { isBodyScrolled } = React.useContext(ModalBodyScrollContext)
    const { howToClose, onClose } = React.useContext(ModalCloseContext)
    const I18n = useI18nContext()
    const modalClosableHeaderRef = React.useRef(null)

    const { closeWithConfirm } = useCloseWithConfirmContext()
    const registerChildRef = useModalChildRegistryDispatch()

    useEffect(() => {
      registerChildRef({ child: 'header', ref: modalClosableHeaderRef })
    }, [])

    function onClickButton(e: React.MouseEvent<HTMLButtonElement>) {
      if (onClose && howToClose?.includes('x')) {
        closeWithConfirm(onClose)(e, 'x')
        return
      }
      closeWithConfirm(externalOnClose!)(e)
    }

    return (
      <StyledModalHeader
        $compact={compact}
        $isBodyScrolled={isBodyScrolled}
        {...props}
        ref={mergeRefs(ref, modalClosableHeaderRef)}
      >
        <StyledModalHeading $compact={compact}>{children}</StyledModalHeading>
        {(onClose && howToClose?.includes('x')) || externalOnClose ? (
          <StyledModalCancel>
            <Button
              aria-label={I18n.t('core.modal.a11y.close')}
              data-internal="close-button"
              data-qa={qa?.closeButton}
              icon={<Clear />}
              onClick={onClickButton}
              size={compact ? 'sm' : 'md'}
              variant="tertiary"
            />
          </StyledModalCancel>
        ) : null}
      </StyledModalHeader>
    )
  }
)

const ModalClosableFooterButtons = React.forwardRef<
  HTMLDivElement,
  ModalCloseableHeaderProps
>(({ children, ...props }, ref) => {
  const { howToClose, onClose } = React.useContext(ModalCloseContext)
  const I18n = useI18nContext()
  const { closeWithConfirm } = useCloseWithConfirmContext()
  const modalClosableFooterButtonsRef = React.useRef(null)

  const registerChildRef = useModalChildRegistryDispatch()

  useEffect(() => {
    registerChildRef({
      child: 'closeableButtons',
      ref: modalClosableFooterButtonsRef,
    })
  }, [])

  function onClickButton(e: React.MouseEvent<HTMLButtonElement>) {
    closeWithConfirm(onClose!)(e, 'footer-button')
  }

  return (
    <StyledModalButtons
      {...props}
      ref={mergeRefs(ref, modalClosableFooterButtonsRef)}
    >
      {howToClose?.includes('footer-button') && (
        <Button onClick={onClickButton} variant="tertiary">
          {I18n.t('core.modal.cancel')}
        </Button>
      )}
      {children}
    </StyledModalButtons>
  )
})

const ModalContainer = React.forwardRef<HTMLDivElement, ModalContainerProps>(
  ({ compact = false, placement, width, ...props }, ref) => {
    const { onClose } = React.useContext(ModalCloseContext)
    const modalContainerRef = React.useRef<HTMLDivElement>(null)
    useInitializeModalFocus({ modalContainerRef, hasOnClose: !!onClose })

    return (
      <StyledModalContainer
        {...props}
        shadowStrength={4}
        $compact={compact}
        $placement={placement}
        $width={width}
        ref={mergeRefs(ref, modalContainerRef)}
      />
    )
  }
)

const ModalBody = React.forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ children, compact = false, noSideSpacing = false, ...props }, ref) => {
    const { isOverflowingY, ref: overflowRef } = useOverflowObserver()
    const scrollRef = React.useRef(null)
    const modalBodyRef = React.useRef(null)
    const { setIsBodyScrolled } = React.useContext(ModalBodyScrollContext)
    const registerChildRef = useModalChildRegistryDispatch()

    useEffect(() => {
      registerChildRef({ child: 'body', ref: modalBodyRef })
    }, [])

    useLayoutEventListener({
      event: 'scroll',
      handler: (e: React.UIEvent<HTMLElement>) => {
        setIsBodyScrolled(e.currentTarget.scrollTop > 0)
      },
      scope: scrollRef,
    })

    return (
      <SectionProvider>
        <LevelContext.Provider value={initialBodyLevel}>
          <StyledModalBody
            {...props}
            $compact={compact}
            $isOverflowingY={isOverflowingY}
            $noSideSpacing={noSideSpacing}
            ref={mergeRefs(overflowRef, scrollRef, ref, modalBodyRef)}
            tabIndex={0}
          >
            {children}
          </StyledModalBody>
        </LevelContext.Provider>
      </SectionProvider>
    )
  }
)

const ModalFooterSummary = React.forwardRef<HTMLDivElement, DivAttributes>(
  ({ children, ...props }, ref) => {
    return (
      <StyledModalFooterSummary {...props} ref={ref}>
        {children}
      </StyledModalFooterSummary>
    )
  }
)

const ModalFooterNotation = React.forwardRef<
  HTMLSpanElement,
  SpanAttributes & TypographyProps
>(({ children, ...props }, ref) => (
  <StyledModalFooterNotation
    aria-live="polite"
    intent="small"
    color="gray45"
    italic
    {...props}
    ref={ref}
  >
    {children}
  </StyledModalFooterNotation>
))

const Modal_ = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      ['aria-describedby']: ariaDescribedby,
      ['aria-details']: ariaDetails,
      ['aria-labelledby']: ariaLabelledby,
      ['aria-label']: ariaLabel,
      children,
      compact = false,
      howToClose,
      id,
      onClickOverlay = noop,
      onClose,
      open = false,
      placement = 'center',
      role,
      ...props
    },
    ref
  ) => {
    useScrollLock(open)
    const [visible, setVisible] = React.useState<boolean>(open)
    const [fadeType, setFadeType] = React.useState<string>('')
    const closeWithConfirmState = useCloseWithConfirmState()
    const { closeWithConfirm } = closeWithConfirmState

    React.useEffect(() => {
      if (open) {
        setVisible(true)
        setTimeout(() => {
          setFadeType(fadeInClassName)
        }, 0)
      } else {
        setFadeType(fadeOutClassName)
      }
    }, [open])

    const onTransitionEnd = () => {
      if (fadeType === fadeOutClassName) {
        setVisible(false)
      }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!onClose) return
      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        closeWithConfirm(onClose)(e, 'x')
      }
    }

    const { dialogProps: dialogProps_ } = useModalDialogLike({
      'aria-describedby': ariaDescribedby,
      'aria-details': ariaDetails,
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      id,
      isModal: false,
      isOpen: open,
      role,
    })

    const dialogProps = onClose && role === 'dialog' ? dialogProps_ : {}

    function onClickScrim(
      e: React.ComponentProps<typeof StyledModalScrim>['onClick']
    ) {
      onClickOverlay(e)
      if (onClose && howToClose?.includes('scrim')) {
        closeWithConfirm(onClose)(e, 'scrim')
      }
    }

    return visible ? (
      <Portal>
        <ModalCloseContext.Provider value={{ onClose, howToClose }}>
          <LevelContext.Provider value={defaultStartLevel}>
            <ChildRegistryProvider>
              <OverridableFocusScope
                // TODO breaking - always
                autoFocus={!!onClose}
                contain={!!onClose}
                restoreFocus={!!onClose}
              >
                <StyledModal
                  className={fadeType}
                  ref={ref}
                  onTransitionEnd={onTransitionEnd}
                  onKeyDown={handleKeyDown}
                  {...dialogProps}
                >
                  <StyledModalScrim onClick={onClickScrim} />

                  <ModalContainer
                    {...props}
                    compact={compact}
                    placement={placement}
                  >
                    <StyledModalContent $compact={compact}>
                      <ModalBodyScrollObserver>
                        <CloseWithConfirmContext.Provider
                          value={closeWithConfirmState}
                        >
                          {children}
                        </CloseWithConfirmContext.Provider>
                      </ModalBodyScrollObserver>
                    </StyledModalContent>
                  </ModalContainer>
                </StyledModal>
              </OverridableFocusScope>
            </ChildRegistryProvider>
          </LevelContext.Provider>
        </ModalCloseContext.Provider>
      </Portal>
    ) : null
  }
)

function ModalBodyScrollObserver({ children }: { children: React.ReactNode }) {
  const [isBodyScrolled, setIsBodyScrolled] = React.useState<boolean>(false)

  return (
    <ModalBodyScrollContext.Provider
      value={{ isBodyScrolled, setIsBodyScrolled }}
    >
      {children}
    </ModalBodyScrollContext.Provider>
  )
}

export const ConfirmModal = React.forwardRef<HTMLDivElement, ConfirmModalProps>(
  ({ children, headline, onClose, ...props }, ref) => {
    return (
      <Modal {...props} ref={ref}>
        <ModalClosableHeader onClose={onClose}>
          <StyledModalWarningIcon size="lg" />
          {headline}
        </ModalClosableHeader>
        {children}
      </Modal>
    )
  }
)

function ModalState(props: RenderProps) {
  useDeprecation({ oldThing: 'Modal.State' })
  return <Visibility {...props} />
}

Modal_.displayName = 'Modal'

ConfirmModal.displayName = 'ConfirmModal'

ModalBody.displayName = 'Modal.Body'

ModalFooterNotation.displayName = 'Modal.FooterNotation'

ModalFooterSummary.displayName = 'Modal.FooterSummary'

const Body = ModalBody
const Container = ModalContainer
const Content = StyledModalContent
const FooterSummary = ModalFooterSummary
const Footer = StyledModalFooter
const FooterButtons = ModalClosableFooterButtons
const FooterNotation = ModalFooterNotation
const Header = ModalClosableHeader
const Overlay = StyledModal
const Scrim = StyledModalScrim
const State = ModalState

/**

 We use modals to present additional actions, information, or forms on pages
 where a user’s experience would benefit from remaining on the same page.
 They can include graphics, inputs, buttons, and other elements as needed to
 produce the workflow or action needed.

 Do not include modals on pages with complicated UI layouts.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-modal--demo)

 @see [Design Guidelines](https://design.procore.com/modal)

 */
export const Modal = addSubcomponents(
  {
    Body,
    Container,
    Content,
    Footer,
    FooterButtons,
    FooterNotation,
    FooterSummary,
    Header,
    Heading: Heading,
    Overlay,
    Scrim,
    State,
    Section,
  },
  Modal_
)
