import type React from 'react'
import styled, { css } from 'styled-components'
import { StyledRequiredContainer } from '../Required/Required.styles'
import type { TypographyProps } from '../Typography'
import {
  getTypographyIntent,
  semiboldRules,
  StyledTypography,
} from '../Typography/Typography.styles'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export type StyledListProps = React.HTMLAttributes<
  HTMLUListElement | HTMLOListElement
> & {
  $listStyleType?: string
}

export const StyledH1 = styled.h1`
  color: ${colors.gray15};
  ${getTypographyIntent('h1')}
  margin: 0;
`
export const StyledH2 = styled.h2`
  color: ${colors.gray15};
  ${getTypographyIntent('h2')}
  margin: 0;
`
export const StyledH3 = styled.h3`
  color: ${colors.gray15};
  ${getTypographyIntent('h3')}
  margin: 0;
`

const topMarginRules = () => {
  return css`
    & + &,
    p + &,
    ol + &,
    ul + & {
      margin-top: ${spacing.lg}px;
    }
  `
}

// Should this be on Typography? body | label
/** Small icons align with font and line height */
export function getSmallIconBodyTextRules() {
  const space = spacing.xs
  const spaceWhenTouch = spacing.sm

  return css`
    & > [data-qa^='ci'] {
      height: 16px;
      width: 16px;
      vertical-align: middle;
      margin-left: ${space}px;

      &[tabindex='0'] {
        margin-left: ${spaceWhenTouch}px;
      }

      &:first-child {
        margin-left: 0;
        margin-right: ${space}px;
        vertical-align: sub;

        &[tabindex='0'] {
          margin-right: ${spaceWhenTouch}px;
        }
      }
    }
  `
}

export const StyledP = styled.p`
  color: ${colors.gray15};
  ${getTypographyIntent('body')}
  ${topMarginRules}
  ${semiboldRules}
`

export const StyledUL = styled.ul<StyledListProps>`
  color: ${colors.gray15};
  margin: 0;
  padding: 0;
  ${getTypographyIntent('body')}
  ${topMarginRules}
  padding-left: ${spacing.lg}px;
  list-style-type: ${(props) => props.$listStyleType || 'disc'};
`

export const StyledOL = styled(StyledUL).attrs({ as: 'ol' })<StyledListProps>`
  list-style-type: ${(props) => props.$listStyleType || 'decimal'};
`

function enforceLabelTypography(props: TypographyProps) {
  return {
    as: props.as || 'label',
    $intent: 'label',
  }
}

export const StyledLabel = styled(StyledTypography).attrs(
  enforceLabelTypography
)`
  ${({ $block = false }: { $block?: boolean }) => $block && `display: block;`}
  ${getSmallIconBodyTextRules}

  > ${StyledRequiredContainer} {
    margin-left: ${spacing.xs}px;
    vertical-align: top;
  }
`

/**
 * An unstyled `button`, browser reset
 */
export function getUnstyledButton() {
  return css`
    border: none;
    background: none;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    text-transform: none;
    -webkit-appearance: button;
  `
}
/**
 * An unstyled `button`, browser reset
 */
export const StyledUnstyledButton = styled.button`
  ${getUnstyledButton}
`

/**
 * An a styled component a
 */
export const StyledAnchor = styled.a``
